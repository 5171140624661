import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from 'axios';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import ReferReasonEnum from '../../../enums/ReferReason.enum';
import getAuthToken from '../../../utils/cookie';
import RadioButtonGroup from '../../UI/RadioButtonGroup';
import { referReasonOptions } from '../../../utils/constant';

const ReferrerModal = ({ showMessage, setShowMessage, showTour }) => {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');

  const handleReasonChange = (value) => {
    setComment('');
    setReason(value);
  };

  const handelClose = (event) => {
    setShowMessage(false);
    showTour();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setShowMessage(false);
      showTour();
      const token = getAuthToken();
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}create/referrer/`,
        {
          refer: reason,
          comment
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderConditionalTextArea = () => {
    if ([ReferReasonEnum.REFERRED_BY_FRIEND, ReferReasonEnum.OTHER].includes(reason)) {
      return (
        <div className="mb-4">
          <label htmlFor="friendName" className="block pb-2 font-medium">
            {ReferReasonEnum.REFERRED_BY_FRIEND === reason ? "Friend's Name" : 'Reason'}
          </label>
          <textarea
            type="text"
            rows={ReferReasonEnum.REFERRED_BY_FRIEND === reason ? 1 : 4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="p-2 w-full block bg-[#f3f4f6] rounded-md shadow-sm sm:text-sm text-black"
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Modal onClose={() => handelClose()} showModal={showMessage}>
      <div className="bg-white">
        <div className="bg-[#FE6629] text-white flex justify-left items-center py-4 relative">
          <button
            type="button"
            className="absolute top-0 right-0 mt-3 mr-3 p-1"
            onClick={() => handelClose()}>
            <i className="fas fa-times text-lg text-white" />
          </button>
          <h1 className="text-2xl pl-4 font-semibold subpixel-antialiased bold">
            How did you hear about us?
          </h1>
        </div>
        <div className="text-black px-4 sm:px-6 md:pr-8 w-full pb-4">
          <div className="pb-4">
            <form onSubmit={handleSubmit} className="mt-4">
              <RadioButtonGroup
                options={referReasonOptions}
                selectedOption={reason}
                onChange={handleReasonChange}
              />

              {renderConditionalTextArea()}

              <div className="flex flex-row-reverse">
                <Button type="submit" onClick={handleSubmit}>
                  Complete
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReferrerModal.propTypes = {
  showMessage: PropTypes.bool.isRequired,
  setShowMessage: PropTypes.func.isRequired,
  showTour: PropTypes.func.isRequired
};

export default ReferrerModal;
