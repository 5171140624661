export const headings = [
  {
    label: '',
    name: 'type',
    customHeadingClass: 'w-7 !pr-2'
  },
  {
    label: 'Title',
    name: 'title',
    sortable: true
  },
  {
    label: 'Writers/Directors/Producers',
    name: 'Writer/Directors/Producers'
  },
  {
    label: 'Submitted by',
    name: 'submittedBy'
  },
  {
    label: 'Genres',
    name: 'genre'
  },
  {
    label: 'Logline',
    name: 'logline'
  },
  {
    label: 'Note',
    name: 'note'
  },
  {
    label: 'Based on',
    name: 'based_on'
  },
  {
    label: 'Read',
    name: 'read',
    sortable: true
  },
  {
    label: 'Responded',
    name: 'response_status',
    sortable: true
  },
  {
    label: 'Priority',
    name: 'priority',
    sortable: true
  },
  {
    label: 'Date',
    name: 'created_at',
    sortable: true
  },
  {
    label: '',
    name: 'actions'
  }
];
