import { updateSubmissionDataApi } from '../../../api/submissions-database';
import PlusIcon from '../../icons/PlusIcon';
import { CrossCircledIcon } from '../../icons/icons';
import RenderTypeIcon from '../RenderTypeIcon';

const RenderDropdownSelect = ({
  item,
  selectedValue,
  setSelectedValue,
  isVisible,
  toggleVisibility,
  isMultiEntry,
  isSubmissionType,
  label,
  selectedItemColorStyle,
  isAuthorDropdown
}) => {
  const removeMultiEntryValue = async (index) => {
    const updatedValues = [...selectedValue];
    updatedValues.splice(index, 1);
    setSelectedValue(updatedValues);
    updateSubmissionDataApi({ data: { _id: item._id, [label]: updatedValues } });
  };

  const returnGeneraWidth = (value, length, isLast) => {
    const areTwoWords = value?.split(' ')?.length === 2;
    if (length > 12 && isLast && areTwoWords) {
      return 'w-[18ch] hover:w-[19ch]';
    } else if (length > 12 && isLast) {
      return 'w-[16ch] hover:w-[18ch]';
    } else if (length > 10) {
      return 'w-[17ch] hover:w-[18ch]';
    } else if (length > 10 && isLast) {
      return 'w-[14ch] hover:w-[16ch]';
    } else {
      return 'w-fit';
    }
  };

  const addButton = (
    <button
      type="button"
      className="p-2 bg-[#292A35]  border border-[#393A4B] h-fit shadow-[0px_1px_1px_#00000026] rounded-md justify-self-start"
      title={`Add ${label}`}
      onClick={() => toggleVisibility(null)}>
      <PlusIcon className={'w-2 h-2'} />
    </button>
  );

  if (isMultiEntry) {
    const isEmpty =
      selectedValue?.length === 0 ||
      (selectedValue?.length === 1 && selectedValue[0]?.trim() === '');
    if (isEmpty) {
      return addButton;
    }
    return (
      <ul className="flex flex-col gap-1 items-start justify-end w-fit">
        {selectedValue?.map((entry, index) => {
          const textLength = entry?.length;
          const isLast = index === selectedValue?.length - 1;
          return (
            <li key={entry || index} className="flex gap-1">
              <div
                className={`p-1 pr-2 text-xs ${returnGeneraWidth(entry, textLength, isLast)} group bg-[#292A35] border border-[#393A4B] shadow-[0px_1px_1px_#00000026] rounded-md`}>
                <span tabIndex={0} onClick={() => toggleVisibility(index)}>
                  <RenderTypeIcon type={entry} className="inline h-4 w-4" />
                </span>
                <span className="ml-1 whitespace-nowrap">{entry}</span>
                <button
                  type="button"
                  onClick={() => removeMultiEntryValue(index)}
                  title={`Remove ${entry}`}
                  className="align-middle hidden group-hover:inline ml-1">
                  <CrossCircledIcon className="w-3 h-3" pathClass={'fill-gray-400'} />
                </button>
              </div>
              {isLast && addButton}
            </li>
          );
        })}
      </ul>
    );
  } else if (isSubmissionType || isAuthorDropdown) {
    return (
      <span tabIndex={0} onClick={() => toggleVisibility(null)}>
        <RenderTypeIcon type={selectedValue} />
      </span>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: selectedItemColorStyle.backgroundColor,
          color: selectedItemColorStyle.textColor
        }}
        className={
          'flex justify-between items-center gap-1 text-xs rounded-full px-2 py-0.5 text-whie bg-transparent w-full cursor-pointer '
        }
        onClick={toggleVisibility}>
        <div className="flex items-center gap-1">
          <span
            style={{ backgroundColor: selectedItemColorStyle.circleColor }}
            className="w-2 h-2 rounded-full"
          />
          {reuturnSelectedValue(label, selectedValue)}
        </div>
        <i
          style={{ color: selectedItemColorStyle.textColor }}
          className={`fas fa-chevron-down ${isVisible ? 'rotate-180' : 'rotate-0'} duration-300 transition-all`}
        />
      </div>
    );
  }
};

export default RenderDropdownSelect;

function reuturnSelectedValue(label, selectedValue) {
  if (label === 'priority') {
    return (
      <ul className="flex flex-col">
        {selectedValue.split(' ').map((word, index) => {
          return (
            <li key={index} className="whitespace">
              {word}
              <br />
            </li>
          );
        })}
      </ul>
    );
  } else {
    return <span>{selectedValue}</span>;
  }
}
