import { Helmet } from 'react-helmet';
import { content } from '../static/data/PrivacyPolicyContent';
import Card from '../components/UI/Card';

const PrivacyPolicy = () => (
  <Card>
    <Helmet>
      <title>Privacy Policy | FullFrame</title>
      <meta
        name="description"
        content="FullFrame is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website..."
      />
    </Helmet>
    {content.map((item, index) => (
      <div className="flex flex-col gap-5 md:gap-6" key={item.title}>
        <h3
          className={`text-lg md:text-xl font-filsonPro-bold ${!item.title && 'hiden'} ${index !== 0 && item.title && 'mt-6'}`}
        >
          {item.title}
        </h3>
        <p className={`${index === 0 ? 'block' : 'hidden'}`}>
          What personal information do we collect?
        </p>
        <div className="flex flex-col gap-2 md:gap-[0.625rem]">
          <h4 className="text-primary font-filsonPro-semibold">{item.subtitle}</h4>
          <p>{item.description}</p>
        </div>
      </div>
    ))}
  </Card>
);

export default PrivacyPolicy;
