import React from 'react';
import {
    BookIcon,
    ScriptIcon,
    SeriesIcon,
} from '../icons/icons';

import action from "./dropdown/icons/action.png";
import adventure from "./dropdown/icons/adventure.png";
import arthouse from "./dropdown/icons/arthouse.png";
import biopic from "./dropdown/icons/biopic.png";
import comedy from "./dropdown/icons/comedy.png";
import crime from "./dropdown/icons/crime.png";
import darkComedy from "./dropdown/icons/dark-comedy.png";
import director from "./dropdown/icons/director.png";
import documentary from "./dropdown/icons/documentary.png";
import drama from "./dropdown/icons/drama.png";
import educational from "./dropdown/icons/educational.png";
import fantasy from "./dropdown/icons/fantasy.png";
import filmNoir from "./dropdown/icons/film-noir.png";
import foundFootage from "./dropdown/icons/found-footage.png";
import heist from "./dropdown/icons/heist.png";
import historical from "./dropdown/icons/historical.png";
import horror from "./dropdown/icons/horror.png";
import musical from "./dropdown/icons/musical.png";
import nonFiction from "./dropdown/icons/non-fiction.png";
import paranormal from "./dropdown/icons/paranormal.png";
import producer from "./dropdown/icons/producer.png";
import psychological from "./dropdown/icons/psychological.png";
import romance from "./dropdown/icons/romance.png";
import scienceFiction from "./dropdown/icons/science-fiction.png";
import slapstick from "./dropdown/icons/slapstick.png";
import slasher from "./dropdown/icons/slasher.png";
import technical from "./dropdown/icons/technical.png";
import thriller from "./dropdown/icons/thriller.png";
import western from "./dropdown/icons/western.png";
import writer from "./dropdown/icons/writer.png";

const iconMap = {
    book: BookIcon,
    script: ScriptIcon,
    series: SeriesIcon,
    arthouse: arthouse,
    biopic: biopic,
    slasher: slasher,
    paranormal: paranormal,
    found: foundFootage,
    psychological: psychological,
    dark: darkComedy,
    slapstick: slapstick,
    heist: heist,
    thriller: thriller,
    musical: musical,   
    action: action,
    adventure: adventure,
    comedy: comedy,
    drama: drama,
    horror: horror,
    romance: romance,
    "Science Fiction": scienceFiction,
    fantasy: fantasy,
    documentary: documentary,
    "film noir": filmNoir,
    western: western,
    historical: historical,
    crime: crime,
    educational: educational,
    "Non-fiction": nonFiction,
    technical: technical,
    writer: writer,
    directors: director,
    producers: producer,
};;

const RenderTypeIcon = ({ type, className = "", isMultiEntry, isAuthorDropdown }) => {
    if (!type) return null;
    const lowerCaseType = type.toLowerCase();
    const matchedKey = Object.keys(iconMap).find(key => lowerCaseType.includes(key?.toLowerCase()));
    const IconComponent = matchedKey ? iconMap[matchedKey] : null;

    if (!IconComponent) return null;

    // Check if IconComponent is a React component or an image source
    const isComponent = typeof IconComponent === 'function';

    if (!isComponent && (isMultiEntry || isAuthorDropdown)) {
        // Render as an image for multi-entry non-component icons
        return <img src={IconComponent} alt={type} className={className} />;
    }

    // Render as a component or a single-entry image
    return isComponent ? <IconComponent className={className} /> : <img src={IconComponent} alt={type} className={className} />;
};

export default RenderTypeIcon;
