import React from 'react';

const SeriesIcon = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.58331 4.05209C4.58343 3.99104 4.5998 3.93111 4.63075 3.87848C4.6617 3.82585 4.70611 3.78242 4.75941 3.75264C4.81271 3.72286 4.87298 3.70782 4.93402 3.70905C4.99507 3.71029 5.05468 3.72776 5.10673 3.75968L6.78606 4.79047C6.83611 4.82122 6.87745 4.86429 6.90612 4.91556C6.93479 4.96683 6.94985 5.0246 6.94985 5.08334C6.94985 5.14209 6.93479 5.19985 6.90612 5.25112C6.87745 5.30239 6.83611 5.34546 6.78606 5.37622L5.10673 6.40747C5.05461 6.43942 4.99492 6.45689 4.93379 6.45809C4.87267 6.45929 4.81234 6.44416 4.75901 6.41428C4.70568 6.38439 4.66129 6.34082 4.6304 6.28806C4.59952 6.23531 4.58326 6.17527 4.58331 6.11413V4.05209Z"
        stroke="white"
        stroke-width="0.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.20831 10.125H7.79165"
        stroke="white"
        stroke-width="0.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16669 1.875H1.83335C1.32709 1.875 0.916687 2.28541 0.916687 2.79167V7.375C0.916687 7.88126 1.32709 8.29167 1.83335 8.29167H9.16669C9.67295 8.29167 10.0834 7.88126 10.0834 7.375V2.79167C10.0834 2.28541 9.67295 1.875 9.16669 1.875Z"
        stroke="white"
        stroke-width="0.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SeriesIcon;
