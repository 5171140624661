import React from 'react';
import Select from 'react-select';

const Selector = (props) => {
  const newVal =
    typeof props.value === 'string'
      ? props.options?.find((option) => option._id === props.value)
      : props.value;

  return (
    <Select
      styles={{
        menu(provided) {
          return {
            ...provided
          };
        },
        container(base) {
          return {
            ...base
          };
        },
        control(base, state) {
          return {
            ...base,
            minWidth: props.maxWidth || '10rem',
            backgroundColor: '#1D2939',
            outline: 'none',
            borderColor: 'rgba(255,255,255,0.1)',
            color: state.isDisabled ? 'rgba(255,255,255,0.5)' : 'white',
            borderRadius: '4px',
            fontSize: '0.875rem',
            fontWeight: 300,
            boxShadow: 'none',
            '&:hover': {
              borderColor: state.isDisabled ? 'rgba(255,255,255,0.1)' : '#FE6629'
            },
            '&:focus': {
              borderColor: state.isDisabled ? 'rgba(255,255,255,0.1)' : '#FE6629'
            }
          };
        },
        option: (provided, state) => {
          return {
            ...provided,
            backgroundColor: state.isSelected ? '#FE6629' : '#1D2939',
            color: state.isDisabled ? 'rgba(255,255,255,0.5)' : 'white',
            fontWeight: 300,
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: state.isSelected ? '#FE6629' : 'rgba(255,255,255,0.1)'
            }
          };
        },
        singleValue: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? 'rgba(255,255,255,0.5)' : 'white'
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#1D2939',
          borderRadius: '4px'
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? 'rgba(255,255,255,0.5)' : 'white'
        }),
        input: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? 'rgba(255,255,255,0.5)' : 'white'
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? 'rgba(255,255,255,0.3)' : 'rgba(255,255,255,0.4)'
        })
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option._id}
      menuPortalTarget={document.body}
      components={{ IndicatorSeparator: () => null }}
      {...props}
      value={newVal}
    />
  );
};

export default Selector;
