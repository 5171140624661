import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Backdrop from './Backdrop';

/* eslint-disable react/prop-types */
const ModalOverlay = ({ showModal, className, children }) => (
  <CSSTransition in={showModal} timeout={200} classNames="modal" mountOnEnter unmountOnExit>
    <div
      className={`${className} absolute left-1/2 -translate-x-1/2 lg:-translate-x-[35%] 2xl:-translate-x-[45%] top-1/2 -translate-y-1/2 rounded-md overflow-hidden 
                      w-[88%] sm:w-4/5 md:w-[70%] lg:w-[57%] xl:w-1/2 2xl:w-[40.5%] h-fit bg-white transition-all duration-200 box-shadow opacity-100 z-[1030]`}>
      <div className="content">{children}</div>
    </div>
  </CSSTransition>
);

const portalElement = document.getElementById('overlay');

/* eslint-disable react/prop-types */
const Modal = (props) => (
  <>
    {ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment
      <Backdrop onClick={props.onClose} {...props} />,
      portalElement
    )}

    {ReactDOM.createPortal(
      // eslint-disable-next-line react/destructuring-assignment, react/jsx-props-no-spreading
      <ModalOverlay className={props.className} {...props}>
        {props.children}
      </ModalOverlay>,
      portalElement
    )}
  </>
);

export default Modal;
