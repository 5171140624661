import { motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import SiteScreenshot from '../static/images/site-screenshot.jpeg';
import PromoVideo from '../static/videos/promo.mp4';
import Button from '../components/UI/Button';

function HeroSection({ textAnimation, buttonAnimation }) {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVideoThumbnail, setShowVideoThumbnail] = useState(true);

  const onPlayClick = (e) => {
    e.stopPropagation();
    setIsPlaying((prev) => !prev);
    setShowVideoThumbnail(false);
  };

  const onVideoEnd = () => {
    videoRef.current.currentTime = 0;
    setIsPlaying(false);
    setShowVideoThumbnail(true);
  };

  const isVideoPlaying = () => !videoRef?.current?.paused;

  useEffect(() => {
    const video = videoRef.current;

    if (isPlaying) {
      video.play();
    } else {
      video.pause();
    }
  }, [isPlaying]);
  useEffect(() => {
    function escFunction(event) {
      if (event.keyCode === 32) {
        if (isPlaying) {
          setIsPlaying(false);
        }
      }
    }

    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);
  return (
    <section className="relative mt-12 md:mt-20 lg:mt-28">
      {/* Title */}
      <motion.div
        animate={textAnimation.animate}
        initial={textAnimation.initial}
        whileInView="visible"
        viewport={{ once: true }}
        className="flex justify-center items-center">
        <div className="flex flex-col gap-6 lg:gap-8 items-center text-center mx-auto">
          <h2 className="flex flex-col text-center font-extrabold text-xl sm:text-3xl md:text-4xl lg:text-5xl">
            <span className="leading-[2rem] sm:leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem]">
              Supercharge your script and
            </span>
            <span className="leading-[2rem] sm:leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem]">
              book coverage
            </span>
          </h2>
          <p className="text-gray-300">Secure development software that saves hours of time</p>
          <motion.div animate={buttonAnimation.animate} initial={buttonAnimation.initial}>
            <Button onClick={() => navigate('/signup', { state: { prevLandingPage: true } })}>
              Start Coverage Now
            </Button>
          </motion.div>
        </div>
      </motion.div>

      {/* Video Introduction */}
      <motion.div
        animate={{ y: 0, opacity: 1, transition: { duration: 0.6, delay: 0.2 } }}
        initial={{ opacity: 0.8, y: 70 }}
        className={`relative mx-auto mt-12 md:mt-20 lg:mt-28 w-fit m-auto h-[230px] sm:h-[450px] md:h-[550px] lg:h-[614px] ${
          !showVideoThumbnail && 'cursor-pointer'
        } rounded-md group overflow-hidden z-20`}
        onClick={() => (!showVideoThumbnail ? setIsPlaying((prev) => !prev) : undefined)}>
        <div
          className={`${
            !showVideoThumbnail && 'hidden'
          } absolute top-0 left-0 w-full h-full flex justify-center bg-[rgba(0,1,32,0.3)] items-center z-10`}
        />
        <img
          src={SiteScreenshot}
          alt="Script Coverage"
          className={`${
            showVideoThumbnail ? 'inline' : 'hidden'
          } relative h-[230px] sm:h-[450px] md:h-[550px] lg:h-[614px] border border-gray-800 rounded-md mx-auto`}
        />
        <button
          type="button"
          name="Play/Pause Video"
          className={`${
            showVideoThumbnail ? 'opacity-100' : 'opacity-0'
          } absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 m-auto group-hover:!opacity-100 transition-all duration-200 z-30`}
          onClick={(e) => onPlayClick(e)}>
          <i
            className={`fas fa-${
              isVideoPlaying() ? 'play' : 'pause'
            }-circle transition-all duration-100 align-middle bg-white text-primary hover:text-primary-dark hover:bg-gray-400 rounded-full overflow-hidden border-none text-4xl sm:text-6xl md:text-6xl lg:text-7xl cursor-pointer`}
          />
        </button>
        <video
          ref={videoRef}
          playsInline
          controls
          onEnded={onVideoEnd}
          className={`${!showVideoThumbnail ? 'block' : 'hidden'} w-full h-full`}>
          <source src={PromoVideo} type="video/mp4" />
        </video>
      </motion.div>
    </section>
  );
}

export default HeroSection;
