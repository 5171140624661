import Select from '../components/select/Select';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';

const DURATIONS = [
  {
    id: 1,
    name: 'Forever'
  },
  {
    id: 2,
    name: '1 Year'
  },
  {
    id: 3,
    name: '6 Months'
  },
  {
    id: 4,
    name: '3 Months'
  }
];
const DataRetention = () => {
  return (
    <Card className="flex flex-col gap-6 !max-h-fit !min-h-0">
      <h3 className="text-lg md:text-xl font-filsonPro-bold">Data Retention Settings</h3>
      <div className="flex flex-col gap-6 sm:w-11/12 md:w-10/12 xl:w-3/4 font-filsonPro-book">
        <p>
          You are in full control of your coverage data retention on FullFrame. You can individually
          delete any of your uploaded source files, asked questions & answers, or full coverage
          reports.
        </p>
        <p>
          If you would like your coverage data to be automatically deleted at set intervals after
          they have been processed or created, you can customize those settings below.
        </p>
      </div>
      <div className="grid grid-cols-3 gap-6">
        <Select
          tooltip="Submissions uploaded to the FullFrame platform, such as scripts or books"
          label={'Source Files'}
          placeholder={'Select Duration'}
          items={DURATIONS}
        />
        <Select
          tooltip="The history of your questions submitted to FullFrame and answers generated by FullFrame"
          label={'Coverage Questions & Answers'}
          placeholder={'Select Duration'}
          items={DURATIONS}
        />
        <Select
          tooltip="Coverage data extracted from Submissions"
          label={'Coverage Reports'}
          placeholder={'Select Duration'}
          items={DURATIONS}
        />
      </div>
      <Button className="px-[0.7rem] !w-fit sm:px-4 py-[0.38rem] font-filsonPro-bold coverage-button">
        Save
      </Button>
    </Card>
  );
};
export default DataRetention;
