import { useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import Modal from '../../UI/Modal';
import CheckoutElement from './CheckoutElement';

const { publishableKey } = require('../../../config.json');

const stripePromise = loadStripe(publishableKey.toString());

/* eslint-disable react/prop-types */
const CardInputModal = ({ showCardModal, setShowCardModal, clientSecret }) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setShowCardModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const options = {
    loader: 'auto',
    clientSecret
  };

  return (
    <Modal onClose={() => setShowCardModal(false)} showModal={showCardModal} className="!pb-6">
      {/* eslint-disable-next-line */}
      <button className="float-right mr-3 mt-1 p-1" onClick={() => setShowCardModal(false)}>
        <i className="fas fa-times text-lg text-primary" />
      </button>
      <div className="flex flex-col gap-2 px-4 sm:px-6 md:pr-8 w-full mt-8">
        <span className="font-bold">
          <span className="mr-1">👋</span>
          <span className="lg:text-lg">Update Card Information</span>
        </span>
        <div className="text-gray-700">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutElement clientSecret={clientSecret} setShowCardModal={setShowCardModal} />
          </Elements>
        </div>
      </div>
    </Modal>
  );
};

export default CardInputModal;
