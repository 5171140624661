export const content = [
  {
    title: 'Privacy Policy',
    subtitle: '1. Introduction',
    description:
      'FullFrame is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our Services or Software.'
  },
  {
    title: '',
    subtitle: '2. Information We Collect',
    description: ''
  },
  {
    title: '',
    subtitle: '2.1. Account Creation',
    description:
      'If you create an account with FullFrame, we will collect certain personal information about you, such as your name, email address, user ID, and authentication information.'
  },
  {
    title: '',
    subtitle: '2.2. Communications with Us',
    description:
      'We may collect personal information from you such as your email address when you request information about or show interest in our Services or Software, register for our newsletter, request customer or technical support, communicate with us, or otherwise provide personal information to us.'
  },
  {
    title: '',
    subtitle: '2.3. Data Collected in Connection with Our Services',
    description:
      'We collect the information you provide to the Services (including any personal information that you choose to include when using the Services, such as your name and/or email address).'
  },
  {
    title: '',
    subtitle: '2.4. Surveys',
    description:
      'We may contact you to participate in surveys. If you decide to participate, we may ask you for certain information which may include personal information.'
  },
  {
    title: '',
    subtitle: '2.5. Community Features',
    description:
      'We may offer community features such as forums, blogs, or social media pages. Any content you provide on the public sections of these channels will be publicly accessible and is not subject to privacy protections referenced herein.'
  },
  {
    title: '',
    subtitle: '2.6. Information We Automatically Collect',
    description:
      'We may also collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, unique identifiers associated with your browser or device (including Technologies), details about your browser, operating system or device, location information (including inferred location based off of your IP address), Internet service provider, pages that you visit before, during, and after using the Services, information about the links you click, and other information about how you use the Services.'
  },
  {
    title: '',
    subtitle: '2.7. Cookies, Pixel Tags/Web Beacons, and Analytics Information',
    description:
      'We, as well as third parties that may provide content or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to collect information through the Services.'
  },
  {
    title: '',
    subtitle: '2.7.1. Cookies',
    description:
      'Cookies are small text files placed in device browsers to store their preferences. We and our third-party partners use cookies for different reasons, including to see which areas and features are popular and to count visits to our Sites.'
  },
  {
    title: '',
    subtitle: '2.7.2. Pixel Tags/Web Beacons',
    description:
      'We may include web beacons in emails to understand whether messages have been opened, acted on, or forwarded.'
  },
  {
    title: '',
    subtitle: '2.7.3. Analytics',
    description:
      'We may use Google Analytics and other service providers to collect and process analytics information on our Services.'
  },
  {
    title: '',
    subtitle: '3. How Do We Use Your Information?',
    description: ''
  },
  {
    title: '',
    subtitle: '3.1. To Provide the Services and Software',
    description:
      'Fulfilling our contract with you; responding to your questions, comments, and other requests; processing your payment information for products or Services purchased; and providing access to certain areas, functionalities, and features of our Services.'
  },
  {
    title: '',
    subtitle: '3.2. Administrative Purposes',
    description:
      'Pursuing our legitimate interests, such as research and development, network and information security, and fraud prevention; measuring interest and engagement in the Services and Software; improving the Services and Software; developing new products and services; ensuring internal quality control and safety; authenticating and verifying individual identities; carrying out audits; communicating with you about your account, the Services, and the Software; preventing and prosecuting potentially prohibited or illegal activities; enforcing our agreements; and complying with our legal obligations.'
  },
  {
    title: '',
    subtitle: '3.3. Marketing Our Products, Services, and Software',
    description:
      'We may use personal information to tailor and provide you with product announcements and other content.'
  },
  {
    title: '',
    subtitle: '3.4. Purposes for Which You Consent',
    description:
      'We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent, as required.'
  },
  {
    title: '',
    subtitle: '3.5. De-identified and Aggregated Information Use',
    description:
      'We may use personal information and other information about you to create de-identified and/or aggregated information.'
  },
  {
    title: '',
    subtitle: '4. To Whom Do We Disclose Your Personal Information?',
    description: ''
  },
  {
    title: '',
    subtitle: '4.1. Service Providers',
    description:
      'We may disclose your personal information with our service providers for the purposes described in Section 3.'
  },
  {
    title: '',
    subtitle: '4.2. Disclosures to Protect Us or Others',
    description:
      'We may access, preserve, and disclose any information we store in association with you to external parties if we, in good faith, believe doing so is required or appropriate.'
  },
  {
    title: '',
    subtitle: '4.3. Merger, Sale, or Other Asset Transfers',
    description:
      'If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, your information may be sold or transferred as part of such a transaction.'
  },
  {
    title: '',
    subtitle: '5. International Data Transfers',
    description:
      'All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to the United States or other countries.'
  },
  {
    title: '',
    subtitle: '6. Your Rights and Choices',
    description: ''
  },
  {
    title: '',
    subtitle: '6.1. General',
    description: 'You may have the right to opt out of certain uses of your personal information.'
  },
  {
    title: '',
    subtitle: '6.2. Email Communications',
    description:
      'You can unsubscribe from FullFrame emails by using the unsubscribe link found at the bottom of the email.'
  },
  {
    title: '',
    subtitle: '6.3. “Do Not Track”',
    description:
      'We do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.'
  },
  {
    title: '',
    subtitle: '6.4. Cookies',
    description:
      'You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits.'
  },
  {
    title: '',
    subtitle:
      '7. Rights for Individuals in the European Economic Area (EEA), United Kingdom, and Switzerland',
    description: ''
  },
  {
    title: '',
    subtitle: '7.1. Data Subject Rights',
    description:
      'If you are located in the EEA, United Kingdom, or Switzerland, you have certain rights under the GDPR and other applicable laws with respect to your personal data, including the right to access, correct, update, or request deletion of your personal information.'
  },
  {
    title: '',
    subtitle: '7.2. Exercising Your Rights',
    description:
      'To exercise any of these rights, please contact us using the contact details provided under the "Contact Us" section below. We will respond to your request in accordance with applicable data protection laws.'
  },
  {
    title: '',
    subtitle: '7.3. Data Transfers',
    description:
      'We may transfer your personal information to countries outside of the EEA, including the United States. When we do so, we ensure appropriate safeguards are in place, such as Standard Contractual Clauses (SCCs) approved by the European Commission.'
  },
  {
    title: '',
    subtitle: '7.4. Complaints',
    description:
      'If you have concerns about our processing of your personal information, you have the right to complain to a data protection authority.'
  },
  {
    title: '',
    subtitle: '8. Data Retention',
    description:
      'We retain the personal information we receive as described in this Privacy Policy for as long as you use our Services or Software or as necessary to fulfill the purposes for which it was collected.'
  },
  {
    title: '',
    subtitle: '9. Security',
    description:
      'We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy.'
  },
  {
    title: '',
    subtitle: '10. Third-Party Websites and Applications',
    description:
      'The Services and Software may contain links to other websites/applications and other websites/applications may reference or link to us.'
  },
  {
    title: '',
    subtitle: '11. Changes to Our Privacy Policy',
    description: 'We may revise this Privacy Policy from time to time in our sole discretion.'
  },
  {
    title: '',
    subtitle: '12. Contact Us',
    description:
      'If you have any questions or concerns about our Privacy Policy or practices, please contact us at: support@fullfr.me'
  }
];
