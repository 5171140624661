import axios from 'axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ChurnReasonEnum from '../../../enums/ChurnReason.enum';
import { ChurnReasonOptions } from '../../../utils/constant';
import getAuthToken from '../../../utils/cookie';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import RadioButtonGroup from '../../UI/RadioButtonGroup';

const ChurnModal = ({ showMessage, setShowMessage, cancelSubscription }) => {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');

  const handleClose = () => {
    setShowMessage(false);
    cancelSubscription();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setShowMessage(false);
      cancelSubscription();
      const token = getAuthToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}create/churn/`,
        {
          refer: reason,
          comment
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal onClose={handleClose} showModal={showMessage}>
      <div className="bg-dark">
        <button type="button" className="float-right mr-3 mt-1 p-1" onClick={handleClose}>
          <i className="fas fa-times text-lg text-primary" />
        </button>
        <div className="flex flex-col gap-2 px-4 sm:px-6 md:pr-8 w-full mt-8 bg-dark">
          <span className="font-bold text-white">
            <h1 className="text-2xl font-semibold text-primary subpixel-antialiased bold">
              How did you hear about us?
            </h1>
          </span>

          <form className="mt-4 pb-4 text-white">
            <RadioButtonGroup
              onChange={setReason}
              selectedOption={reason}
              options={ChurnReasonOptions}
              whiteText
            />

            {reason === ChurnReasonEnum.OTHER && (
              <div className="mb-4">
                <label htmlFor="otherText" className="block pb-2 font-medium">
                  Reason
                </label>
                <textarea
                  type="text"
                  id="otherText"
                  name="otherText"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="p-2 w-full block bg-[#e4e7e9] rounded-md shadow-sm sm:text-sm text-black"
                />
              </div>
            )}
            <div className="flex flex-row-reverse">
              <Button type="submit" onClick={handleSubmit}>
                Complete
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

ChurnModal.propTypes = {
  showMessage: PropTypes.bool.isRequired,
  setShowMessage: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired
};

export default ChurnModal;
