import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  useStripe,
  useElements,
  LinkAuthenticationElement,
  AddressElement,
  PaymentElement
} from '@stripe/react-stripe-js';
import { toast } from 'react-hot-toast';

import Button from '../UI/Button';
import { useUserDetialsContext } from '../../context/user-details';
import LogoCroped from '../../static/images/logo-croped.png';

/* eslint-disable react/prop-types */
function OneTimeCheckout({ product, credits }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const { userDetails } = useUserDetialsContext();
  const { status, email: storedEmail } = userDetails;

  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState(storedEmail || '');

  const extraTokensHandler = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    if (status === 'not_subscribed') {
      toast.error('Please subscribe to buy extra tokens.');

      setIsProcessing(false);
      return;
    }

    if (status === 'canceled') {
      toast.error('Your subscription is canceled. Please subscribe to buy extra tokens.');

      setIsProcessing(false);
      return;
    }

    if (status === 'trialing') {
      toast.error(
        'Your subscription is in the trial period. You can buy extra tokens after the trial period.'
      );
      setTimeout(() => {
        navigate('/script-coverage');
      }, 2000);
    }

    const creditsNum = +credits;

    if (isNaN(creditsNum) || creditsNum < 1 || !creditsNum) {
      toast.error('Please provide valid credits.');
      setIsProcessing(false);
      return;
    }

    if (!(product === 'book' || product === 'script')) {
      toast.error('Please provide valid product to buy credits.');
      setIsProcessing(false);
      return;
    }

    const loadingToastId = toast.loading('Processing payment...');

    const paymentResponse = await stripe?.confirmPayment({
      elements,
      redirect: 'if_required'
    });

    if (paymentResponse?.paymentIntent?.status === 'succeeded') {
      toast.dismiss(loadingToastId);
      toast.success('Payment Successful. Your credits will be added to your account shortly.');
    }

    if (paymentResponse?.paymentIntent?.status === 'requires_action') {
      toast.error('Insufficient funds. Please add funds to your card and try again.');
      setTimeout(() => {
        navigate('/script-coverage');
      }, 2000);

      setIsProcessing(false);

      return;
    }

    if (paymentResponse.error) {
      console.log(paymentResponse.error.message);
      toast.error(paymentResponse.error.message);
      setIsProcessing(false);

      return;
    }

    setIsProcessing(false);

    setTimeout(() => {
      navigate('/script-coverage');
    }, 5000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    extraTokensHandler();
  };

  return (
    <div className="bg-[#fcfcfc]">
      <div className="grid md:grid-cols-[40%,58%] lg:grid-cols-2 gap-5 p-2 sm:p-4 lg:p-8 w-full 2xl:w-10/12 mx-auto 2xl:shadow-sm bg-[#fafafa] text-black h-screen overflow-y-scroll">
        <div className="flex flex-col justify-between pt-3 sm:pt-4 md:pt-8 px-[8%] sm:px-[15%] lg:px-[10%] xl:px-[20%] bg-primary-light pb-4">
          <div className="text-center">
            <h4 className="text-center font-filsonPro-regular text-2xl lg:text-3xl text-[rgb(185,189,194)]">
              Buy Credits
            </h4>
            <div className="flex gap-2 items-center mt-6 sm:mt-8 -translate-x-6">
              {/* eslint-disable-next-line */}
              <button
                type="button"
                className="fas fa-arrow-left text-[rgb(185,189,194)] text-lg"
                onClick={() => navigate(-1)}
              />
              <img src={LogoCroped} alt="Fullframe Logo" className="w-[2rem] h-[2rem] mx-auto" />
              <h3 className="text-start text-lg text-gray-500 font-filsonPro-semibold w-full">
                Fullframe
              </h3>
            </div>
            <h3 className="text-start text-lg text-gray-500 font-filsonPro-semibold w-full mt-6 sm:mt-8">
              Pay Fullframe
            </h3>
            <h3 className="text-start text-3xl font-filsonPro-semibold w-full mt-1">
              ${credits * (product === 'book' ? 5 : 3)}
              .00
            </h3>
            <div className="flex items-center gap-3 sm:gap-4 lg:gap-5 mt-6 lg:mt-4 xl:mt-8">
              <div className="h-full">
                <i
                  className={`fas fa-${product === 'book' ? 'book' : 'gear'} text-primary text-2xl`}
                />
              </div>
              <div className="flex flex-col gap-1 sm:gap-0 w-full">
                <div className="flex justify-between text-base sm:text-lg md:text-base lg:text-lg font-filsonPro-semibold">
                  <span>
                    {product.substring(0, 1).toUpperCase()}
                    {product.substring(1)} Credits
                  </span>
                  <span>
                    ${credits * (product === 'book' ? 5: 3)}
                    .00{' '}
                  </span>
                </div>
                <div className="flex justify-between items-center w-full">
                  <div className="border-b-2">
                    {/* eslint-disable-next-line */}
                    <label
                      htmlFor="credits"
                      className="text-base sm:text-lg md:text-base lg:text-lg font-filsonPro-semibold"
                    >
                      Qty
                    </label>
                    <select
                      name="credits"
                      id="credits"
                      onChange={(e) => {
                        navigate(
                          `/billing/checkout/${product}?credit${e.target.value > 1 ? 's' : ''}=${e.target.value}`
                        );
                        setTimeout(() => {
                          window.location.reload();
                        }, 500);
                      }}
                      className="text-lg font-filsonPro-book self-start text-center bg-transparent w-[3.5rem] py-0.5 outline-none"
                      defaultValue={credits}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                  <span className="font-filsonPro-book text-gray-600">
                    ${product === 'book' ? 5 : 3}
                    .00 each
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:flex gap-2 lg:gap-4 justify-center">
            <p>
              <span className="text-[rgb(163,165,167)] font-semibold">Powered by </span>
              <span className="text-[rgb(97,97,97)] font-bold">Stripe</span>
            </p>
            <span className="text-[rgb(163,165,167)]">|</span>
            <p className="flex gap-1 sm:gap-2 font-filsonPro-book">
              <Link to="/terms-of-use" className="text-[rgb(163,165,167)] cursor-pointer">
                Terms
              </Link>
              <Link to="/privacy" className="text-[rgb(163,165,167)] cursor-pointer">
                Privacy
              </Link>
            </p>
          </div>
        </div>
        <form
          id="payment-form"
          onSubmit={handleSubmit}
          className="flex flex-col gap-6 p-4 w-full sm:w-3/4 mx-auto md:w-full shadow-[-1px_0px_8px_0px_rgba(0,0,0,0.1)]"
        >
          <LinkAuthenticationElement
            onChange={(event) => {
              setEmail(event.value.email);
            }}
            options={{ defaultValues: { email } }}
          />
          <AddressElement options={{ mode: 'billing', allowedCountries: ['US'] }} />
          <PaymentElement />
          <div className="flex gap-2 sm:gap-4 justify-center mt-3 sm:mt-5 font-semibold">
            <Button
              disabled={!stripe || isProcessing}
              type="submit"
              id="submit"
              className="!w-full !font-filsonPro-bold"
            >
              Pay
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OneTimeCheckout;
