import { motion } from 'framer-motion';

function SuggestionsItem({ suggestions, sendMessage }) {
  const suggestionsAnimation = {
    animate: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.7, delay: 0.23 }
    },
    initial: { opacity: 0, y: 20 }
  };

  return (
    <li>
      <div className="w-fit mx-auto shadow-[1px_2px_10px_2px_rgba(255,255,255,0.1)] pt-2 p-4 rounded-lg">
        <p className="text-center text-lg font-semibold text-white my-2">Suggested Prompts 🔥</p>
        <ul className="flex flex-col gap-2 justify-centr items-cener">
          {suggestions.map((suggestion, index) => (
            <motion.li
              key={suggestion}
              className="flex items-center justify-start gap-2 w-fit pl-1 pr-2 sm:pr-3 font-semibold bg-primary hover:bg-primary-dark transition-all duration-500 rounded-lg"
              animate={{
                y: 0,
                opacity: 1,
                transition: { duration: 0.7, delay: 0.23 + index ? index * 0.1 : 0 }
              }}
              initial={suggestionsAnimation.initial}>
              <button
                type="button"
                className="text-start py-1 px-2.5"
                onClick={() => sendMessage(suggestion)}>
                {suggestion}
              </button>
            </motion.li>
          ))}
        </ul>
      </div>
    </li>
  );
}

export default SuggestionsItem;
