/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const DataContainer = ({ children, className = '', colSpan }) => {
  const basePadding = 8;
  return (
    <td
      css={css`
        padding-right: ${basePadding}px;

        @media (min-width: 2000px) {
          padding-right: calc(${basePadding}px + 0.2vw);
        }
      `}
      className={`w-fit py-3.5 text-xs xl:text-sm whitespace-nowrap text-white text-center sm:text-start align-middle z-10 ${className}`}
      colSpan={colSpan || 1}>
      {children}
    </td>
  );
};

export default DataContainer;
