import { Helmet } from 'react-helmet';

import { content } from '../static/data/FaqContent';
import Card from '../components/UI/Card';

const Faq = () => (
  <Card className="flex flex-col gap-6 md:gap-8">
    <Helmet>
      <title>FAQ | FullFrame</title>
      <meta
        name="description"
        content="Welcome to our FAQ page! Here, we'll address some of the most common questions we receive about our productivity software."
      />
    </Helmet>
    {content.map((item, index) => (
      <div className="flex flex-col gap-5 md:gap-6" key={item.title}>
        <h3
          className={`text-lg md:text-xl font-filsonPro-bold ${!item.title && 'hiden'} ${index !== 0 && item.title && 'mt-6'}`}
        >
          {item.title}
        </h3>
        <p className={`${index === 0 ? 'block' : 'hidden'}`}>
          Welcome to our FAQ page! Here, we&apos;ll address some of the most common questions we
          receive about our productivity software.
        </p>
        {item?.subcontent?.map((subItem, index2) => (
          <div className="flex flex-col gap-2 md:gap-[0.625rem]" key={subItem.question}>
            <h4 className="text-primary font-filsonPro-semibold">{subItem.question}</h4>
            <p>{subItem.answer}</p>
          </div>
        ))}
      </div>
    ))}
    <p className="text-lg">
      We hope this FAQ page has answered some of your questions and concerns about our productivity
      software for the film and TV industry. If you have any further questions, please don&apos;t
      hesitate to contact us at{' '}
      <a href="mailto:Support@Fullfr.me" className="text-primary">
        Support@Fullfr.me
      </a>
    </p>
  </Card>
);

export default Faq;
