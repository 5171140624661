import { useClickOutside, useDebouncedValue } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import Button from '../components/UI/Button';
import { apiInstance } from '../utils/apiInstance';
import toast from 'react-hot-toast';
import { isArray, isEmpty } from 'lodash-es';
import SearchIcon from '../components/icons/SearchIcon';

const ListGeneratorAutocomplete = ({ type, onAdd }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [debouncedSearch] = useDebouncedValue(query, 500);
  const [isSearching, setIsSearching] = useState(false);
  // Function to simulate API call
  const fetchResults = async (searchTerm) => {
    if (!type) return toast.error('Please select a type of search before searching');
    if (searchTerm?.length < 3) return toast.error('Please enter atleast 3 characters');
    setIsSearching(true);
    if (searchTerm === '') {
      return setResults([]);
    }

    const { data } = await apiInstance(window.location).get(`list/talents/`, {
      params: {
        query: searchTerm,
        credit_type: type
      }
    });
    const resultsList = isEmpty(data?.data) ? [] : isArray(data?.data) ? data?.data : [data?.data];
    setResults(resultsList);
    setIsSearching(false);
  };

  // Handle query input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
  };

  useEffect(() => {
    if (debouncedSearch?.length > 2) {
      fetchResults(debouncedSearch);
    } else {
      setResults([]);
    }
  }, [debouncedSearch]);

  const divRef = useClickOutside(() => {
    setQuery('');
    setResults([]);
  });
  return (
    <div className="relative w-full" ref={divRef}>
      <div className="flex items-center w-full border border-orange rounded-lg overflow-hidden">
        <input
          disabled={!type}
          className="w-full h-[40px] outline-none px-4 py-2 !text-[#101828]"
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
        />
        <Button
          disabled={!type}
          loading={isSearching}
          className={
            'h-[40px] rounded-l-none !px-6 bg-[#F2F4F7] border-[#E4E7EC] text-[#344054] hover:bg-[#F2F4F7] hover:opacity-90 disabled:bg-gray-400'
          }
          onClick={() => fetchResults(query)}>
          <SearchIcon />
          Search
        </Button>
      </div>

      {results.length > 0 && query && (
        <div className="absolute left-0 right-0 mt-2 bg-gray-800 shadow-lg rounded-lg border border-[#344054]">
          {results.map((result, idx) => {
            const latest_project = result.projects?.past_projects?.[0];
            return (
              <React.Fragment key={result.id}>
                <div className="flex items-center p-3 hover:bg-gray-700 transition">
                  <img
                    src={`https://image.tmdb.org/t/p/original/${result.profile_path}`}
                    alt={result.name}
                    className="w-10 h-10 rounded-md mr-4 border border-[#344054] object-cover"
                  />
                  <div>
                    <p className="text-white font-semibold">{result.name}</p>
                    {latest_project && (
                      <p className="text-gray-400 text-sm">
                        {latest_project?.title} (
                        {new Date(latest_project?.release_date)?.getFullYear?.()})
                      </p>
                    )}
                  </div>
                  <Button
                    className="ml-auto bg-orange-500 text-white p-1 rounded hover:bg-orange-600 font-medium"
                    onClick={() => {
                      onAdd?.(result);
                      setQuery('');
                    }}>
                    + Add
                  </Button>
                </div>
                {idx === results?.length - 1 ? null : (
                  <hr className="!bg-[#1D2939] h-[1px] !border-[#344054]" />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ListGeneratorAutocomplete;
