import React, { useState } from 'react';
import axios from 'axios';
import Button from '../components/UI/Button';
import cookieVerify from '../utils/cookie';
import { ReactComponent as UploadIcon } from '../static/icons/upload.svg';
import { ReactComponent as CrossIcon } from '../static/icons/cross.svg';
import Tooltip from '@mui/material/Tooltip';
import Card from '../components/UI/Card';
import ReportGenerationModal from '../components/coverage/Modal/ReportGenerationModal';
import MessageModal from '../components/coverage/Modal/MessageModal';
import ConfirmationModal from '../components/coverage/Modal/ConfirmationModal';
import { toast } from 'react-hot-toast';
import { useSubmissionsContext } from '../context/submissions';
import { useUserDetialsContext } from '../context/user-details';
import redirectToLogin from '../utils/token';

const DraftComparison = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [unified, setUnified] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [reportGenerationModal, setReportGenerationModal] = useState(false);
  const [reportGeneration, setReportGeneration] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const { setSubmissionData } = useSubmissionsContext();
  const { updateUserTokens } = useUserDetialsContext();

  const handleFileChange = (event, setFile) => {
    setFile(event.target.files[0]);
  };

  const handleGenerateComparison = async () => {
    if (!file1 || !file2) {
      return;
    }

    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);
    formData.append('unified', unified);
    const tok = cookieVerify(location);
    setReportGeneration(true);
    setReportGenerationModal(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}compare_drafts/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tok}`
          }
        }
      );
    } catch (error) {
      console.error('Error:', error);
      setReportGeneration(false);
      setReportGenerationModal(false);

      if (error.response) {
        if (error.response.status === 400) {
          toast.error(
            'Something went wrong. Please try again. If the error persists, please contact our support'
          );
        } else if (error.response.status === 401) {
          toast.error('Session expired. Please log in again to continue');
          setTimeout(() => {
            redirectToLogin();
          }, 2000);
        } else {
          setShowMessage(true);
        }
      } else {
        setShowMessage(true);
      }
    } finally {
      setReportGeneration(false);
      setReportGenerationModal(false);
    }

    const new_comparison_submission = {
      isArchived: false,
      priority: 'Available Material',
      status: 'PROCESSING',
      type: 'COMPARISON',
      title: `${file1.name} vs ${file2.name}`,
      company_name: '',
      note: '',
      created_at: new Date()
    };
    setSubmissionData((prev) => [new_comparison_submission, ...prev]);
  };

  const renderFileUploadButton = (file, setFile, label) => {
    if (!file) {
      return (
        <label
          className="group py-2 px-6 hover:scale-105 font-filsonPro-semibold duration-200 rounded-md cursor-pointer upload-section border text-primary border-primary hover:text-secondary hover:border-secondary text-center relative flex items-center justify-center"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          <span className="cursor-pointer">{label}</span>
          <input
            type="file"
            accept=".pdf,.docx,.doc"
            onChange={(e) => handleFileChange(e, setFile)}
            style={{ display: 'none' }}
          />
        </label>
      );
    } else {
      return (
        <div
          className="group flex items-center py-2 px-6 font-filsonPro-semibold rounded-md border text-primary border-primary hover:text-secondary hover:border-secondary text-center relative cursor-pointer"
          style={{
            minWidth: '250px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          onClick={() => setFile(null)}
          aria-label={`Remove ${label}`}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setFile(null);
            }
          }}>
          <span className="flex-1 overflow-hidden">{file.name}</span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setFile(null);
            }}
            className="ml-2 bg-transparent border-none cursor-pointer focus:outline-none flex items-center justify-center"
            aria-label="Remove file">
            <CrossIcon className="h-4 w-4 text-orange-500 group-hover:text-secondary" />
          </button>
        </div>
      );
    }
  };

  return (
    <Card className="pt-4">
      <div className="flex flex-col gap-5 md:gap-7">
        <div className="flex flex-col gap-2 md:gap-3">
          <h3 className="text-lg md:text-xl font-filsonPro-bold text-white">Upload Versions</h3>
          <p className="text-gray-400 text-sm md:text-base">
            Upload the current version and prior version in .pdf or .docx to get a draft comparison
            template complete with headline changes, character changes, and detailed page notes.
          </p>
        </div>

        {/* Upload Section */}
        <div className="flex items-center justify-center border border-[rgba(255,255,255,0.1)] border-dashed bg-gradient-to-b from-[rgba(254,102,41,0.048)] to-[rgba(254,102,41,0.12)] rounded-[10px] shadow-inner">
          <div className="flex flex-col items-center justify-center gap-4 my-4 md:my-6 lg:my-8">
            <UploadIcon className="h-14 md:h-full" />
            <h4 className="font-filsonPro-bold text-white">Upload Your Files Here</h4>
            <span className="text-gray-400">Limit 200MB Per File (PDF, DOCX, or DOC)</span>
            <div className="flex flex-row items-center justify-center gap-4 mt-4">
              {renderFileUploadButton(file1, setFile1, 'Upload Previous Version')}
              {renderFileUploadButton(file2, setFile2, 'Upload New Version')}
            </div>
          </div>
        </div>

        {/* Generate Comparison Button */}
        <div className="flex justify-center mt-4">
          <Tooltip
            title={!file1 || !file2 ? 'Please select both drafts' : ''}
            enterDelay={500}
            leaveDelay={200}
            placement="top">
            <span style={{ cursor: 'pointer' }}>
              <Button
                onClick={handleGenerateComparison}
                disabled={!file1 || !file2}
                style={{ pointerEvents: 'all' }}
                className={'font-filsonPro-semibold'}>
                Generate Comparison
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        suretyClickHandler={(choice) => {
          if (choice === 'yes') {
            handleGenerateComparison();
          } else {
            setConfirmationModal(false);
          }
        }}
      />

      {/* Report Generation Modal */}
      <ReportGenerationModal
        reportGenerationModal={reportGenerationModal}
        setReportGenerationModal={setReportGenerationModal}
        selectedFile={file2}
      />

      {/* Error Message Modal */}
      <MessageModal
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        title={<span className="lg:text-lg">Error Generating Comparison</span>}
        message={
          <div className="flex flex-col items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
            <p className="font-semibold self-start">
              Something went wrong while generating the comparison. Please try again later.
            </p>
            <Button className="!font-inter !font-semibold" onClick={() => setShowMessage(false)}>
              Close
            </Button>
          </div>
        }
        noBottomMargin
      />
    </Card>
  );
};

export default DraftComparison;
