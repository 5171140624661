const redirectToLogin = async () => {
  const url = 'https://FullFrme.b2clogin.com/FullFrme.onmicrosoft.com/oauth2/v2.0/authorize';
  const query_params = {
    p: process.env.REACT_APP_POLICY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    nonce: 'defaultNonce',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'openid',
    response_type: 'id_token',
    prompt: 'login',
    response_mode: "query"

  };

  const encodedParams = new URLSearchParams(query_params).toString();
  const redirectUrl = `${url}?${encodedParams}`;

  // Redirect the user to the Azure AD B2C login page
  window.location.replace(redirectUrl);
};

const reDirectToSignUp = async () => {
  const url = 'https://FullFrme.b2clogin.com/FullFrme.onmicrosoft.com/oauth2/v2.0/authorize';
  const query_params = {
    p: process.env.REACT_APP_POLICY_LOGIN,
    client_id: process.env.REACT_APP_CLIENT_ID,
    nonce: 'defaultNonce',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'openid',
    response_type: 'id_token',
    prompt: 'login',
    response_mode: "query"
  };

  const encodedParams = new URLSearchParams(query_params).toString();
  const redirectUrl = `${url}?${encodedParams}`;

  // Redirect the user to the Azure AD B2C signup page
  window.location.replace(redirectUrl);
};

export default redirectToLogin;
export { reDirectToSignUp };
