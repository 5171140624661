import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

/* eslint-disable react/prop-types */
const Button = ({
  element = null,
  to = '',
  id,
  className,
  onClick,
  type,
  disabled = false,
  loading = false, // Add loading prop
  children,
  variant = 'primary'
}) => {
  const baseStyles =
    'p-2 md:px-3 text-white h-fit w-fit transition-all duration-200 flex gap-2 items-center justify-center text-sm h-[38px] rounded-[4px] cursor-pointer';

  const variants = {
    primary: !disabled && !loading ? 'bg-primary hover:bg-primary-dark' : 'bg-[rgb(224,139,108)]',
    gray: !disabled && !loading ? 'bg-gray-500 hover:bg-gray-600' : 'bg-gray-300',
    secondary: !disabled && !loading ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-300'
  };

  const mergedClasses = twMerge(baseStyles, variants[variant], className);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center gap-2">
          <span className="loader"></span>{' '}
          {/* Add a loader spinner (you can customize this with Tailwind) */}
          Loading...
        </div>
      );
    }
    return children;
  };

  if (element === 'link') {
    return (
      <Link
        to={!disabled && !loading ? to : ''}
        className={mergedClasses}
        id={id || ''}
        onClick={!loading ? onClick : null}>
        {renderContent()}
      </Link>
    );
  }

  return (
    <button
      className={mergedClasses}
      id={id || ''}
      type={type || 'button'}
      disabled={disabled || loading} // Disable button when loading
      onClick={!loading ? onClick : null}>
      {renderContent()}
    </button>
  );
};

export default Button;
