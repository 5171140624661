import React from 'react';
import { useSubmissionsContext } from '../../../context/submissions';

const RenderDropdownPills = ({ headings }) => {
  const { hiddenColumns, setHiddenColumns } = useSubmissionsContext();

  const titleClickHandler = (title) => {
    setHiddenColumns((prevHiddenColumns) => {
      let updatedHiddenColumns;

      if (prevHiddenColumns?.includes(title)) {
        updatedHiddenColumns = prevHiddenColumns.filter((prop) => prop !== title);
      } else {
        updatedHiddenColumns = [...prevHiddenColumns, title];
      }

      // Storing the updated hiddenColumns array to localStorage
      localStorage.setItem('hiddenColumns', JSON.stringify(updatedHiddenColumns));

      return updatedHiddenColumns;
    });
  };

  return (
    <ul className="flex flex-wrap gap-1">
      {headings?.map((heading, index) => {
        return (
          <li
            className={`${index === 1 && 'col-span-2'} ${index === 2 && 'col-span-2'} ${index === 7 && 'col-span-2'}`}>
            <button
              type="button"
              onClick={() => titleClickHandler(heading)}
              className={`px-2 py-[0.28125rem] text-xs bg-[#52526F40] hover:bg-[#4d5464] ${hiddenColumns?.includes(heading) ? 'text-[#DCD8FE50] border-[#52526F40]' : 'text-[#EEEFFC] border-transparent'} rounded-[4px] border`}>
              <span>{heading}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default RenderDropdownPills;
