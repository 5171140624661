import PropTypes from 'prop-types';

const RadioButtonGroup = ({ options, selectedOption, onChange, whiteText = false }) => (
  <div className="mb-4">
    <div className="flex flex-col space-y-2">
      {Object.keys(options).map((key) => (
        <label key={key} className="inline-flex items-center">
          <input
            required
            type="radio"
            className="form-radio accent-[#FF4013]"
            name="source"
            value={key}
            checked={selectedOption === key}
            onChange={() => onChange(key)}
          />
          <span className={`ml-2 ${whiteText ? 'text-white' : 'text-black'}`}>{options[key]}</span>
        </label>
      ))}
    </div>
  </div>
);

RadioButtonGroup.propTypes = {
  options: PropTypes.shape({
    [PropTypes.string.isRequired]: PropTypes.string.isRequired
  }).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RadioButtonGroup;
