import { useEffect } from 'react';
import { ReactComponent as PDFIcon } from '../../static/icons/pdf.svg';
import { ReactComponent as Spinner } from '../../static/icons/spinner.svg';
import { ReactComponent as WordIcon } from '../../static/icons/word.svg';
import { GreenBorderedCheckIcon } from '../icons/icons';
import Button from '../UI/Button';
import Modal from '../UI/Modal';
import ModalHeader from './ModalHeader';

const EmbeddingModal = ({
  reportGenerationModal,
  setReportGenerationModal,
  selectedFile,
  isLoading = true
}) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setReportGenerationModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);
  const fileExtension = selectedFile?.name?.split('.')?.pop()?.toLowerCase();
  const returnFileIcon = () => {
    if (fileExtension === 'pdf') {
      return <PDFIcon className="h-9 stroke-gray-500" />;
    } else if (['docx', 'doc']?.includes(fileExtension)) {
      return <WordIcon className="h-9 stroke-gray-500" />;
    } else {
      return null;
    }
  };
  const name = selectedFile?.name || selectedFile?.title;
  return (
    <Modal
      onClose={() => setReportGenerationModal(false)}
      showModal={reportGenerationModal}
      className="!w-fit">
      <div className="flex flex-col items-center justify-center gap-4 w-full ">
        <ModalHeader text="Embedding is in progress" />
        <div className="flex flex-col gap-4 px-4 md:pl-6 md:pr-6 lg:pr-8 w-full">
          <div className="flex flex-col gap-1">
            <p className="text-gray-600">Your embedding is in progress.</p>
            <p className="text-gray-600">
              You will be redirected to the query page once it is completed.
            </p>
          </div>
          <span className="font-semibold">
            <span className="text-primary">*</span> Feel free to close this message.
          </span>
        </div>
        <div className="flex flex-col gap-5 px-6 w-full">
          <div className="flex items-center justify-between pl-8 pr-4 border border-gray-400 backdrop:filter-blur(15px) rounded-[10px]">
            <div className="flex gap-3 items-center py-3">
              {returnFileIcon()}
              <div className="flex flex-col font-filsonPro-semibold">
                <span>{`${name?.substring(0, 50)}${name?.length > 50 ? '...' : ''}`}</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {isLoading ? (
                <Spinner className="animate-spin h-8 w-8 fill-primary" />
              ) : (
                <GreenBorderedCheckIcon />
              )}
            </div>
          </div>
          <div className="flex justify-center w-full mb-4">
            <Button
              className="flex items-center justify-center gap-2"
              onClick={() => setReportGenerationModal(false)}>
              <i className="fas fa-times-square" />
              <span className="">Close</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmbeddingModal;
