import toast from 'react-hot-toast';

const confirmSetupIntent = async (stripe, clientSecret, paymentMethodId) => {
  toast.loading('Processing...');
  stripe
    ?.confirmCardSetup(clientSecret, {
      payment_method: paymentMethodId
    })
    .then((result) => {
      // Handle result.error or result.paymentIntent
      if (result.error) {
        console.log(result.error);
        if (result.error.code) {
          toast.error(result.error.code);
        }
      } else {
        toast.success('Successfull');
      }
    });
};

export default confirmSetupIntent;
