import React, { useEffect, useState } from 'react';
import {
  addSubmissionDataApi,
  getSubmissionsDataApi,
  getSubmissionsLongDataApi
} from '../api/submissions-database';
import Button from '../components/UI/Button';
import PlusIcon from '../components/icons/PlusIcon';
import SubmissionsTable from '../components/table/SubmissionsTable';
import { useSubmissionsContext } from '../context/submissions';
import { exportSubmissions } from '../utils/exportSubmissions';
import { sortDataByFields } from '../utils/sortDataByFields';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ReactGA from 'react-ga4';
import { toast } from 'react-hot-toast';
import { ActiveIcon, ArchiveIcon } from '../components/icons/icons';
import GrayButton from '../components/submissions-database/GrayButton';
import DisplayPropertiesDropdown from '../components/submissions-database/dropdown/DisplayPropertiesDropdown';
import { useUserDetialsContext } from '../context/user-details';
import isProcessingTooLong from '../utils/isProcessingTooLong';
import redirectToLogin from './../utils/token';

const SubmissionsDatabase = () => {
  const { submissionsData, setSubmissionData, loading, setLoading, setHiddenColumns } =
    useSubmissionsContext();
  const [csvExportInProgress, setCsvExportInProgress] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { userDetails } = useUserDetialsContext();
  const [displayTable, setDisplayTable] = useState('active');

  const setAndSortSubmissionsList = (submissionList) => {
    const sortCriteria = localStorage.getItem('sortingCriteria');
    if (sortCriteria) {
      const sortedData = sortDataByFields(submissionList, JSON.parse(sortCriteria));
      setSubmissionData(sortedData);
    } else {
      setSubmissionData(submissionList);
    }
  };
  const addSubmission = async () => {
    setInProgress(true);
    const newSubmission = {
      read: false,
      response_status: false,
      isArchived: false,
      priority: 'General',
      status: 'COMPLETED',
      type: 'SCRIPT',
      title: '',
      writer: [],
      director: [],
      producers: [],
      company_name: '',
      submitted_by: '',
      genre: [],
      logline: '',
      note: '',
      publisher: [],
      agency: ''
    };

    const presentFields = { ...newSubmission };
    delete presentFields.agency;
    delete presentFields.publisher;

    try {
      const response = await addSubmissionDataApi({ data: newSubmission });
      const newId = response.data.data._id;
      const createdSubmission = { ...newSubmission, _id: newId, created_at: new Date() };

      setSubmissionData((prevData) => {
        return [createdSubmission, ...prevData];
      });
    } catch (error) {
      console.error('Error adding submission:', error);
    } finally {
      setInProgress(false);
    }
  };

  const handleCSVExport = async () => {
    try {
      setCsvExportInProgress(true);
      const response = await getSubmissionsDataApi();
      const submissionList = response?.data?.data;

      if (!submissionList) {
        console.error('No data returned from API');
        return;
      }

      const sortCriteria = localStorage.getItem('sortingCriteria');
      if (sortCriteria) {
        const sortedData = await sortDataByFields(submissionList, JSON.parse(sortCriteria));
        exportSubmissions(sortedData);
      } else {
        exportSubmissions(submissionList);
      }
    } catch (error) {
      console.error('Error fetching submissions data:', error);
    } finally {
      setCsvExportInProgress(false);
    }
  };

  const returnSubmissionsTable = () => {
    if (displayTable === 'active') {
      return <SubmissionsTable loading={loading} filterSubmissionData={activeData} />;
    } else if (displayTable === 'archived') {
      return (
        <SubmissionsTable loading={loading} filterSubmissionData={archivedData} isArchiveTable />
      );
    }
    return null;
  };

  const submissionLongPolling = async () => {
    try {
      const isProcessing = submissionsData?.some((item) => {
        return item.status === 'PROCESSING' && !isProcessingTooLong(item?.created_at);
      });
      if (!isProcessing) return;
      const response = await getSubmissionsLongDataApi();
      const submissionList = response?.data?.data;
      setAndSortSubmissionsList(submissionList);
    } catch (error) {
      console.log('error', error);
      if (error?.response?.status === 408) {
        submissionLongPolling();
      } else if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again to continue.');
        setTimeout(() => {
          redirectToLogin();
        }, 2000);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (submissionsData?.length > 0 && !loading) {
      submissionLongPolling();
    }
    const storedHiddenColumns = localStorage.getItem('hiddenColumns');
    if (storedHiddenColumns) {
      setHiddenColumns(JSON.parse(storedHiddenColumns));
    }
  }, [submissionsData]);

  useEffect(() => {
    if (userDetails && userDetails.oid) {
      try {
        ReactGA.event({
          category: 'Page Visit',
          action: 'Material Log Visit by OID',
          label: `${userDetails.oid}`
        });
      } catch (err) {
        console.error('Error setting user ID or sending pageview to GA', err);
      }
    }
  }, [userDetails?.oid]);

  const activeData = submissionsData.filter((record) => !record?.isArchived);
  const archivedData = submissionsData.filter((record) => record?.isArchived);
  const scaledContainerStyle = css`
    transform-origin: top left;

    @media (max-width: 1800px) {
      transform: ${!loading ? 'scale(0.75)' : 'scale(1)'};
      width: ${!loading ? '131%' : '100%'};
    }

    @media (min-width: 1800px) and (max-width: 2800px) {
      transform: scale(1);
    }

    @media (min-width: 2801px) {
      transform: scale(1);
    }
  `;

  return (
    <section className="z-[50] database-submissions-container submission-table-container">
      <div className="pt-8 px-4 sm:px-3 md:px-4 lg:px-6 py-4">
        <h2 className="text-lg font-inter">Submissions</h2>
        <div className="flex  items-center justify-between gap-3 mt-4">
          <div className="flex items-center gap-2">
            <GrayButton
              text="Active"
              icon={<ActiveIcon />}
              onClick={() => setDisplayTable('active')}
              className="rounded-lg"
              selected={displayTable === 'active'}
              isTableToggle
            />
            <GrayButton
              text="Archived"
              icon={<ArchiveIcon className="!h-5 !w-5" />}
              onClick={() => setDisplayTable('archived')}
              className="rounded-lg"
              selected={displayTable === 'archived'}
              isTableToggle
            />
          </div>
          <div className="flex items-center gap-1.5 md:gap-3">
            <GrayButton
              text="Add row"
              icon={<PlusIcon />}
              onClick={addSubmission}
              loading={inProgress}
            />
            <DisplayPropertiesDropdown />
            <GrayButton
              text="Export Table"
              icon={<PlusIcon />}
              onClick={handleCSVExport}
              loading={csvExportInProgress}
            />
            <Button
              element={'link'}
              to="/script-coverage"
              className={
                'flex items-center gap-1.5 !px-2 md:!px-3.5 !py-1.5 !h-fit font-inter !rounded'
              }>
              <PlusIcon />
              <span>Generate Coverage</span>
            </Button>
          </div>
        </div>
      </div>
      <div css={scaledContainerStyle}>
        <div className={'p-0 rounded-[10px] w-full'}>
          <div className="relative h-full w-full">{returnSubmissionsTable()}</div>
        </div>
      </div>
    </section>
  );
};

export default SubmissionsDatabase;
