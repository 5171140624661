import mammoth from 'mammoth';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const countWords = (text) => {
  return text.trim().split(/\s+/).length;
};

export const extractTotalPagesFromDOCX = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value.length / 2000;
  } catch (error) {
    console.error('Error extracting text from DOCX:', error);
    return 0;
  }
};

export const extractTotalPagesFromPDF = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const typedarray = new Uint8Array(arrayBuffer);
    const pdf = await pdfjs.getDocument(typedarray).promise;
    return pdf.numPages;
  } catch (error) {
    console.error('Error extracting text from PDF:', error);
    return 0;
  }
};

export const extractTotalPagesFromDOC = async (file) => {
  try {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const wordCount = countWords(content);
      const AVERAGE_NUM_OF_WORDS_IN_PAGE = 400;
      const estimatedPages = Math.ceil(wordCount / AVERAGE_NUM_OF_WORDS_IN_PAGE);
    };
    reader.readAsText(file);
  } catch (error) {
    console.error('Error extracting text from DOC:', error);
    return 0;
  }
};

export const extractTotalPageFromFile = async (file) => {
  const fileExtension = file.name.split('.').pop().toLowerCase();

  switch (fileExtension) {
    case 'docx':
      return await extractTotalPagesFromDOCX(file);
    case 'pdf':
      return await extractTotalPagesFromPDF(file);
    case 'doc':
      return await extractTotalPagesFromDOC(file);
    default:
      throw new Error('Unsupported file type');
  }
};
