import { toast } from 'react-hot-toast';

const confirmSetup = async (stripe, elements, clientSecret) => {
  const loadingToastId = toast.loading('Creating Payment Method...');

  const defaultResponse = {
    status: 500
  };

  elements.submit();
  const response = await stripe?.confirmPayment({
    elements,
    redirect: 'if_required'
  });

  if (response.error) {
    const paymentMethodCreationError = response.error;
    if (paymentMethodCreationError) {
      toast.dismiss(loadingToastId);

      if (paymentMethodCreationError.code) {
        toast.error(paymentMethodCreationError.code);
      }
    }
  } else {
    toast.success('Successfull.');
    defaultResponse.status = 200;
  }

  return defaultResponse;
};

export default confirmSetup;
