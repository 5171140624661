import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import confirmSetup from '../../../utils/billing/confirmSetup';
import Button from '../../UI/Button';

// eslint-disable-next-line react/prop-types
function CheckoutElement({ clientSecret, setShowCardModal }) {
  const elements = useElements();
  const stripe = useStripe();

  const cardSubmitHandler = async (e) => {
    e.preventDefault();
    const res = await confirmSetup(stripe, elements, clientSecret);

    if (res?.status === 200) {
      setShowCardModal(false);
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_REDIRECT_URI;
      }, 2500);
    }
  };

  return (
    <form id="layout-payment-form" onSubmit={cardSubmitHandler} className="p-2 sm:p-4">
      <PaymentElement />
      <div className="flex gap-2 sm:gap-4 justify-center mt-3 sm:mt-5 font-semibold">
        <Button disabled={!stripe} type="submit" id="layout-submit" className="!w-full">
          Update
        </Button>
      </div>
    </form>
  );
}

export default CheckoutElement;
