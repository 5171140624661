import React from 'react';
import { ChevronDownIcon } from '../icons/icons';
import MultiOptionItem from './MultiOptionItem';

const RenderMultiOption = ({ item, isActive, isOpened, onClick }) => {
  return (
    <li
      key={item.id}
      className={`flex flex-col gap-2 px-2.5 py-1.5 w-full overflow-hidden transition-all duration-300  ${isOpened ? 'max-h-60' : 'max-h-11'}`}>
      <div className="flex items-center gap-2 cursor-pointer" onClick={() => onClick(item.id)}>
        <span>{item.icon(isActive)}</span>
        <span className="text-[11px] font-semibold">{item?.title}</span>
        <ChevronDownIcon
          className={`${isOpened ? '-rotate-90' : ''} !h-4 !w-4 !text-gray-300 transition-all duration-300`}
        />
      </div>
      <ul className={`${isOpened ? 'visible' : 'invisible'}`}>
        {item?.subItems?.map((subItem) => (
          <MultiOptionItem subItem={subItem} />
        ))}
      </ul>
    </li>
  );
};

export default RenderMultiOption;
