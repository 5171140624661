import React from 'react';

const ExportIcon = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.66675 4.00001H4.00008C3.2637 4.00001 2.66675 4.59696 2.66675 5.33334V12C2.66675 12.7364 3.2637 13.3333 4.00008 13.3333H10.6667C11.4031 13.3333 12.0001 12.7364 12.0001 12V9.33334M9.33341 2.66667H13.3334M13.3334 2.66667V6.66667M13.3334 2.66667L6.66675 9.33334"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ExportIcon;
