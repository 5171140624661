import isProcessingTooLong from '../../utils/isProcessingTooLong';
import { CheckCircledIcon, CrossCircledIcon, ProcessingIconDashed } from '../icons/icons';
import parseDate from '../../utils/parseDate';

const RenderStatus = ({ item }) => {
  const status = item?.status?.toLowerCase();
  const createdAt = parseDate(item?.created_at);

  function renderStatusContent(status, createdAt) {
    if (status === 'completed') {
      return {
        icon: <CheckCircledIcon />,
        text: createdAt.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
      };
    } else if (status === 'processing' && !isProcessingTooLong(createdAt)) {
      // If processing for too long, treat it as failed

      return {
        icon: <ProcessingIconDashed className="spin" />,
        text: 'Processing'
      };
    } else {
      return {
        icon: <CrossCircledIcon />,
        text: 'Failed'
      };
    }
  }

  const statusContent = renderStatusContent(status, createdAt);

  return statusContent ? (
    <div className="flex items-center gap-2">
      {statusContent.icon}
      <span className="text-xs font-normal">{statusContent.text}</span>
    </div>
  ) : null;
};

export default RenderStatus;