import React from 'react';

const PageContainer = ({ children, className = '' }) => {
  return (
    <section
      id="page_section"
      className={`flex flex-col gap-4 sm:gap-6 md:gap-8 w-full h-full pt-6 lg:pt-8 px-4 md:px-6 lg:pr-8 lg:pl-[16rem] mb-6 ${className}`}>
      {children}
    </section>
  );
};

export default PageContainer;
