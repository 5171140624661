const ChurnReasonEnum = {
  NOT_ENOUGH_VOLUME: 'NOT_ENOUGH_VOLUME',
  REPORT_QUALITY_NEEDS_IMPROVEMENT: 'REPORT_QUALITY_NEEDS_IMPROVEMENT',
  DID_NOT_USE_ENOUGH: 'DID_NOT_USE_ENOUGH',
  PRICING_TOO_EXPENSIVE: 'PRICING_TOO_EXPENSIVE',
  NEED_MORE_FEATURES: 'NEED_MORE_FEATURES',
  INTEND_TO_USE_DIFFERENT_TOOL: 'INTEND_TO_USE_DIFFERENT_TOOL',
  OTHER: 'OTHER'
};

export default ChurnReasonEnum;
