import PropTypes from 'prop-types';
import Button from '../../UI/Button';
import BuyMoreCreditsDropDown from '../../billing/BuyMoreCreditsDropDown';
import StripePricingTable from '../../billing/pricingTable';
import MessageModal from './MessageModal';

const UploadFileModal = ({
  status,
  showMessage,
  setShowMessage,
  endTrialClickHandler = () => { },
  isScript,
  credits,
  setCredits
}) => {
  const getComponentAsPerStatus = (statusText) => {
    let component;
    let text;
    switch (statusText) {
      case 'trialing':
        text =
          'Would you like to start your subscription today and continue with uninterrupted coverage?';
        component = (
          <div className="flex flex-col p-4 items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
            <p className="font-semibold self-start">{text}</p>
            <Button
              onClick={endTrialClickHandler}
              className="!font-inter !font-semibold self-center">
              Start Paid Subscription
            </Button>
          </div>
        );
        break;
      case 'not_subscribed':
        component = (
          <div className="max-h-[670px] overflow-y-scroll">
            <p className="font-semibold self-start">{text}</p>
            <StripePricingTable />
          </div>
        );
        break;
      default:
        component = (
          <div className="overflow-y-auto">
            <BuyMoreCreditsDropDown isScript={isScript} credits={credits} setCredits={setCredits} />
          </div>
        );
        break;
    }
    return component;
  };

  const getTitleAsPerStatus = (statusText) => {
    let text;
    switch (statusText) {
      case 'trialing':
        text = "You've fully utilized your FullFrame trial credits!";
        break;
      case 'not_subscribed':
        text = 'Please Subscribe to Start Generating Coverage';
        break;
      default:
        text =
          'You do not have enough credits to generate the report. Please purchase more credits.';
        break;
    }
    return <span className="text-lg">{text}</span>;
  };

  return (
    <MessageModal
      className="lg:w-[70%] xl:w-[60%] 2xl:w-[80%]"
      title={getTitleAsPerStatus(status)}
      message={getComponentAsPerStatus(status)}
      showMessage={showMessage}
      setShowMessage={setShowMessage}
      noBottomMargin
    />
  );
};

UploadFileModal.propTypes = {
  status: PropTypes.string.isRequired,
  showMessage: PropTypes.bool.isRequired,
  setShowMessage: PropTypes.func.isRequired,
  endTrialClickHandler: PropTypes.func.isRequired,
  isScript: PropTypes.bool.isRequired,
  credits: PropTypes.number.isRequired,
  setCredits: PropTypes.func.isRequired
};

export default UploadFileModal;
