import React, { useState } from 'react';
import { updateSubmissionDataApi } from '../../api/submissions-database';
import { useSubmissionsContext } from '../../context/submissions';
import PlusIcon from '../icons/PlusIcon';
import { CrossCircledIcon } from '../icons/icons';
import TableInputField from './TableInputField';
import Dropdown from './dropdown/Dropdown';

const MultiEntryField = ({ fieldNames, item, editingField, setEditingField, fieldWidth }) => {
  const [newFieldStr, setNewFieldStr] = useState('');
  const [newField, setNewField] = useState({});

  const { setSubmissionData } = useSubmissionsContext();
  const [currentDropdown, setCurrentDropdown] = useState(null);

  const addSubmissionsFieldValue = async (fieldName) => {
    const updatedValue = [...(item[fieldName] || []), newFieldStr];
    setSubmissionData((prev) =>
      prev.map((data) => {
        if (data._id === item._id) {
          return {
            ...data,
            [fieldName]: [...(data[fieldName] || []), newFieldStr]
          };
        }
        return data;
      })
    );

    setEditingField({});
    setNewField({});
    setNewFieldStr('');
    updateSubmissionDataApi({ data: { _id: item._id, [fieldName]: updatedValue } });
  };

  const updateSubmissionsFieldValue = async (index, newValue, fieldName) => {
    if (newValue === item[fieldName][index]) return;
    if (newValue === '') {
      removeHandler(fieldName, item[fieldName][index]);
      return;
    }
    const updatedValues = [...item[fieldName]];
    updatedValues[index] = newValue;
    setSubmissionData((prev) =>
      prev.map((submissionData) => {
        if (submissionData._id === item._id) {
          return {
            ...submissionData,
            [fieldName]: updatedValues
          };
        }
        return submissionData;
      })
    );
    const timeoutId = setTimeout(() => {
      updateSubmissionDataApi({ data: { _id: item._id, [fieldName]: updatedValues } });
    }, 100);
    return () => clearTimeout(timeoutId);
  };

  const removeHandler = async (field, value) => {
    const updatedValue = item[field].filter((val) => val !== value);
    setSubmissionData((prev) =>
      prev.map((data) => {
        if (data._id === item._id) {
          return {
            ...data,
            [field]: updatedValue
          };
        }
        return data;
      })
    );
    updateSubmissionDataApi({ data: { _id: item._id, [field]: updatedValue } });
  };

  const onBlurHandler = async (fieldName) => {
    setCurrentDropdown(null);
    if (newFieldStr?.length === 0) {
      setEditingField({});
      setNewField({});
      return;
    }
    if (fieldName) {
      addSubmissionsFieldValue(fieldName);
    }
  };

  const keyDownHandler = async (e, fieldName, isNewInputField, index) => {
    if (e.key === 'Enter') {
      if (!isNewInputField) {
        e.preventDefault();
        const input = document.getElementById(`input-${fieldName}-${item._id}-${index}`);
        if (input) {
          input.blur();
        }
        return;
      }
      if (newFieldStr?.length === 0) return;
      addSubmissionsFieldValue(fieldName);
    }
    if (e.key === 'Escape' || (e.key === 'Backspace' && e.target.value === '')) {
      setNewFieldStr('');
      setEditingField({});
      setNewField({});
    }
  };

  const addFieldClickHandler = async (field) => {
    // Checking if the field is already being edited and if its value is not empty then adding the value and creating a new input field
    if (
      editingField.field === field &&
      editingField.itemId === item._id &&
      newFieldStr.length !== 0
    ) {
      setTimeout(() => {
        setEditingField({
          field,
          itemId: item._id
        });
      }, 100);

      setTimeout(() => {
        const input = document.getElementById(`input-${field}-${item._id}`);
        if (input) {
          input.focus();
        }
      }, 150);

      setTimeout(() => {
        setNewField({
          field,
          itemId: item._id
        });
      }, 100);

      setNewFieldStr('');

      setEditingField({});
      addSubmissionsFieldValue(field);
      return;
    } else {
      setNewField({
        field,
        itemId: item._id
      });
      setTimeout(() => {
        const input = document.getElementById(`input-${field}-${item._id}`);
        if (input) {
          input.focus();
        }
      }, 100);
    }
  };

  const dropdownChangeHandler = async (newFieldName, oldFieldName, value) => {
    const newFieldNameLowered = newFieldName.toLowerCase();
    const oldFieldNameLowered = oldFieldName.toLowerCase();

    if (newFieldNameLowered === oldFieldNameLowered) return;
    const updatedOldFieldValues = Array.isArray(item[oldFieldNameLowered])
      ? item[oldFieldNameLowered].filter((val) => !val.includes(value))
      : [];

    const updatedNewFieldValues = Array.isArray(item[newFieldNameLowered])
      ? [...item[newFieldNameLowered], value]
      : [value];

    setSubmissionData((prev) =>
      prev.map((submissionData) => {
        if (submissionData._id === item._id) {
          return {
            ...submissionData,
            [oldFieldNameLowered]: updatedOldFieldValues,
            [newFieldNameLowered]: updatedNewFieldValues
          };
        }
        return submissionData;
      })
    );

    await updateSubmissionDataApi({
      data: {
        _id: item._id,
        [oldFieldNameLowered]: updatedOldFieldValues,
        [newFieldNameLowered]: updatedNewFieldValues
      }
    });
  };

  const inputBoxClickHandler = (fieldName, fieldIndex, inputBoxId) => {
    const valueInput = document.getElementById(inputBoxId);
    if (valueInput) {
      setTimeout(() => {
        valueInput?.focus();
      }, 10);
    }
    setEditingField({ itemId: item?._id, field: fieldName, index: fieldIndex });
  };

  const RenderInputField = (field) => {
    if (newField?.field === field && newField?.itemId === item._id) {
      return (
        <TableInputField
          value={newFieldStr}
          onChange={(value) => setNewFieldStr(value)}
          onKeyDown={(e) => keyDownHandler(e, field, true)}
          onBlur={() => onBlurHandler(field)}
          editingField={newField}
          className="text-xs"
          id={`input-${field}-${item._id}`}
        />
      );
    }
  };

  const addButton = (
    <button
      type="button"
      className="p-2 bg-[#292A35] border border-[#393A4B] shadow-[0px_1px_1px_#00000026] rounded-md h-fit"
      title={`Add Writer/Director/Producer`}
      onClick={() => addFieldClickHandler('producers')}
      onMouseDown={(e) => e.preventDefault()}>
      <PlusIcon className={'w-2 h-2'} />
    </button>
  );

  const isLastItem = (currentField, currentIndex) => {
    for (let i = fieldNames.length - 1; i >= 0; i--) {
      const fieldName = fieldNames[i];
      if (item[fieldName]?.length > 0) {
        if (fieldName === currentField && currentIndex === item[fieldName].length - 1) {
          return true;
        }
        break;
      }
    }
    return false;
  };

  const areAllFieldsEmpty = fieldNames.every(
    (fieldName) =>
      !item?.hasOwnProperty(fieldName) ||
      !item[fieldName] ||
      !(item[fieldName]?.length > 0) ||
      item[fieldName][0] === ''
  );

  const returnPadding = (value) => {
    if (value?.length > 68) {
      return 'pr-[1.1rem]';
    } else if (value?.length > 50) {
      return 'pr-3.5';
    } else if (value?.length > 35) {
      return 'pr-3.5';
    } else if (value?.length > 25) {
      return 'pr-3.5';
    } else if (value?.length > 15) {
      return 'pr-2.5';
    } else return 'pr-2';
  };

  return (
    <div
      className={`flex ${!areAllFieldsEmpty ? 'flex-col gap-1' : 'flex-row-reverse justify-end'}`}
      tabIndex={0}
      onBlur={() => setCurrentDropdown(null)}>
      <ul className="flex flex-col gap-1">
        {fieldNames.map((fieldName, index1) => {
          return item[fieldName]?.map((value, index2) => {
            const isBeingEdited =
              editingField?.itemId == item?._id &&
              editingField?.field === fieldName &&
              editingField?.index === index2;
            const displayFieldStyles = {
              maxWidth: fieldWidth && !isBeingEdited ? fieldWidth : 'fit-content',
              textWrap: fieldWidth && !isBeingEdited ? 'wrap' : 'nowrap'
            };
            const inputId = `input-${fieldName}-${item._id}-${index2}`;
            if (item[fieldName][0] !== '') {
              return (
                <li key={item?._id + index2} className="flex gap-1 group">
                  <div
                    tabIndex={0}
                    className={`flex items-start gap-1 py-1 pl-2.5 ${returnPadding(value)} text-xs w-fit bg-[#292A35] border border-[#393A4B] shadow-[0px_1px_1px_#00000026] rounded-md`}
                    style={displayFieldStyles}>
                    <Dropdown
                      item={item}
                      values={['Writer', 'Directors', 'Producers']}
                      defaultValue={fieldName}
                      dropdownClass="!w-40"
                      title="Director/Writer/Producer"
                      label="Director/Writer/Producer"
                      currentDropdown={currentDropdown}
                      setCurrentDropdown={(value) =>
                        setCurrentDropdown({ label: value?.label, index: index2 })
                      }
                      dropdownChangeHandler={(newFieldName) =>
                        dropdownChangeHandler(newFieldName, fieldName, value)
                      }
                      isAuthorDropdown
                      index={index2}
                      inputId={inputId}
                    />
                    <div
                      className="relative flex items-center w-fit"
                      onClick={() => inputBoxClickHandler(fieldName, index2, inputId)}>
                      <span
                        className={`${isBeingEdited && 'invisible overflow-hidden'} cursor-text`}>
                        {value}
                      </span>
                      <input
                        value={value}
                        onChange={(e) => {
                          updateSubmissionsFieldValue(index2, e.target.value, fieldName);
                        }}
                        onBlur={() => {
                          onBlurHandler(fieldName);
                          setEditingField({});
                        }}
                        className={`absolute bg-transparent outline-none overflow-hidden w-[calc(100%+6px)] ${!isBeingEdited && 'invisible overflow-hidden'}`}
                        onKeyDown={(e) => keyDownHandler(e, fieldName, false, index2)}
                        title={`${value}`}
                        id={inputId}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => removeHandler(fieldName, value)}
                      title={`Remove ${value}`}
                      className="ml-auto hidden group-hover:block relative top-[2px]">
                      <CrossCircledIcon className="w-3 h-3" pathClass={'fill-gray-400'} />
                    </button>
                  </div>
                  {isLastItem(fieldName, index2) && addButton}
                </li>
              );
            }
          });
        })}
      </ul>
      {RenderInputField('producers')}
      {areAllFieldsEmpty && addButton}
    </div>
  );
};

export default MultiEntryField;
