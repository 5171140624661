import { ChatIcon } from '../../components/icons/ChatIcon';
import {
  AboutUsIcon,
  BillingIcon,
  BookCoverageIcon,
  CustomTemplateIcon,
  DraftComparisonIcon,
  FaqIcon,
  ModifySubscriptionIcon,
  PoliciesIcon,
  PrivacyPolicyIcon,
  ReferalIcon,
  ScriptCoverageIcon,
  SettingsIcon,
  SubmissionLogIcon,
  TermsOfUseIcon,
  UpdatePaymentInfoIcon
} from '../../components/icons/icons';

// SideBar Items
const sideBarItems = [
  {
    id: 1,
    value: 'Script Coverage',
    linkTo: 'script-coverage',
    icon: (isActive) => (
      <ScriptCoverageIcon
        className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
      />
    )
  },
  {
    id: 2,
    value: 'Book Coverage',
    linkTo: 'book-coverage',
    className: 'book-coverage-btn',
    icon: (isActive) => (
      <BookCoverageIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    )
  },
  {
    id: 77,
    value: (
      <>
        Query{' '}
        <span className="ml-2  p-[1.5px] px-3 bg-[#3F9CF2]  rounded-[9px] shadow-sm">Alpha</span>
      </>
    ),
    linkTo: 'query',
    icon: (isActive) => (
      <ChatIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    )
  },
  {
    id: 3,
    value: <div className="material-log">Material Log</div>,
    linkTo: 'material-log',
    icon: (isActive) => (
      <SubmissionLogIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    )
  },
  // {
  //   id: 8,
  //   title: 'List Builder',
  //   isMultiOption: true,
  //   icon: (isActive) => (
  //     <ListIcon className={`${isActive ? 'stroke-white' : 'group-hover:!text-secondary'}`} />
  //   ),
  //   subItems: [
  //     {
  //       id: 81,
  //       value: <div className="list-generator">List Generator</div>,
  //       icon: (isActive) => (
  //         <ListIcon className={`${isActive ? 'stroke-white' : 'group-hover:!text-secondary'}`} />
  //       ),
  //       linkTo: 'list-builder/list'
  //     },
  //     {
  //       id: 82,
  //       value: <div className="list-generator">Idea Generator</div>,
  //       linkTo: 'list-builder/idea',
  //       icon: (isActive) => (
  //         <SparklesIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
  //       )
  //     }
  //   ]
  // },
  {
    id: 9,
    seperator: true,
    value: 'Draft Comparison',
    linkTo: 'draft-comparison',
    className: 'draft-comparison-btn',
    icon: (isActive) => (
      <DraftComparisonIcon
        className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
      />
    )
  },
  {
    id: 4,
    title: 'Billing',
    isMultiOption: true,
    icon: (isActive) => (
      <BillingIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    ),
    subItems: [
      {
        id: 41,
        value: <div className="change-plan-section">Modify Subscription</div>,
        linkTo: 'billing/balance',
        icon: (isActive) => (
          <ModifySubscriptionIcon
            className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
          />
        )
      },
      {
        id: 42,
        value: 'Update Payment Info',
        linkTo: 'https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk',
        icon: (isActive) => (
          <UpdatePaymentInfoIcon
            className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
          />
        )
      }
    ]
  },
  {
    id: 5,
    title: 'Settings',
    isMultiOption: true,
    icon: (isActive) => (
      <SettingsIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    ),
    subItems: [
      {
        id: 51,
        value: <div className="template-section">Custom Template</div>,
        linkTo: 'custom-template',
        icon: (isActive) => (
          <CustomTemplateIcon
            className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
          />
        )
      }
      // {
      //   id: 52,
      //   value: <div className="data-retention-section">Data Retention</div>,
      //   linkTo: 'data-retention',
      //   icon: (isActive) => (
      //     <NoteIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
      //   )
      // }
    ]
  },
  {
    id: 6,
    value: 'Referral Program',
    linkTo: 'referral-program',
    icon: (isActive) => (
      <ReferalIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    )
  },
  {
    id: 7,
    title: 'Policies',
    isMultiOption: true,
    icon: (isActive) => (
      <PoliciesIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
    ),
    subItems: [
      {
        id: 71,
        value: 'About us',
        linkTo: 'about-us',
        icon: (isActive) => (
          <AboutUsIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
        )
      },
      {
        id: 72,
        value: 'FAQ',
        linkTo: 'faq',
        icon: (isActive) => (
          <FaqIcon className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`} />
        )
      },
      {
        id: 73,
        value: 'Privacy Policy',
        linkTo: 'privacy-policy',
        icon: (isActive) => (
          <PrivacyPolicyIcon
            className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
          />
        )
      },
      {
        id: 74,
        value: 'Terms of Use',
        linkTo: 'terms-of-use',
        icon: (isActive) => (
          <TermsOfUseIcon
            className={`${isActive ? 'fill-white' : 'group-hover:!text-secondary'}`}
          />
        )
      }
    ]
  }
];

export default sideBarItems;
