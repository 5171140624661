import toast from 'react-hot-toast';

export const confirmPayment = async (stripe, navigate, clientSecret, paymentMethodId) => {
  const paymentResponse = await stripe?.confirmPayment({
    clientSecret,
    confirmParams: {
      payment_method: paymentMethodId
    },
    redirect: 'if_required'
  });

  if (paymentResponse?.paymentIntent?.status === 'succeeded') {
    toast.success('Successful');
    window.location.reload();
    return;
  }

  if (paymentResponse?.paymentIntent?.status === 'requires_action') {
    toast.error('Please authenticate your card.');
    setTimeout(() => {
      navigate('/script-coverage');
    }, 2000);

    return;
  }

  if (paymentResponse.error) {
    toast.error(paymentResponse.error.message);

    return;
  }

  setTimeout(() => {
    navigate('/script-coverage');
  }, 5000);
};
