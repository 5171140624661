import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Link, useNavigate } from 'react-router-dom';
import { useSubmissionsContext } from '../../context/submissions';
import { useUserDetialsContext } from '../../context/user-details';
import { ReactComponent as UploadIcon } from '../../static/icons/upload.svg';
import { endTrial } from '../../utils/billing/endUserTrial';
import { extractTotalPageFromFile } from '../../utils/file';
import { returnFormattedDate } from '../../utils/utils';
import { ChevronDownIcon, LeftArrowIcon } from '../icons/icons';
import { SpinnerSmall } from '../spinner/Spinner';
import ActionElement from './ActionElement';
import UploadFileModal from './Modal/UploadFileModal';
import UploadedFiles from './UploadedFiles';

function FileUpload({
  title = ' ',
  subtitle = ' ',
  isScript = false,
  setUploadedFile,
  setTokenExceeds,
  setTokenExceedsModal,
  setConfirmationModal,
  reportGeneration,
  setReportGeneration,
  setReportGenerationModal,
  isCustomTemplate = false,
  isMailSent = false,
  uploadInProgress,
  isQuery = false,
  queryScriptHandler
}) {
  const navigate = useNavigate();
  const { userDetails } = useUserDetialsContext();
  const { submissionsData, loading } = useSubmissionsContext();
  const { status, tokenId } = userDetails;

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileIsDragged, setFileIsDragged] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [credits, setCredits] = useState(1);
  const [openDocumentDropdown, setOpenDocumentDropdown] = useState(false);

  const endTrialClickHandler = async () => {
    endTrial(setShowMessage, tokenId, navigate);
  };

  const onChangeHandler = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['pdf', 'doc', 'docx'];
      if (!allowedExtensions.includes(fileExtension)) {
        e.preventDefault();
        return;
      }
    }

    if (isScript) {
      const totalPages = await extractTotalPageFromFile(file);
      if (totalPages > 160) {
        setTokenExceedsModal(true);
        setTokenExceeds(true);
        setReportGeneration(false);
      } else {
        setTokenExceeds(false);
        setTokenExceedsModal(false);
      }
    }
    if (setReportGeneration) {
      setReportGeneration(false);
    }
    if (file) {
      setUploadedFile(file);
      setSelectedFile(file);
    }
  };
  // If user drags and drops a file
  const handleDrop = (e) => {
    e.preventDefault();

    setFileIsDragged(false);
    setReportGeneration(false);
    if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
      const file = e.dataTransfer.items[0].getAsFile();
      setUploadedFile(file);
      setSelectedFile(file);
    }
  };

  const onFileCancel = () => {
    if (setConfirmationModal) {
      setConfirmationModal(false);
    }
    setTimeout(() => {
      if (setReportGeneration) {
        setReportGeneration(false);
      }
      setSelectedFile(undefined);
      fileInputRef.current.value = null;
    }, 100);
  };

  const handleDocumentDropdownClose = () => {
    setOpenDocumentDropdown(false);
  };

  const handleToggleDocumentDropdown = () => {
    setOpenDocumentDropdown((prev) => !prev);
  };

  const nonArchivedSubmissions = submissionsData?.filter(
    (item) => !item.isArchived && item?.text_id
  );
  const slicedSubmissionsData = nonArchivedSubmissions.slice(
    0,
    Math.min(nonArchivedSubmissions?.length, 4)
  );
  const createSubmissionChat = async (submission) => {
    setSelectedFile(submission);
    setUploadedFile(submission);
    handleDocumentDropdownClose();
  };

  const returnSubmissionsList = () => {
    if (loading) {
      return (
        <li className="flex items-center justify-center h-[8rem]">
          <SpinnerSmall secondPathColor="#FE6629" className="!h-5 !w-5" />
        </li>
      );
    }
    return slicedSubmissionsData?.map((submission) => {
      return (
        <li
          className="text-[#EEEFFC] font-normal text-xs pb-1.5 pt-2 pl-3 pr-1 hover:text-primary cursor-pointer mb-0.5"
          onClick={() => createSubmissionChat(submission)}>
          {submission?.title} ({returnFormattedDate(submission?.created_at)})
        </li>
      );
    });
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: 'linear-gradient(rgba(254, 102, 41, 0.05), rgba(254, 102, 41, 0.05)), #101828',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: 0,
      marginTop: '4px !important',
      width: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #475467',
      boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)'
    }
  }));

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  return (
    <>
      <div className="flex flex-col gap-5 md:gap-7">
        {/* Below span element is to show the first dialogue of the product roadmap */}
        <span className="first-step" />
        <div className="flex flex-col gap-2 md:gap-3">
          <h3 className="text-lg md:text-xl font-filsonPro-bold">{title}</h3>
          <p className="text-gray-400 font-semibold">{subtitle}</p>
        </div>
        <div
          className={`flex items-center justify-center border ${!fileIsDragged ? 'border-[rgba(255,255,255,0.1)] border-dashed' : 'border-primary border-solid'} bg-[linear-gradient(180deg,rgba(254,102,41,0.048)0%,rgba(254,102,41,0.12)100%)] rounded-[10px]`}
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            setFileIsDragged(true);
          }}
          onDragLeave={() => setFileIsDragged(false)}>
          <div className="flex flex-col items-center justify-center gap-2 my-4 md:my-6 lg:my-8">
            <UploadIcon className="h-14 md:h-full" />
            <h4 className="font-filsonPro-bold">
              Drag and Drop Your
              {isCustomTemplate ? ' Custom Template' : ' Files'} Here
            </h4>
            <span className="text-gray-400">Limit 200MB Per File (PDF, DOCX, or DOC)</span>
            <div className="flex items-center gap-4 mt-2">
              <label
                htmlFor="selectFile"
                type="button"
                className="py-2 px-6 hover:scale-105 font-filsonPro-semibold text-primary hover:text-secondary border border-primary hover:border-secondary transition-all duration-200 rounded-md cursor-pointer upload-section">
                Browse Files
              </label>
              <input
                type="file"
                accept={`.pdf,.doc,.docx`}
                className="hidden"
                id="selectFile"
                ref={fileInputRef}
                onChange={onChangeHandler}
              />
              {isQuery && (
                <Grid item>
                  <ClickAwayListener onClickAway={handleDocumentDropdownClose}>
                    <div>
                      <HtmlTooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        onClose={handleDocumentDropdownClose}
                        open={openDocumentDropdown}
                        placement="bottom-start"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <ul>
                            {returnSubmissionsList()}
                            <li className="text-[#EEEFFC] font-normal text-sm py-1.5 border-t border-[#475467] group cursor-pointer">
                              <Link
                                to="/material-log"
                                className="w-full flex items-center justify-center bg-[#667085] hover:bg-[#4d5464] py-1 px-1 rounded-[4px]">
                                <LeftArrowIcon className="mr-2" />
                                <span>Go To Material Log</span>
                              </Link>
                            </li>
                          </ul>
                        }>
                        <button
                          onClick={handleToggleDocumentDropdown}
                          className="px-4 py-2 text-sm bg-[#292a35] border border-[#383849] rounded-md font-inter">
                          <span>Select Submission</span>
                          <ChevronDownIcon
                            className={`inline-block ml-3 align-middle ${openDocumentDropdown ? 'rotate-180' : 'rotate-0'} transition-all duration-500`}
                          />
                        </button>
                      </HtmlTooltip>
                    </div>
                  </ClickAwayListener>
                </Grid>
              )}
            </div>
          </div>
        </div>
        <UploadedFiles selectedFile={selectedFile} onFileCancel={onFileCancel} />
        <ActionElement
          selectedFile={selectedFile}
          isCustomTemplate={isCustomTemplate}
          isScript={isScript}
          reportGeneration={reportGeneration}
          setReportGenerationModal={setReportGenerationModal}
          setShowMessage={setShowMessage}
          setConfirmationModal={setConfirmationModal}
          uploadInProgress={uploadInProgress}
          isMailSent={isMailSent}
          isQuery={isQuery}
          queryScriptHandler={queryScriptHandler}
        />
      </div>
      <UploadFileModal
        status={status}
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        endTrialClickHandler={endTrialClickHandler}
        isScript={isScript}
        credits={credits}
        setCredits={setCredits}
      />
    </>
  );
}

export default FileUpload;
