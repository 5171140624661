export const exportSubmissions = (submissionsData) => {
  const headers =
    'Title,Writer,Director,Producers,Submitted By,Logline,Note,Read,Response Status,Priority,FullFrame Processing Status';
  const csvRows = [headers]; // Start with the headers

  console.log('submissionsData', submissionsData);
  submissionsData.forEach((submission) => {
    const row = [
      submission.title || '',
      (submission.writer || []).join('; '),
      (submission.directors || []).join('; '),
      (submission.producers || []).join('; '),
      submission.submitted_by || '',
      `"${(submission.logline || '').replace(/"/g, '""')}"`, // Handle quotes in logline
      submission.note || '',
      submission.read ? 'Yes' : 'No',
      submission.response_status ? 'Yes' : 'No',
      submission.priority || '',
      submission.status || ''
    ];
    csvRows.push(row.join(','));
  });

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'submissions.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
