import React from 'react';

const TableInputField = ({
  value,
  onChange,
  onKeyDown = () => {},
  onBlur,
  className = '',
  id = ''
}) => {
  const returnInputWidth = (value) => {
    if (value?.length <= 2) {
      return '5ch';
    } else if (value?.length <= 6) {
      return '8ch';
    } else if (value?.length > 90) {
      return `${value?.length * 0.9}ch`;
    } else if (value?.length > 80) {
      return `${value?.length * 0.92}ch`;
    } else if (value?.length > 52) {
      return `${value?.length * 0.95}ch`;
    } else if (value?.length > 42) {
      return `${value?.length * 0.94}ch`;
    } else if (value?.length > 22) {
      return `${value?.length * 0.985}ch`;
    } else {
      return `${value?.length + 2}ch`;
    }
  };
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`px-2 py-1 bg-[#292A3580] border-[rgba(255,255,255,0.2)] border border-tansparent rounded outline-none ${className}`}
      style={{ width: returnInputWidth(value) }}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      id={id}
    />
  );
};

export default TableInputField;
