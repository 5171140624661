import { useEffect, useRef, useState } from 'react';
import { updateSubmissionDataApi } from '../../../api/submissions-database';
import { useSubmissionsContext } from '../../../context/submissions';
import capitalizeString from '../../../utils/capitalizeString';
import getColorByStatus from '../../../utils/getColorByStatus';
import RenderDropdownSelect from './RenderDropdownSelect';
import RenderListIcon from './RenderListIcon';

const Dropdown = ({
  item,
  index,
  values,
  defaultValue,
  title = '',
  label = '',
  containerClass = '',
  dropdownClass = '',
  isBoolean = false,
  currentDropdown,
  setCurrentDropdown,
  isSubmissionType = false,
  isMultiEntry = false,
  isAuthorDropdown = false,
  dropdownChangeHandler,
  inputId
}) => {
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const { setSubmissionData } = useSubmissionsContext();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isVisible, setIsVisible] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [filteredValues, setFilteredValues] = useState(values);

  const onClickHandler = async (newValue) => {
    let updatedValue = newValue;
    if (isMultiEntry) {
      const index = currentDropdown.index;
      if (index) {
        let filteredValue = selectedValue;
        filteredValue[index] = newValue;
        updatedValue = filteredValue;
      } else {
        updatedValue = [...selectedValue, newValue];
        setSelectedValue(updatedValue);
      }
      setSelectedValue(updatedValue);
    }

    setSubmissionData((prevData) => {
      const updatedData = prevData.map((submission) => {
        if (submission._id === item._id) {
          return { ...submission, [label]: updatedValue };
        }
        return submission;
      });
      return updatedData;
    });

    if (newValue === selectedValue) return;
    // Checking if the selected value is a boolean value
    if (isBoolean) {
      // Checking if the selected value is first index value then sending false to the backend else true
      updatedValue = newValue === values[0] ? false : true;
      const newSelectedValue = newValue;
      setSelectedValue(newSelectedValue);
    } else if (!isMultiEntry) {
      updatedValue = newValue;
      setSelectedValue(updatedValue);
    }

    if (isAuthorDropdown) {
      dropdownChangeHandler(updatedValue);
    } else {
      await updateSubmissionDataApi({ data: { _id: item._id, [label]: updatedValue } });
    }
    setIsVisible(false);
    setFilterText('');
    setHighlightedIndex(-1);
    setTimeout(() => {
      setCurrentDropdown({ label: '', index: null });
    }, 5);
  };

  const toggleVisibility = (index) => {
    if (!isVisible) {
      setCurrentDropdown({ label, index });
      // Focus on the input field when the dropdown is opened
      setTimeout(() => {
        inputRef.current?.focus();
      }, 60);
    } else {
      setCurrentDropdown({ label: '', index: null });
    }
    setIsVisible((prev) => !prev);
  };

  const onBlurHandler = (event) => {
    const multiEntryInput = document.getElementById(inputId);
    if (
      !dropdownRef?.current?.contains(event.relatedTarget) &&
      !multiEntryInput?.contains(event.relatedTarget) &&
      !inputRef?.current?.contains(event.relatedTarget)
    ) {
      setIsVisible(false);
      setFilterText('');
      setHighlightedIndex(-1);
    }
  };

  const handleFilterChange = (event) => {
    const newText = event.target.value;
    setFilterText(newText);
  };

  useEffect(() => {
    const newFilteredValues = values.filter((item) =>
      item.toLowerCase().includes(filterText.toLowerCase())
    );
    const matchIndex = newFilteredValues.findIndex((item) =>
      item.toLowerCase().startsWith(filterText.toLowerCase())
    );
    if (filterText === '' || matchIndex === -1) {
      setHighlightedIndex(-1);
    } else {
      setHighlightedIndex(matchIndex);
    }
    setFilteredValues(newFilteredValues);
  }, [filterText, values]);

  const handleKeyDown = async (event) => {
    if (event.key === 'Escape') {
      // Closing the dropdown on ESC
      setIsVisible(false);
      setFilterText('');
      setHighlightedIndex(-1);
    } else if (event.key === 'Enter' && highlightedIndex >= 0) {
      // Setting the selected value from the highlighted index on ENTER
      const selectedItem = filteredValues[highlightedIndex];
      if (selectedItem && currentDropdown === label) {
        setSelectedValue(selectedItem);
        setIsVisible(false);
        setFilterText('');
        setHighlightedIndex(-1);
        if (selectedItem === selectedValue) return;
        let updatedValue = '';
        // Checking if the dropdown is for boolean values
        if (isBoolean) {
          // Checking if the selected value is first index value then sending false to the backend else true
          updatedValue = selectedValue === values[0] ? false : true;
        } else {
          updatedValue = selectedItem;
        }
        await updateSubmissionDataApi({ data: { _id: item._id, [label]: updatedValue } });
      }
    } else if (event.key === 'ArrowDown') {
      setHighlightedIndex((prev) => (prev + 1) % filteredValues.length);
    } else if (event.key === 'ArrowUp') {
      setHighlightedIndex((prev) => (prev - 1 + filteredValues.length) % filteredValues.length);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [filteredValues, highlightedIndex]);

  let selectedItemColorStyle = '';
  if (!isMultiEntry) {
    selectedItemColorStyle = getColorByStatus(selectedValue);
  }

  const returnAbsolutePosition = () => {
    if (isMultiEntry) {
      return 'top-[3rem]';
    } else if (label === 'priority') {
      // Checking if there are two words in the priority dropdown to show the dropdown on top
      if (selectedValue.split(' ').length > 1) {
        return 'top-[2.5rem]';
      } else {
        return 'top-[1.4rem]';
      }
    } else {
      return 'top-[1.4rem]';
    }
  };

  return (
    <div
      className={`relative ${containerClass} min-w-fit`}
      onBlur={onBlurHandler}
      tabIndex={0}
      ref={dropdownRef}>
      <RenderDropdownSelect
        item={item}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        isVisible={isVisible}
        toggleVisibility={toggleVisibility}
        isMultiEntry={isMultiEntry}
        isSubmissionType={isSubmissionType}
        label={label}
        selectedItemColorStyle={selectedItemColorStyle}
        isAuthorDropdown={isAuthorDropdown}
      />
      {/* Checking if the index is in the middle of the dropdown to show the dropdown on top */}
      <ul
        className={`absolute ${returnAbsolutePosition()}
          w-full rounded-md text-[13px] text-left pb-1 bg-[rgba(49,50,62,1)] border border-[rgba(255,255,255,0.1)] text-white ${isVisible ? 'block' : 'hidden'} shadow-[0px_4px_24px_rgba(0,0,0,0.2)] ${dropdownClass} z-[30]`}>
        <li>
          <input
            type="text"
            value={filterText}
            onChange={handleFilterChange}
            ref={inputRef}
            className="w-full bg-transparent text-white px-2 py-1 pt-2 mb-1 border-b border-[rgba(255,255,255,0.1)] outline-none"
            placeholder={`${title}${title !== 'Director/Writer/Producer' ? '...' : ''}`}
          />
        </li>
        {filteredValues.map((value, index) => {
          // Checking if the value is multi entry and if the value is already selected then not showing it in the dropdown
          if (isMultiEntry && selectedValue?.includes(value)) return null;
          const itemColorStyle = getColorByStatus(value);
          const isHighlighted = index === highlightedIndex && currentDropdown?.label === label;
          return (
            <li
              key={value}
              onClick={() => onClickHandler(value)}
              onMouseOver={() => setHighlightedIndex(index)}
              className={`${isHighlighted ? 'text-white bg-[#505257]' : 'hover:text-white hover:bg-[#505257] text-gray-300 bg-transparent'} ${(isSubmissionType || isMultiEntry || isAuthorDropdown) && 'flex gap-2 items-center'} cursor-pointer px-2 py-1 rounded-sm mx-0.5 z-30`}>
              <RenderListIcon
                value={value}
                itemColorStyle={itemColorStyle}
                isSubmissionType={isSubmissionType}
                isMultiEntry={isMultiEntry}
                isAuthorDropdown={isAuthorDropdown}
              />
              {reuturnListValue(label, value)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;

function reuturnListValue(label, selectedValue) {
  if (label === 'Director/Writer/Producer') {
    if (selectedValue.charAt(selectedValue.length - 1) === 's') {
      return selectedValue.slice(0, -1);
    } else {
      return selectedValue;
    }
  } else if (label === 'priority') {
    return (
      <ul className="inline-flex flex-col">
        {selectedValue.split(' ').map((word, index) => {
          return (
            <li key={index} className="whitespace">
              {word}
              <br />
            </li>
          );
        })}
      </ul>
    );
  } else if (label === 'type') {
    return capitalizeString(selectedValue);
  } else {
    return selectedValue;
  }
}
