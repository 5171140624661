const getAuthToken = () => {
  let tokenValue;

  // Check for id_token in the query parameters
  const params = new URLSearchParams(window.location.search);
  const idToken = params.get('id_token');

  if (idToken) {
    // If id_token exists in the query params, set/update the cookie
    tokenValue = idToken;
    document.cookie = `token=${tokenValue}; path=/; Secure; SameSite=Strict`;

    // Remove the id_token from the URL
    params.delete('id_token');
    const newUrl = window.location.pathname + '?' + params.toString();
    window.history.replaceState(null, '', newUrl.endsWith('?') ? newUrl.slice(0, -1) : newUrl);
  } else {
    // If no id_token in query params, use the token from the cookie
    const cookies = document?.cookie?.split(';');
    const tokenCookie = cookies?.find((cookie) => cookie.trim().startsWith('token='));
    tokenValue = tokenCookie?.split('=')[1].trim();
  }

  return tokenValue || false;
};

export default getAuthToken;
