import { ReactComponent as ScriptCoverageIcon } from '../icons/script-coverage.svg';
import { ReactComponent as BookCoverageIcon } from '../icons/book-coverage.svg';
import { ReactComponent as UsersIcon } from '../icons/users.svg';
import { ReactComponent as Fingerprint } from '../icons/fingerprint.svg';
import { ReactComponent as Search } from '../icons/search.svg';
import { ReactComponent as Notebook } from '../icons/notebook.svg';

import { ReactComponent as PrivacyCheck } from '../icons/privacy-check.svg';
import { ReactComponent as Security } from '../icons/security.svg';
import { ReactComponent as Azure } from '../icons/azure.svg';
import { ReactComponent as MaterialLogIcon } from '../icons/material-log.svg';
import { ReactComponent as FeatureCompsIcon } from '../icons/feature-comps.svg';

export const FEATURES_DATA = [
  {
    Description:
      'Drop in a .pdf and get a coverage template in minutes, complete with headers, logline, synopsis, and character breakdown.',
    Name: 'Script Coverage',
    Icon: ScriptCoverageIcon
  },
  {
    Description:
      'Like our script coverage feature, drop in a book in .pdf format and get coverage in minutes, complete with headers, logline, and synopsis.',
    Name: 'Book Coverage',
    Icon: BookCoverageIcon
  },
  {
    Description:
      'With your coverage report, receive a character breakdown in just minutes to aid in casting.',
    Name: 'Character Breakdown',
    Icon: UsersIcon
  },
  {
    Description: 'Have confidence in your privacy on our platform with enterprise level security.',
    Name: 'Enterprise-Grade Security',
    Icon: Fingerprint
  },
  {
    Description:
      'Instantly extract the key info into an organized reading log, eliminating manual entry.',
    Name: 'Automated Material Log',
    Icon: MaterialLogIcon
  },
  {
    Description:
      'Automatically generate a list of comparable films to help you pitch your project.',
    Name: 'Comparisons',
    Icon: FeatureCompsIcon
  },
  {
    Description: "Use your company's templates to create reports.",
    Name: 'Custom Templates',
    Icon: Notebook
  },
  {
    Description:
      'Work with submissions in multiple languages including English, Spanish, Japanese, Hindi, French, Italian, and many more.',
    Name: 'Multilingual Support',
    Icon: Search
  }
];

export const ENTERPRISE_DATA = [
  {
    Description:
      'By default, customer data is siloed and never shared with other FullFrame customers or used to train AI models.',

    Icon: PrivacyCheck
  },
  {
    Description:
      'All data on the FullFrame platform is encrypted at rest with AES-256 and in transit via TLS.',

    Icon: Security
  },
  {
    Description:
      'Built on Microsoft Azure Platform, the industry leader for secure data infrastructure.',
    Icon: Azure
  }
];
