import { useState } from 'react';
import toast from 'react-hot-toast';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { downloadSubmissionDataApi, updateSubmissionDataApi } from '../../api/submissions-database';
import { useUserDetialsContext } from '../../context/user-details';
import { createChatBySubmission } from '../../utils/chat';
import downloadDocument from '../../utils/downloadDocument';
import redirectToLogin from '../../utils/token';
import { ChatIcon } from '../icons/ChatIcon';
import { ArchiveIcon, DeleteIcon, DownloadIcon, UnarchiveIcon } from '../icons/icons';
import EmbeddingModal from '../query/EmbeddingModal';

function RenderActionIcons({ item, setSubmissionData, isArchiveTable, setConfirmationModal }) {
  const navigate = useNavigate();
  const itemStatus = item?.status?.toLowerCase();
  const [embeddingModal, setEmbeddingModal] = useState(false);
  const {
    userDetails: { status, show_chat }
  } = useUserDetialsContext();

  const updatedSubmission = async (field, value) => {
    setSubmissionData((prevData) => {
      const updatedData = prevData.map((submission) => {
        if (submission._id === item._id) {
          return { ...submission, [field]: value };
        }
        return submission;
      });
      return updatedData;
    });
    updateSubmissionDataApi({ data: { _id: item._id, [field]: value } });
  };

  const downloadSubmission = async () => {
    const response = await downloadSubmissionDataApi({ submissionId: item._id });
    const { filename } = response.headers;
    downloadDocument(response.data, filename);
  };

  const createSubmissionChat = async () => {
    try {
      setEmbeddingModal(true);
      const response = await createChatBySubmission(item?._id);
      const chatId = response?.data?.data?.chat_id;
      navigate({
        pathname: '/query',
        search: createSearchParams({
          chat_id: chatId
        }).toString()
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again to continue.');
        setTimeout(() => {
          redirectToLogin();
        }, 2000);
      } else {
        toast.error('Error in createSubmissionChat:', error);
      }
    } finally {
      setEmbeddingModal(false);
    }
  };

  const chatIcon = () => (
    <ChatIcon
      className="hover:stroke-primary transition-all duration-300"
      onClick={createSubmissionChat}
      disabled={item?.status?.toLowerCase() !== 'completed'}
    />
  );

  const downloadIcon = () => (
    <DownloadIcon
      className="hover:stroke-primary transition-all duration-300"
      onClick={downloadSubmission}
      disabled={item?.status?.toLowerCase() !== 'completed'}
    />
  );

  const deleteIcon = () => (
    <DeleteIcon
      onClick={() => setConfirmationModal(true)}
      className="hover:stroke-primary transition-all duration-300"
    />
  );

  if (itemStatus?.toLowerCase() === 'processing') {
    return '';
  } else if (itemStatus?.toLowerCase() === 'completed') {
    if (!isArchiveTable) {
      return (
        <div className="flex items-center justify-end gap-2 w-full">
          {show_chat !== false && status !== 'not_subscribed' && item?.text_id && chatIcon()}
          {downloadIcon()}
          <button
            type="button"
            onClick={() => {
              updatedSubmission('isArchived', true);
            }}
            className="group"
            title="Archive">
            <ArchiveIcon className="hover:text-primary" />
          </button>
          {deleteIcon()}
          <EmbeddingModal
            reportGenerationModal={embeddingModal}
            setReportGenerationModal={setEmbeddingModal}
            selectedFile={{ name: item.title }}
          />
        </div>
      );
    }
    return (
      <div className="flex items-center justify-end gap-2 w-full">
        {downloadIcon()}
        <UnarchiveIcon
          onClick={() => {
            updatedSubmission('isArchived', false);
          }}
        />
        {deleteIcon()}
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-end w-full">
        <button
          type="button"
          onClick={() => setConfirmationModal(true)}
          title="Delete"
          className="float-right">
          <DeleteIcon className="hover:stroke-primary transition-all duration-300" />
        </button>
      </div>
    );
  }
}

export default RenderActionIcons;
