import toast from 'react-hot-toast';
import { useUserDetialsContext } from '../../context/user-details';

import Button from '../UI/Button';
import CheckIcon from '../icons/CheckIcon';
import Spinner from '../icons/Spinner';

const ActionElement = ({
  selectedFile,
  isCustomTemplate,
  uploadInProgress,
  isMailSent,
  isScript,
  reportGeneration,
  setConfirmationModal,
  setShowMessage,
  setReportGenerationModal,
  isQuery,
  queryScriptHandler
}) => {
  const { userDetails } = useUserDetialsContext();
  const {
    status,
    book_tokens,
    script_tokens,
    extra_script_tokens,
    extra_book_tokens,
    custom_book_template,
    custom_script_template
  } = userDetails;

  const isInProgress = custom_book_template === false && custom_script_template === false;
  const isTemplateUploaded = custom_book_template === true || custom_script_template === true;

  // When user clicks on Generate Coverate/Submit Template button
  const actionButtonClickHandler = () => {
    if (!selectedFile) {
      toast.error('Please select a file first.');
      return;
    }

    if (isQuery) {
      queryScriptHandler();
      return;
    }

    if (isCustomTemplate) {
      setConfirmationModal(true);
      return;
    }

    if (isScript && script_tokens < 1 && extra_script_tokens < 1) {
      setShowMessage(true);
      return;
    }

    if (!isScript && book_tokens < 1 && extra_book_tokens < 1) {
      setShowMessage(true);
      return;
    }

    if (reportGeneration) {
      setReportGenerationModal(true);
    } else {
      setConfirmationModal(true);
    }
  };

  const returnTemplateUploadStatus = () => {
    if (isMailSent || isInProgress) {
      return (
        <div className="flex items-center gap-1 px-2.5 py-1.5 border border-primary font-bold rounded-[4px]">
          <Spinner />
          <span className="text-xl">Upload in progress</span>
        </div>
      );
    }
    if (!isMailSent && isTemplateUploaded) {
      return (
        <div className="flex items-center gap-1 px-2.5 py-1.5 border border-primary font-bold rounded-[4px]">
          <CheckIcon />
          <span className="text-xl">Upload Complete</span>
        </div>
      );
    }
  };

  if (isCustomTemplate && status) {
    return (
      <div className="flex gap-2 sm:gap-4 items-center justify-center">
        <Button
          disabled={uploadInProgress || !selectedFile}
          onClick={actionButtonClickHandler}
          className="px-[0.7rem] !w-fit sm:px-4 py-[0.38rem] font-filsonPro-bold coverage-button">
          {isMailSent || isTemplateUploaded || isInProgress ? 'Resubmit' : 'Submit'} Custom Template
        </Button>
        {returnTemplateUploadStatus()}
      </div>
    );
  }
  if (status) {
    return (
      <div className="flex gap-2 sm:gap-4 items-center justify-center">
        <Button
          onClick={actionButtonClickHandler}
          className="px-[0.7rem] !w-fit sm:px-4 py-[0.38rem] font-filsonPro-bold coverage-button">
          {isQuery ? 'Start Query' : 'Generate Coverage'}
        </Button>
        <span className="font-filsonPro-semibold">
          <span className="hidden sm:inline">Cost:</span> 1 Credit
        </span>
      </div>
    );
  }
  return (
    <div className="flex gap-2 sm:gap-4 items-center justify-center animate-pulse">
      <div className="py-4 md:py-5 w-40 rounded-md bg-gray-200" />
      <span className="py-3 w-24 bg-gray-200 rounded-md" />
    </div>
  );
};

export default ActionElement;
