import { useEffect } from 'react';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';

/* eslint-disable react/prop-types */
const LimitExceedsModal = ({ tokenExceedsModal = false, setTokenExceedsModal }) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setTokenExceedsModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Modal onClose={() => setTokenExceedsModal(false)} showModal={tokenExceedsModal}>
      <div className="mb-4 flex flex-col gap-2 px-4 sm:px-6 md:pr-8 w-full mt-8">
        <span className="font-bold">Document length exceeded</span>
        <p className="text-gray-700">
          This script is too long for coverage generation. Please submit a script 160 pages or less.
        </p>
      </div>
      <div className="flex justify-center gap-4 mt-12 w-full">
        <Button
          className="flex items-center justify-center gap-2 "
          onClick={() => setTokenExceedsModal(false)}>
          <i className="fas fa-times-square" />
          <span className="">Close</span>
        </Button>
      </div>
    </Modal>
  );
};

export default LimitExceedsModal;
