import { createContext, useContext, useState } from 'react';

export const SidebarContext = createContext({
  openItemsId: [],
  setOpenItemsId: () => {}
});

export const useSidebarContext = () => useContext(SidebarContext);

export function SidebarContextProvider({ children }) {
  const [openItemsId, setOpenItemsId] = useState([4, 5, 6, 7]);

  return (
    <SidebarContext.Provider
      value={{
        openItemsId,
        setOpenItemsId
      }}>
      {children}
    </SidebarContext.Provider>
  );
}
