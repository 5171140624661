import ChurnReasonEnum from '../enums/ChurnReason.enum';
import ReferReasonEnum from '../enums/ReferReason.enum';


export const ChurnReasonOptions = {
  [ChurnReasonEnum.NOT_ENOUGH_VOLUME]: 'Insufficient volume of data',
  [ChurnReasonEnum.REPORT_QUALITY_NEEDS_IMPROVEMENT]: 'Report quality requires enhancement',
  [ChurnReasonEnum.DID_NOT_USE_ENOUGH]: 'Under utilization of service',
  [ChurnReasonEnum.PRICING_TOO_EXPENSIVE]: 'Cost exceeds perceived value',
  [ChurnReasonEnum.NEED_MORE_FEATURES]: 'Requirement for additional features',
  [ChurnReasonEnum.INTEND_TO_USE_DIFFERENT_TOOL]: 'Intention to adopt an alternative solution',
  [ChurnReasonEnum.OTHER]: 'Other'
};

export const referReasonOptions = {
  [ReferReasonEnum.SEARCHING_FOR_SOLUTION]: 'Searching for a Solution',
  [ReferReasonEnum.ADVERTISEMENT]: 'Advertisement',
  [ReferReasonEnum.REFERRED_BY_FRIEND]: 'Referred by a Friend',
  [ReferReasonEnum.OTHER]: 'Other'
};

export const PIPELINE_COMPLETION_TIMEOUT_MAX_MIN = 20