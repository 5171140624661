import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import ActionButton from './ActionButton';
import { Cross } from '../icons/icons';

const Tag = forwardRef(
  (
    {
      children,
      text,
      className,
      deletable,
      onDelete,
      crossClassName,
      crossProps,
      showDeleteOnHover = true, // Set default value to true
      hoverDelay = 200, // Delay in milliseconds
      ...props
    },
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false); // State to manage hover
    const [isHoverWithDelay, setIsHoverWithDelay] = useState(false); // State to manage hover with delay
    const hoverTimeoutRef = useRef(null); // Ref to store the timeout ID

    useEffect(() => {
      if (isHovered) {
        // Set a timeout when mouse enters
        hoverTimeoutRef.current = setTimeout(() => {
          setIsHoverWithDelay(true);
        }, hoverDelay);
      } else {
        // Clear the hover state and timeout when mouse leaves
        clearTimeout(hoverTimeoutRef.current);
        setIsHoverWithDelay(false);
      }

      // Clear timeout on unmount
      return () => clearTimeout(hoverTimeoutRef.current);
    }, [isHovered, hoverDelay]);

    return (
      <ActionButton
        ref={ref}
        className={twMerge(
          'flex text-xs rounded-[4px] hover:opacity-100 cursor-default',
          className
        )}
        {...props}
        onMouseEnter={() => setIsHovered(true)} // Handle hover enter
        onMouseLeave={() => setIsHovered(false)} // Handle hover leave
      >
        {children || text}
        {deletable &&
          (!showDeleteOnHover || isHoverWithDelay) && ( // Conditional rendering with delay
            <Cross
              className={twMerge('cursor-pointer hover:opacity-70 ml-2', crossClassName)}
              onClick={onDelete}
              {...crossProps}
            />
          )}
      </ActionButton>
    );
  }
);

export default Tag;
