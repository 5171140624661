import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';
import EditableText from '../atoms/EditableText';
import Tag from '../atoms/Tag';

const DropdownNew = forwardRef(
  (
    {
      items,
      value,
      onChange,
      onDelete,
      className,
      disableEditing,
      autoFocus,
      searchPlaceholder = 'Search',
      toggleOnTextClick = false
    },
    ref // Accept ref from the parent
  ) => {
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const tagRef = useRef(null);
    const dropdownRef = useRef(null);
    const textRef = useRef(null); // Ref for EditableText

    // Toggle dropdown visibility and position
    const toggleDropdown = () => {
      if (!isOpen) {
        const rect = tagRef.current.getBoundingClientRect();
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX
        });
      }
      setIsOpen(!isOpen);
      setSearch('');
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearch('');
      }
    };

    useEffect(() => {
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);

    useEffect(() => {
      if (value) textRef?.current?.setText(value?.name);
    }, [value]);

    useEffect(() => {
      if (autoFocus === true) textRef?.current?.focus();
    }, [autoFocus]);
    function handleChange(value) {
      onChange?.(value);
      setSearch('');
    }

    // Expose functions to parent component using ref
    useImperativeHandle(ref, () => ({
      setText: (text) => {
        textRef.current?.setText(text);
      },
      getText: () => {
        return textRef.current?.getText();
      },
      focusText: () => {
        textRef.current?.focus();
      },
      toggleEditingText: () => {
        textRef.current?.toggleEditing();
      }
    }));
    const filteredItems = items.filter((i) =>
      search?.length ? new RegExp(search, 'ig').test(i.name) : i
    );

    return (
      <>
        <Tag
          ref={tagRef}
          onDelete={onDelete}
          deletable={!!onDelete}
          className={twMerge('items-center text-[10px] p-1', className)}
          crossClassName="ml-0"
          crossProps={{
            height: '12px'
          }}>
          <span className="text-lg cursor-pointer" onClick={toggleDropdown}>
            {value?.type?.icon}
          </span>
          <EditableText
            onClick={toggleOnTextClick ? toggleDropdown : undefined}
            defaultIsEditing={autoFocus ? true : undefined}
            disableEditing={disableEditing}
            ref={textRef}
            standalone={false}
            className="p-0"
            onChange={(text) => handleChange({ type: value?.type, name: text })}
          />
        </Tag>

        {isOpen &&
          ReactDOM.createPortal(
            <div
              ref={dropdownRef}
              className={twMerge(
                'bg-[rgba(49,50,62,1)] border border-[rgba(255,255,255,0.1)]',
                'absolute rounded-[4px] mt-1 text-xs',
                'z-[9999] text-white',
                'w-fit max-w-52'
              )}
              style={{ top: `${position.top}px`, left: `${position.left}px` }}>
              <ul>
                <li className="px-2 py-1 border-b border-[#52526F40]">
                  <input
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const item = filteredItems?.[0];
                        if (item) {
                          handleChange({ type: item, name: value.name });
                          setIsOpen(false);
                        }
                      }
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoFocus
                    className="bg-transparent outline-none"
                    type="text"
                    placeholder={searchPlaceholder}
                  />
                </li>
                {filteredItems?.map((item, index) => (
                  <li
                    tabIndex={0}
                    key={item.id}
                    className={twMerge(
                      'cursor-pointer px-2 py-1 hover:bg-gray-700 focus:bg-gray-700 outline-none',
                      index === 0 && search.length ? 'bg-gray-700' : null
                    )}
                    onClick={() => {
                      handleChange({ type: item, name: value.name });
                      setIsOpen(false);
                    }}>
                    <span className="inline-flex items-center gap-1">
                      {item.icon} {item.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>,
            document.getElementById('portal')
          )}
      </>
    );
  }
);

export default DropdownNew;
