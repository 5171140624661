/* eslint-disable react/prop-types */
const EnterpriseCard = ({ Description, Icon }) => (
  <div className="flex justify-between p-4 sm:p-7 lg:p-11 mx-auto w-full bg-slate-200 bg-opacity-5 ">
    <div className="flex flex-col gap-4 md:gap-6 xl:gap-8 items-center justify-center text-center">
      <Icon className="w-12 md:w-16" />
      <p className="font-normal">{Description}</p>
    </div>
  </div>
);

export default EnterpriseCard;
