import { Link } from 'react-router-dom';

import Logo from '../../static/images/logo.png';

function Footer() {
  return (
    <div className="relative flex items-center justify-between bg-[linear-gradient(rgba(5,30,20,0.3),rgba(40,10,41,0.1),rgba(50,10,41,0.1))] mt-12 sm:mt-16 md:mt-20 lg:mt-28 py-6 md:py-8 z-30">
      <div className="flex flex-col md:flex-row gap-1 items-center justify-between w-[97%] sm:w-[95%] md:w-[90%] mx-auto">
        <img src={Logo} alt="Fullframe" className="mb-3 md:mb-0h-[70px] cursor-pointer" />
        <div className="flex gap-4 md:gap-6">
          <Link
            to="/contact_us"
            className="cursor-pointer hover:text-primary-dark transition-all duration-100"
          >
            Contact Us
          </Link>
        </div>
        <a
          href="mailto:support@fullfr.me"
          className="hover:text-primary-dark transition-all duration-100"
        >
          support@fullfr.me
        </a>
      </div>
    </div>
  );
}

export default Footer;
