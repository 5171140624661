import React, { useEffect, useMemo, useRef } from 'react';
import DataTable from '../components/DataTable/DataTable';
import Divider from '../components/Divider/Divider';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';
import EditableText from '../components/atoms/EditableText';
import PlusIcon from '../components/icons/PlusIcon';
import DropdownNew from '../components/molecules/DropdownNew';
import { MEDIA_TYPES } from '../constants';
import useListStore from '../store/list-generator';
import ReCycleIcon from '../components/icons/ReCycleIcon';

const IdeaGeneratorMembers = ({ talents }) => {
  const textRef = useRef();
  const [addTalent] = useListStore((store) => [store.addTalent]);
  const title = useListStore((store) => store.dropdown.type?.name);
  const version = useListStore((store) => store.dropdown.version);
  useEffect(() => {
    if (title) textRef?.current?.setText(title);
  }, [title]);
  const COLUMNS = useMemo(() => {
    return [
      {
        accessor: 'avatar',
        Header: 'Headshot',
        Cell({ row: { original }, value }) {
          return (
            <div style={{ height: 40, width: 40 }}>
              <img
                src={value}
                className="rounded-md border border-gray-100 h-full w-full object-cover cursor-pointer"
                alt={original?.name}
              />
            </div>
          );
        }
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell({ row: { original }, value }) {
          return (
            <button className="flex gap-1">
              <div style={{ height: 20, width: 20 }}>
                <svg
                  className="cursor-pointer"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M9.99999 1.66669L12.575 6.88335L18.3333 7.72502L14.1667 11.7834L15.15 17.5167L9.99999 14.8084L4.84999 17.5167L5.83332 11.7834L1.66666 7.72502L7.42499 6.88335L9.99999 1.66669Z"
                    stroke={original.is_favourite ? null : '#98A2B3'}
                    className={original.is_favourite ? 'fill-yellow-500' : null}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <EditableText ref={textRef} />
            </button>
          );
        }
      },
      {
        accessor: 'credits',
        Header: 'Credits',
        minWidth: '17vw',
        Cell({ row: { original } }) {
          return (
            <div className="flex flex-wrap flex-grow-0 gap-1 max-w-sm items-center">
              {original.credits?.map?.((credit, index) => {
                return (
                  <DropdownNew
                    searchPlaceholder="Type.."
                    autoFocus={autoFocus}
                    className={'min-h-[25px] h-auto'}
                    items={MEDIA_TYPES}
                    value={credit}
                    key={index}
                  />
                );
              })}
            </div>
          );
        }
      },
      {
        accessor: 'upcoming_projects',
        Header: 'Next Up Project',
        minWidth: '17vw',
        Cell({ row: { original } }) {
          return (
            <div className="flex flex-wrap flex-grow-0 gap-1 max-w-sm items-center">
              {original.upcoming_projects?.map?.((project, index) => {
                return (
                  <DropdownNew
                    searchPlaceholder="Type.."
                    className={'bg-orange-400 min-h-[25px] h-auto'}
                    autoFocus={autoFocus}
                    items={MEDIA_TYPES}
                    value={project}
                    key={index}
                  />
                );
              })}
            </div>
          );
        }
      },
      {
        accessor: '_id',
        Header: 'Add To Main List',
        Cell({ row: { original }, value }) {
          return (
            <div className="flex justify-center items-center gap-2">
              <div className="flex gap-4">
                <Button disabled={!version}>
                  <PlusIcon />
                  Add
                </Button>
                <Button
                  variant="secondary"
                  className={'rounded-sm bg-gray-800 p-2 hover:opacity-60 border border-black-50'}>
                  Skip
                </Button>
              </div>
            </div>
          );
        }
      }
    ];
  }, [talents?.map((t) => t._id)?.join('-'), version]);

  return (
    <Card className={'min-h-fit !p-0'} resetZIndex>
      <header className="p-4 flex justify-between items-center">
        <EditableText key={title} initialText={title} fullWidth ref={textRef} disableEditing />
        <Button>
          <ReCycleIcon />
          Refresh Ideas
        </Button>
      </header>
      <Divider className="my-0" />
      <section>
        <DataTable data={talents} columns={COLUMNS} />
      </section>
    </Card>
  );
};

export default IdeaGeneratorMembers;
