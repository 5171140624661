import { useState, useEffect } from 'react';

import { toast } from 'react-hot-toast';
import {
  useStripe,
  LinkAuthenticationElement,
  AddressElement,
  PaymentElement,
  useElements
} from '@stripe/react-stripe-js';

import { Link } from 'react-router-dom';
import Button from '../UI/Button';
import { useUserDetialsContext } from '../../context/user-details';
import LogoCroped from '../../static/images/logo-croped.png';
import confirmSetup from '../../utils/billing/confirmSetup';

function SubscriptionCheckout() {
  const stripe = useStripe();
  const elements = useElements();

  const { userDetails } = useUserDetialsContext();
  const { name: storedName, email: storedEmail, tokenId } = userDetails;

  const [name, setName] = useState(storedName || '');
  const [email, setEmail] = useState(storedEmail || '');
  const [address, setAddress] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const subscriptionHandler = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    const paymentIntentToastId = null;

    if (
      name === '' ||
      email === '' ||
      address === '' ||
      address === null ||
      address === undefined ||
      address === 'undefined' ||
      address === 'null'
    ) {
      toast.dismiss(paymentIntentToastId);
      toast.error('Form is incomplete. Please fill all the fields.');
      setIsProcessing(false);
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}create_sub/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenId}`
      },
      body: JSON.stringify({
        name,
        email,
        line1: address?.line1 || null,
        line2: address?.line2 || null,
        city: address?.city || null,
        state: address?.state || null,
        postal_code: address?.postal_code || null,
        country: address?.country || null
      })
    });

    if (response.status === 200) {
      const responseJson = await response.json();
      const { client_secret } = responseJson;
      // eslint-disable-next-line max-len
      const res = await confirmSetup(stripe, elements, client_secret);
      if (res?.status === 200) {
        toast.dismiss(paymentIntentToastId);
      }
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_REDIRECT_URI;
      }, 3000);
      setIsProcessing(false);
    }

    if (response.status === 401) {
      const responseJson = await response.json();
      toast.dismiss(paymentIntentToastId);
      console.log(responseJson.message);
      toast.error(responseJson.message);

      setTimeout(() => {
        window.location.href = process.env.REACT_APP_REDIRECT_URI;
      }, 3000);

      setIsProcessing(false);
      return;
    }

    if (response.status === 400) {
      const responseJson = await response.json();
      toast.dismiss(paymentIntentToastId);
      console.log(responseJson.message);
      toast.error(responseJson.message);
      setIsProcessing(false);
      return;
    }
    setIsProcessing(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    subscriptionHandler();
  };

  useEffect(() => {
    document.title = 'Fullframe | Subscribe';
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', 'Subscribe to Fullframe');
  }, []);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const date = new Date();
  const currentDate = new Date();
  // Adding seven days to the current date
  const newDate = new Date(date);
  newDate.setDate(currentDate.getDate() + 7);

  return (
    <div className="bg-[#fcfcfc] h-screen overflow-y-scroll">
      <div className="grid md:grid-cols-[40%,58%] lg:grid-cols-2 gap-5 p-4 lg:p-8 w-full 2xl:w-10/12 mx-auto 2xl:shadow-sm bg-[#fafafa] text-black h-full">
        <div className="flex flex-col justify-between mt-3 sm:mt-4 md:mt-8 lg:px-[6%] xl:px-[10%]">
          <div className="text-center">
            <h4 className="text-center font-filsonPro-regular text-2xl lg:text-3xl text-[rgb(185,189,194)]">
              Subscribe to Fullframe
            </h4>
            <p className="font-inter font-semibold text-lg sm:text-xl mt-3 lg:mt-5">
              <span className="text-2xl lg:text-3xl font-filsonPro-semibold">$25.00 </span>
              <span className="text-[rgb(185,189,194)] text-sm font-filsonPro-regular">
                per month{' '}
              </span>
            </p>
            <div className="flex flex-col lg:flex-row items-center lg:items-start gap-3 sm:gap-4 mt-6 lg:mt-4 xl:mt-8">
              <div className="h-full">
                <img src={LogoCroped} alt="Fullframe" className="w-12 lg:w-16" />
              </div>
              <div className="flex flex-col gap-4 sm:gap-8 md:gap-12">
                <div className="flex flex-col gap-2 sm:gap-1">
                  <div className="flex justify-between text-base sm:text-lg md:text-base lg:text-lg font-filsonPro-bold">
                    <span>FullFrame Subscription</span>
                    <span>7 days free</span>
                  </div>
                  <span className="font-inter text-gray-300 text-left">
                    Access to our coverage template, up to 10 script reports and 5 book reports.
                  </span>
                  <span className="justify-self-end w-fit self-end font-inter text-gray-300">
                    $25.00/month afterwards{' '}
                  </span>
                </div>
                <div className="flex flex-col gap-3 sm:gap-5">
                  <div className="flex justify-between font-filsonPro-semibold sm:text-lg md:text-base lg:text-lg">
                    <span>Subtotal</span>
                    <span>$0.00</span>
                  </div>
                  <div className="flex justify-between font-filsonPro-semibold sm:text-lg md:text-base lg:text-lg">
                    <span>Total after trial</span>
                    <span>$25.00</span>
                  </div>
                  <div className="flex justify-between font-filsonPro-semibold sm:text-lg md:text-base lg:text-lg">
                    <span>Next billing date</span>
                    <span>
                      {months[newDate.getMonth()]} {newDate.getDate()}, {newDate.getFullYear()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div />
          </div>
          <div className="hidden md:flex gap-2 lg:gap-4 justify-center">
            <p>
              <span className="text-[rgb(163,165,167)] font-semibold">Powered by </span>
              <span className="text-[rgb(97,97,97)] font-bold">Stripe</span>
            </p>
            <span className="text-[rgb(163,165,167)]">|</span>
            <p className="flex gap-1 sm:gap-2 font-filsonPro-book">
              <Link to="/terms-of-use" className="text-[rgb(163,165,167)] cursor-pointer">
                Terms
              </Link>
              <Link to="/privacy" className="text-[rgb(163,165,167)] cursor-pointer">
                Privacy
              </Link>
            </p>
          </div>
        </div>
        <form
          id="payment-form"
          onSubmit={handleSubmit}
          className="flex flex-col gap-6 p-4 w-full sm:w-3/4 mx-auto md:w-full shadow-[-1px_0px_8px_0px_rgba(0,0,0,0.1)]"
        >
          <LinkAuthenticationElement
            onChange={(event) => {
              setEmail(event.value.email);
            }}
            options={{ defaultValues: { email } }}
          />
          <AddressElement
            options={{ mode: 'billing', allowedCountries: ['US'] }}
            onChange={(e) => {
              setAddress(e.value.address);
              setName(e.value.name);
            }}
          />
          <PaymentElement />
          <div className="flex gap-2 sm:gap-4 justify-center mt-3 sm:mt-5 font-semibold">
            <Button
              disabled={!stripe || isProcessing}
              type="submit"
              id="submit"
              className="!w-full"
            >
              Subscribe
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SubscriptionCheckout;
