import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSubmissionsDataApi } from '../api/submissions-database';
import Divider from '../components/Divider/Divider';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';
import Progress from '../components/atoms/Progress';
import { LeftArrowIcon } from '../components/icons/icons';
import ListGeneratorSelectors from '../list-generator/ListGeneratorSelectors';
import { twMerge } from 'tailwind-merge';
import IdeaGeneratorMembers from './IdeaGeneratorMembers';

export const DataCard = ({ label, children, className, contentClassName }) => {
  return (
    <div className={twMerge('w-fit', className)}>
      {label && <label className=" font-semibold text-sm">{label}</label>}
      <div
        className={twMerge(
          'text-white bg-gray-900 px-4 py-2 rounded-lg mt-2 border border-gray-700',
          contentClassName
        )}>
        {children}
      </div>
    </div>
  );
};
const TRAITS = [
  {
    trait: 'Age',
    description: 'Sample description: Character over 30 years old'
  },
  {
    trait: 'Gender',
    description: 'Sample description: Character over 30 years old'
  },
  {
    trait: 'Role Alignment',
    description: 'Sample description: Character over 30 years old'
  },
  {
    trait: 'Project Scale',
    description: 'Sample description: Character over 30 years old'
  },
  {
    trait: 'Body Type',
    description: 'Sample description: Character over 30 years old'
  }
];
const IdeaGeneratorHome = () => {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  useEffect(() => {
    async function getData() {
      const { data, status } = await getSubmissionsDataApi(location);
      if (status === 200) setSubmissions(data?.data);
    }
    getData();
  }, [location]);

  return (
    <div className="flex flex-col gap-6 font-inter">
      <Card className={'min-h-fit flex gap-4'}>
        <Button
          onClick={() => navigate('/list-builder/list')}
          variant="secondary"
          className={'rounded-lg w-fit'}>
          <div className="w-max flex items-center gap-2 h-[40px]">
            <LeftArrowIcon /> Go Back To List
          </div>
        </Button>
        <div className="flex items-center w-full">
          <Progress value={progress} label={'Generation...'} />
        </div>
      </Card>
      <ListGeneratorSelectors submissions={submissions} />

      <Card className={'min-h-fit !p-0'} resetZIndex>
        <header className="p-4 flex justify-between items-center">Idea Generator</header>
        <Divider className="my-0" />
        <section className="p-4">
          <div>
            <DataCard label={'User Intent'} className={'mb-4'}>
              We rounded up the kids' animated shows that transcend all age targets thanks to their
              brilliant writing and amazing visuals. We rounded up the kids' animated shows that
              transcend all age targets thanks
            </DataCard>
            <DataCard label={'Data Sources'} className={'mb-4'} contentClassName="!px-2 !py-2">
              <div className="flex items-center justify-between w-full gap-3">
                FullFrame Knowledge Base{' '}
                <DataCard contentClassName={'!py-2 !px-3 mt-0 bg-gray-800'}>Default</DataCard>
              </div>
            </DataCard>
            <div className="flex gap-x-4 mb-8">
              <div className={twMerge('w-max')}>
                <label className=" font-semibold text-sm">Trait</label>
                <div className="flex flex-col gap-y-2">
                  {TRAITS.map((trait) => {
                    return (
                      <DataCard
                        className={'min-w-max w-full'}
                        contentClassName={
                          'bg-gradient-to-l from-[#E04C11] to-[#FE6629] border-2 w-full text-center'
                        }>
                        {trait.trait}
                      </DataCard>
                    );
                  })}
                </div>
              </div>
              <div className={twMerge('w-full')}>
                <label className=" font-semibold text-sm">Description</label>
                <div className="flex flex-col gap-y-2">
                  {TRAITS.map((trait) => {
                    return (
                      <DataCard className={'w-full'} contentClassName={'w-full'}>
                        {trait.description}
                      </DataCard>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Button onClick={() => {}} className={'h-[40px] rounded-lg'}>
            <div className="w-max">Generate Ideas</div>
          </Button>
        </section>
      </Card>
      <IdeaGeneratorMembers talents={[{}]} />
    </div>
  );
};

export default IdeaGeneratorHome;
