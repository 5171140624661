import ReactMarkdown from 'react-markdown';
import { useUserDetialsContext } from '../../context/user-details';
import LogoCroped from '../../static/images/logo-croped.png';

function MessageItem({
  message = {},
  index,
  editable,
  isEditing,
  setIsEditing,
  handleEditClick,
  handleSaveClick,
  editedText,
  setEditedText,
  inputRef,
  queryInProgress
}) {
  const { userDetails } = useUserDetialsContext();
  const { name } = userDetails;

  const renderMessage = () => {
    // Trim any trailing newlines and spaces from the message text
    const trimmedMessage = message.text.trim().replace(/\n+$/, '');

    if (isEditing && editable) {
      return (
        <input
          type="text"
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          ref={inputRef}
          className="font-normal focus:outline-none bg-transparent border-b text-[1rem] border-b-primary min-w-full sm:min-w-[18rem] md:min-w-[20rem] lg:min-w-[25rem] w-full"
        />
      );
    }

    return (
      <ReactMarkdown
        className="react-markdown-container prose w-full break-words"
        components={{
          p: ({ node, ...props }) => <p className="custom-paragraph" {...props} />
        }}>
        {trimmedMessage}
      </ReactMarkdown>
    );
  };
  const renderProfile = () => {
    if (message.botMessage) {
      return (
        <img
          src={LogoCroped}
          className="mt-0.5 w-6 sm:w-8 h-6 sm:h-8 self-start object-cover"
          alt="bot"
        />
      );
    }
    return (
      <div className="flex items-center justify-center gap-[0.09rem] h-10 w-10 bg-[rgba(217,217,217,0.10)] text-[0.9rem] text-primary rounded-full">
        <span>{name?.split(' ')[0]?.substring(0, 1)?.toUpperCase()}</span>
        <span>{name?.split(' ')[1]?.substring(0, 1)?.toUpperCase()}</span>
      </div>
    );
  };

  const iconClickHandler = (clickIndex, messageText = '') => {
    if (isEditing) {
      handleSaveClick(clickIndex);
    } else {
      handleEditClick(messageText);
    }
  };

  const onBlurHandler = (e) => {
    if (editable) {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setIsEditing(false);
      }
    }
  };

  return (
    <li className="flex gap-2 pr-8 w-fit">
      {renderProfile()}
      <div
        className="flex justify-between items-start w-fit p-3 lg:pr-4 bg-[rgba(217,217,217,0.10)] rounded-2xl min-w-[13rem] sm:min-w-[15rem] lg:min-w-[17rem] group"
        onBlur={onBlurHandler}>
        <div className={`flex flex-col ${isEditing ? 'gap-1' : 'gap-0.5'} w-full`}>
          {renderMessage()}
        </div>
        {editable && (
          <button
            type="button"
            className="flex items-center justify-center ml-2 transition-all duration-200"
            onClick={() => iconClickHandler(index, message.text)}>
            {isEditing ? (
              <i
                className={`fas fa-check text-[#] text-sm ${queryInProgress ? 'bg-primary-light' : 'bg-primary'} hover:bg-primary-dark rounded-full py-0.5 px-1.5`}
              />
            ) : (
              <i className="fa fa-pencil text-[#acbedc] hover:text-white p-0.5 text-sm invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200" />
            )}
          </button>
        )}
      </div>
    </li>
  );
}

export default MessageItem;
