import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon } from '../../components/icons/icons';
import { SpinnerSmall } from '../../components/spinner/Spinner';
import { groupChatsByDate } from '../../utils/chat';
import RenderSessionItem from './RenderSessionItem';

const ChatListing = ({
  closeHistoryHandler = () => {},
  chatListing = [],
  chatListingLoading = false
}) => {
  if (chatListingLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <SpinnerSmall secondPathColor="#FE6629" />
      </div>
    );
  }

  const { today, yesterday, previous7Days } = groupChatsByDate(chatListing);
  return (
    <div className="flex items-start h-full overflow-auto overflow-x-hidden text-ellipsis p-4 w-full">
      <div className="w-full font-inter font-light">
        <RenderSessionItem title="Today" items={today} />
        <RenderSessionItem
          title="Yesterday"
          items={yesterday}
          containerClassName={`${today?.length > 0 && 'mt-8'}`}
        />
        <RenderSessionItem
          title="Previous 7 Days"
          items={previous7Days}
          containerClassName={`${(yesterday?.length > 0 || today?.length > 0) && 'mt-8'}`}
        />
      </div>

      <button
        type="button"
        onClick={closeHistoryHandler}
        className="sticky top-0 right-1 flex items-center justify-between hover:text-primary transition-all duration-300">
        <ChevronLeftIcon className="inline-block align-bottom text-current m-0.5" />
      </button>
    </div>
  );
};

export default ChatListing;
