export const exportLists = (data) => {
  const headers = [
    'Section ID',
    'Section Title',
    'Talent ID',
    'Name',
    'Notes',
    'Availability',
    'Credits',
    'Upcoming Projects',
    'Company Name',
    'Submitted By',
    'Is Favourite',
    'Status',
    'Created At',
    'Updated At'
  ];

  const csvRows = [headers.join(',')];

  data.forEach((section) => {
    section.talents.forEach((talent) => {
      const credits = talent.credits.map((credit) => credit.name).join('; ');
      const upcomingProjects = talent.upcoming_projects.map((project) => project.value).join('; ');

      const row = [
        section.id || '',
        section.title || '',
        talent._id || '',
        talent.name || '',
        talent.notes || '',
        talent.availability || '',
        `"${credits.replace(/"/g, '""')}"`, // Handle quotes in credits
        `"${upcomingProjects.replace(/"/g, '""')}"`, // Handle quotes in upcoming projects
        talent.company_name || '',
        talent.submitted_by || '',
        talent.is_favourite ? 'Yes' : 'No',
        talent.status?.name || '',
        talent.created_at || '',
        talent.updated_at || ''
      ];

      csvRows.push(row.join(','));
    });
  });

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'talents.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
