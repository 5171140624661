import { STATUS_COLORS } from "../constants";

export default function getColorByStatus(status) {
    if (!status) return STATUS_COLORS.info;
  const statusKey = status?.replace(/\s+/g, '')?.toLowerCase();
  
  switch (statusKey) {
    case 'projectdrafts':
        return STATUS_COLORS.purple
    case 'availablematerial':
        return STATUS_COLORS.blue
    case 'noresponse':
    case 'unread':
        return STATUS_COLORS.danger;
    case 'responded':
    case 'read':
        return STATUS_COLORS.sucess;
    case 'projectsamples':
        return STATUS_COLORS.warning;
    case 'general':
        return STATUS_COLORS.info;
    default:
        return STATUS_COLORS.info;
  }
}
