import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const PaymentComplete = () => {
  const [secondsUntilRedirect, setSecondsUntilRedirect] = useState(5);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const plan = queryParams.get('plan');

    try {
      ReactGA.event({
        category: 'Conversion',
        action: `Stripe CC info complete - ${plan}`,
        label: `Stripe Conversion - ${plan}`
      });
    } catch (err) {
      // we can eventually swap this out with something in another analytics tool that tracks errors
      console.error('Error sending GA event', err);
    }

    const intervalId = setInterval(() => {
      setSecondsUntilRedirect((seconds) => {
        if (seconds === 1) {
          window.location.href = '/script-coverage'; // Redirects directly to the root page
        }
        return seconds - 1; // Decrease seconds until redirect
      });
    }, 1000);

    return () => clearInterval(interval()); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="h-screen w-screen bg-black flex justify-center items-center flex-col text-white">
      <h1 className="text-2xl font-bold mb-2">Payment Complete</h1>
      <p className="text-lg">Redirecting back to FullFrame in {secondsUntilRedirect} seconds</p>
    </div>
  );
};

export default PaymentComplete;
