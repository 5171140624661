import { useEffect, useState } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Button from '../components/UI/Button';

import ConfirmationModal from '../components/billing/modal/ConfirmationModal';
import MessageModal from '../components/coverage/Modal/MessageModal';
import { useTemplatesContext } from '../context/templates';
import { useUserDetialsContext } from '../context/user-details';

import CardInputModal from '../components/billing/modal/CardInputModal';
import StripePricingTable from '../components/billing/pricingTable';
import ChurnModal from '../components/coverage/Modal/ChurnModal';
import Card from '../components/UI/Card';
import { cancelSubscription } from '../utils/billing/cancelSubscription';
import { confirmPayment } from '../utils/billing/confirmPayment';
import { endTrial } from '../utils/billing/endUserTrial';
import { resumSubscription } from '../utils/billing/resumeSubscription';

const Balance = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [showMessage, setShowMessage] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [showChurnModal, setShowChurnModal] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const { userDetails } = useUserDetialsContext();
  const { tokenId, status, action, canceled, canceled_at, start_date, current_period_end } =
    userDetails;
  const { templates, onAddQuantity, onSubtractQuantity, updateSelectedTemplate } =
    useTemplatesContext();

  const buyCreditsClickHandler = (template) => {
    if (status === 'past_due' || status === 'incomplete') {
      if (action === 'requires_action') {
        setConfirmationModal(true);
      }
      if (action === 'requires_payment_method') {
        setShowMessage(true);
      }
      return;
    }

    if (status === 'trialing') {
      setShowMessage(true);
      return;
    }

    if (status === 'canceled' || status === 'not_subscribed' || canceled) {
      toast.error('Your subscription is canceled. Please subscribe to buy extra tokens.');
      return;
    }

    updateSelectedTemplate(template);

    navigate(
      `/billing/checkout/${template.product}?credit${template.quantity > 1 ? 's' : ''}=${template.quantity}`
    );
  };

  const endTrialClickHandler = async () => {
    endTrial(setShowMessage, tokenId, navigate);
  };

  const cancelSubscriptionHandler = async () => {
    await cancelSubscription(navigate, tokenId);
  };

  const suretyClickHandler = (choice) => {
    const isYes = choice === 'yes';
    setConfirmationModal(false);
    if (isYes) {
      if (cancelClicked) {
        setShowChurnModal(true);
        setCancelClicked(false);
      } else {
        confirmPayment(tokenId, navigate);
      }
    }
  };

  const onCancelSubscriptionClick = async () => {
    setCancelClicked(true);
    setTimeout(() => {
      setConfirmationModal(true);
    }, 400);
  };

  const onResumSubscription = async () => {
    if (status === 'past_due' || status === 'incomplete') {
      setShowMessage(true);
      return;
    }
    const {
      client_secret,
      payment_method,
      card,
      status: resumeStatus,
      pay_as_you_go
    } = await resumSubscription(tokenId);
    if (status === 'canceled') {
      if (pay_as_you_go) {
        toast.success('Subscription resumed successfully.');
        window.location.reload();
      }
      if (!client_secret) {
        toast.error('Could not cofirm payment. Please try again.');
        return;
      }
      if (card) {
        setClientSecret(client_secret);
        setShowCardModal(true);
        return;
      }

      if (!payment_method) {
        toast.error('Could not cofirm payment. Please try again.');
        return;
      }
      confirmPayment(stripe, navigate, client_secret, payment_method);
    } else if (resumeStatus === 'done') {
      toast.success('Subscription resumed successfully.');
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  };

  const date = new Date();
  const currentDate = new Date();
  // Adding seven days to the current date
  const newDate = new Date(date);
  newDate.setDate(currentDate.getDate() + 7);

  useEffect(() => {
    document.title = 'Subscription | Fullframe';
  }, []);

  return (
    <Card>
      <div className="sm:pl-4">
        {status === 'not_subscribed' && <StripePricingTable />}
        {/* If user has not canceled the subscription */}
        {status !== 'not_subscribed' && !canceled && (
          <>
            {status ? (
              <h2 className="text-lg font-semibold text-center sm:text-left">Buy Extra Credits</h2>
            ) : (
              <div className="bg-gray-300 py-3 md:py-4 w-32 md:w-36 animate-pulse rounded-md" />
            )}
            {templates.map((template, index) => (
              <li
                className={`${!(templates.length === index + 1) && 'border-b '} flex flex-col sm:flex-row gap-4 justify-between items-center py-4 border-[#1E1E39]`}
                key={template.id}>
                <div className="flex flex-col sm:flex-col 2xl:flex-row gap-5 sm:gap-6 md:gap-7 lg:gap-6 2xl:gap-[8%] items-center md:items-start 2xl:items-center w-full">
                  {status ? (
                    <div className="flex gap-3 px-2 md:px-4 py-2 md:text-lg w-fit pr-4 xl:pr-6 2xl:pr-12 justify-self-center sm:justify-self-start font-filsonPro-semibold border border-primary bg-[#000120] rounded-lg">
                      <span className="text-primary">${template.price}</span>
                      <span>{template.title}</span>
                    </div>
                  ) : (
                    <div className="bg-gray-300 py-4 md:py-6 w-36 md:w-52 animate-pulse rounded-md" />
                  )}
                  <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4 md:gap-6">
                    {status ? (
                      <div className="flex items-center gap-2 sm:gap-4">
                        <button
                          type="button"
                          aria-label="reduce credits"
                          className={`fas fa-minus text-sm sm:text-base px-3 py-2 sm:p-2.5 sm:px-4 md:py-3 md:px-4 rounded-lg ${!(status === 'trialing') ? 'bg-[rgba(255,255,255,0.1)] hover:bg-primary' : 'bg-[rgba(255,255,255,0.5)]'} cursor-pointer`}
                          onClick={() => onSubtractQuantity(index, status)}
                          disabled={status === 'canceled' || canceled || status === 'incomplete'}
                          title="Remove credits"
                        />
                        <span className="px-4 py-2 sm:px-5 md:py-3 lg:px-6 xl:px-7 bg-[#000120] text-base font-filsonPro-bold rounded-lg">
                          {template.quantity}{' '}
                          {template.quantity === 1 ? (
                            <span>Credit&nbsp;&nbsp;&nbsp;</span>
                          ) : (
                            'Credits'
                          )}
                        </span>
                        <button
                          type="button"
                          aria-label="Add Credits"
                          className={`fas fa-plus text-sm sm:text-base px-3 py-2 sm:p-2.5 sm:px-4 md:py-3 md:px-4 rounded-lg ${!(status === 'trialing') ? 'bg-[rgba(255,255,255,0.1)] hover:bg-primary' : 'bg-[rgba(255,255,255,0.5)]'} cursor-pointer`}
                          onClick={() => onAddQuantity(index, status)}
                          disabled={status === 'canceled' || canceled || status === 'incomplete'}
                          title="Add more credits"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 sm:gap-4">
                        <div className="p-3 sm:p-5 md:p-4 lg:p-5 xl:p-6 bg-gray-300 rounded-lg animate-pulse" />
                        <span className="px-9 py-3 mx-3 my-2 sm:mx-4 md:my-3 lg:mx-4 xl:mx-5 bg-gray-300 animate-pulse rounded-md" />
                        <div className="p-3 sm:p-5 md:p-4 lg:p-5 xl:p-6 bg-gray-300 rounded-lg animate-pulse" />
                      </div>
                    )}
                    {status ? (
                      <span className="font-semibold text-base md:text-lg whitespace-nowrap self-center md:self-end lg:self-center">
                        Total: ${template.subTotal}
                        .00{' '}
                      </span>
                    ) : (
                      <span className="px-12 py-3.5 bg-gray-300 animate-pulse rounded-md" />
                    )}
                    {status ? (
                      <Button
                        type="button"
                        className="flex items-center justify-center gap-1 md:gap-2 font-bold"
                        onClick={() => buyCreditsClickHandler(template)}
                        disabled={status === 'canceled' || canceled || status === 'incomplete'}>
                        <span className="fas fa-cart-plus" />
                        <span>
                          Buy&nbsp;
                          {template.product.substring(0, 1).toUpperCase()}
                          {template.product.substring(1)} Credits
                        </span>
                      </Button>
                    ) : (
                      <div className="bg-gray-300 py-4 md:py-6 w-36 md:w-52 animate-pulse rounded-md" />
                    )}
                  </div>
                </div>
              </li>
            ))}
          </>
        )}
        {status !== 'not_subscribed' && (
          <div className="w-full mt-6 md:pt-9 border-t-2 border-[#2a2a3d]">
            <div className="max-w-4xl mx-auto px-4">
              {status ? (
                <h2 className="text-lg md:text-xl lg:text-2xl font-semibold mb-4">
                  Subscription Status
                </h2>
              ) : (
                <div className="bg-gray-300 py-4 md:py-[1.1rem] w-32 md:w-48 mb-4 animate-pulse rounded-md" />
              )}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 border border-[#262646] rounded-lg p-6 bg-[rgba(16,17,37,0.9)]">
                {status ? (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col items-center sm:items-start ">
                      <h3 className="text-lg font-filsonPro-semibold text-white">Date Started</h3>
                      <p className="text-white font-filsonPro-regular">
                        {start_date &&
                          new Intl.DateTimeFormat('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          }).format(start_date * 1000)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2 items-center sm:items-start ">
                      <div className="px-16 py-3 bg-gray-300 animate-pulse rounded-md" />
                      <div className="px-16 py-3 bg-gray-300 animate-pulse rounded-md" />
                    </div>
                    <div className="flex flex-col gap-2 items-center sm:items-start ">
                      <div className="px-16 py-3 bg-gray-300 animate-pulse rounded-md" />
                      <div className="px-16 py-3 bg-gray-300 animate-pulse rounded-md" />
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-5 sm:gap-4 items-center sm:items-end justify-between">
                  {/* eslint-disable-next-line no-nested-ternary  */}
                  {!(status === 'canceled') && !canceled ? (
                    status ? (
                      <div className="flex flex-col items-center sm:items-start">
                        <h3 className="text-lg font-filsonPro-semibold text-white">
                          Next Billing Date
                        </h3>
                        <p className="text-white font-filsonPro-regular text-center">
                          {current_period_end &&
                            new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            }).format(current_period_end * 1000)}
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2 items-center sm:items-start ">
                        <div className="px-16 py-3 bg-gray-300 animate-pulse rounded-md" />
                        <div className="px-16 py-3 bg-gray-300 animate-pulse rounded-md" />
                      </div>
                    )
                  ) : (
                    // If user has canceled the subscription
                    <div className="flex flex-col gap-4 w-fit">
                      <div className="flex flex-col items-center sm:items-start">
                        <h3 className="text-lg font-filsonPro-semibold text-white">
                          Subscription End Date
                        </h3>
                        <p className="text-white font-filsonPro-regular text-enter">
                          {current_period_end &&
                            new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            }).format(current_period_end * 1000)}
                        </p>
                      </div>
                      <div className="flex flex-col items-center sm:items-start">
                        <h3 className="text-lg font-filsonPro-semibold text-white">Canceled On</h3>
                        <p className="text-white font-filsonPro-regular">
                          {canceled_at &&
                            new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            }).format(canceled_at * 1000)}
                        </p>
                      </div>
                    </div>
                  )}
                  {status !== 'incomplete' && (
                    <div className="flex justify-center w-full sm:w-fit">
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {status ? (
                        status === 'canceled' || canceled ? (
                          // If user has canceled the subscription
                          <Button
                            type="button"
                            className="flex items-center justify-center gap-2 px-2 md:px-4 !w-fit font-filsonPro-semibold hover:-translate-y-0.5 transition-all duration-200"
                            onClick={onResumSubscription}>
                            <span>Resume Subscription</span>
                          </Button>
                        ) : (
                          <div className="flex gap-2 items-center">
                            <Button
                              to="https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk"
                              element="link"
                              className="flex items-center justify-center gap-2 px-2 md:px-4 !w-fit font-filsonPro-semibold hover:-translate-y-0.5 transition-all duration-200">
                              <span>Change Plan</span>
                            </Button>
                            <Button
                              type="button"
                              className="flex items-center justify-center gap-2 px-2 md:px-4 !w-fit font-filsonPro-semibold hover:-translate-y-0.5 transition-all duration-200"
                              onClick={onCancelSubscriptionClick}>
                              <span>Cancel Subscription</span>
                            </Button>
                          </div>
                        )
                      ) : (
                        <div className="bg-gray-300 py-3 md:py-4 w-32 md:w-36 animate-pulse rounded-md" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <MessageModal
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        title={
          status === 'trialing' ? (
            <span> Buy Credits </span>
          ) : (
            action === 'requires_payment_method' && (
              <>
                <span className="mr-1">👋</span>
                <span className="lg:text-lg">Update Payment Information - Insufficient Funds</span>
              </>
            )
          )
        }
        message={
          status === 'trialing' ? (
            <div className="flex flex-col items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
              <p className="font-semibold self-start">
                Would you like to start your subscription today and continue with uninterrupted
                coverage?
              </p>
              <div className="text-right w-full">
                <p>Cost: $25.00/month</p>
              </div>
              <Button
                onClick={endTrialClickHandler}
                className="!font-inter !font-semibold self-center">
                Start Paid Subscription
              </Button>
            </div>
          ) : (
            action === 'requires_payment_method' && (
              <div className="flex flex-col items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
                <p className="font-semibold">
                  There are insufficient funds on this form of payment. Please change to a different
                  form of payment or add funds and try again. You can update your payment
                  information in our billing portal.
                </p>
                <Button
                  element="link"
                  to="https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk"
                  className="!font-inter !font-semibold"
                  onClick={() => setShowMessage(false)}>
                  Update Payment Information
                </Button>
              </div>
            )
          )
        }
        noBottomMargin
      />
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        suretyClickHandler={suretyClickHandler}
        subHeading={
          cancelClicked
            ? 'Please confirm you would like to cancel?'
            : action === 'requires_action' && 'Confirm Subscription'
        }
        message={
          cancelClicked
            ? 'If so, select “Yes” to cancel the subscription.'
            : action === 'requires_action' &&
              'Please confirm you would like to start your paid subscription for $25? You will be charged immediately and receive an additional 10 script credits and 5 book credits.'
        }
      />
      <CardInputModal
        clientSecret={clientSecret}
        showCardModal={showCardModal}
        setShowCardModal={setShowCardModal}
      />
      <ChurnModal
        setShowMessage={setShowChurnModal}
        showMessage={showChurnModal}
        cancelSubscription={cancelSubscriptionHandler}
      />
    </Card>
  );
};

export default Balance;
