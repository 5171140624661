import React from 'react';
import RenderTypeIcon from '../RenderTypeIcon';

const RenderListIcon = ({
  value,
  itemColorStyle,
  isSubmissionType,
  isMultiEntry,
  isAuthorDropdown
}) => {
  if (isSubmissionType || isMultiEntry || isAuthorDropdown) {
    return (
      <RenderTypeIcon
        type={value}
        className={`${isSubmissionType && 'h-4 w-4'}`}
        isMultiEntry={isMultiEntry}
        isAuthorDropdown={isAuthorDropdown}
      />
    );
  } else {
    return (
      <div
        style={{
          borderColor: itemColorStyle.circleColor,
          borderWidth: '1px',
          borderStyle: itemColorStyle.dashad ? 'dashed' : 'solid'
        }}
        className="inline-block mr-2 w-2.5 h-2.5 rounded-full"
      />
    );
  }
};

export default RenderListIcon;
