import { useEffect } from 'react';
import { ReactComponent as PDFIcon } from '../../../static/icons/pdf.svg';
import { ReactComponent as Spinner } from '../../../static/icons/spinner.svg';
import { ReactComponent as WordIcon } from '../../../static/icons/word.svg';
import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import ModalHeader from './ModalHeader';

/* eslint-disable react/prop-types */
const ReportGenerationModal = ({
  reportGenerationModal,
  setReportGenerationModal,
  selectedFile,
  isLoading = true
}) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setReportGenerationModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const fileExtension = selectedFile?.name?.split('.')?.pop()?.toLowerCase();
  const returnFileIcon = () => {
    if (fileExtension === 'pdf') {
      return <PDFIcon className="h-9 stroke-gray-500" />;
    } else if (['docx', 'doc']?.includes(fileExtension)) {
      return <WordIcon className="h-9 stroke-gray-500" />;
    } else {
      return null;
    }
  };

  return (
    <Modal onClose={() => setReportGenerationModal(false)} showModal={reportGenerationModal}>
      <div className="mb-4 flex flex-col items-center justify-center gap-7 w-full">
        <ModalHeader
          text={
            <div className="flex justify-between">
              <span>Your results will be delivered:</span>
              <button type="button" onClick={() => setReportGenerationModal(false)}>
                <i className="fas fa-times cursor-pointer px-2 py-1" />
              </button>
            </div>
          }
        />
        <div className="flex flex-col gap-3 px-4 md:pl-6 md:pr-6 lg:pr-8 w-full">
          <div className="flex flex-col gap-1">
            <p className="text-gray-600">1. Directly to your email.</p>
            <p className="text-gray-600">
              2. Through direct download, provided this tab remains open.
            </p>
          </div>
          <span className="font-semibold">
            <span className="text-primary">*</span> Feel free to close this message.
          </span>
        </div>
        <div className="flex flex-col gap-7 px-6 w-full">
          <div className="flex items-center justify-between pl-8 pr-4 border border-gray-400 backdrop:filter-blur(15px) rounded-[10px]">
            <div className="flex gap-3 items-center py-3">
              {returnFileIcon()}
              <div className="flex flex-col font-filsonPro-semibold">
                <span>{`${selectedFile?.name.substring(0, 50)}${selectedFile?.name.length > 50 ? '...' : ''}`}</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {isLoading ? (
                <Spinner className="animate-spin h-8 w-8 fill-primary" />
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.75 12.25L11 14.5L15.25 9.25"
                    stroke="green"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="green"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <div className="flex justify-center w-full">
            <Button
              className="flex items-center justify-center gap-2"
              onClick={() => setReportGenerationModal(false)}>
              <i className="fas fa-times-square" />
              <span className="">Close</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportGenerationModal;
