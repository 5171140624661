import { useEffect } from 'react';

import Modal from '../../UI/Modal';

/* eslint-disable react/prop-types */
const MessageModal = ({
  title,
  message,
  showMessage = false,
  setShowMessage,
  noBottomMargin = false,
  className = ''
}) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setShowMessage(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Modal onClose={() => setShowMessage(false)} showModal={showMessage} className={className}>
      <div className="bg-dark">
        <button
          type="button"
          className="float-right mr-3 mt-1 p-1"
          onClick={() => setShowMessage(false)}
        >
          <i className="fas fa-times text-lg text-primary" />
        </button>
        <div className="flex flex-col gap-2 px-4 sm:px-6 md:pr-8 w-full mt-8  bg-dark">
          <span className="font-bold text-white">{title}</span>
          <div className="text-white">{message}</div>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
