import toast from 'react-hot-toast';
export const verify = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}verify/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    if (response.status === 400) {
      const data = await response.json();
      return data;
    }
    if (response.status === 401 || response.status === 403) {
      toast.error('Unauthorized User');
      return null;
    }
  } catch (err) {
    toast.error('Something went wrong');

    return null;
  }
  return null;
};
