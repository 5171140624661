import { Helmet } from 'react-helmet';

function HelmetComponent({ title, description, cannonical }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Cannonical's should always point to the production site regardless of the environment to allow search engines to only index the production contact */}
      {cannonical ? (
        <link rel="canonical" href={`https://www.fullfr.me${cannonical}`}></link>
      ) : null}
    </Helmet>
  );
}

export default HelmetComponent;
