import axios from 'axios';
import cookieVerify from './cookie';

export const apiInstance = (location) =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${cookieVerify(location)}`
    },
    withCredentials: true
  });
