import React, { Fragment } from 'react';

const NavlinksSkeleton = () => {
  return (
    <ul className="flex flex-col gap-4 md:gap-4.5 animate-pulse mt-0 md:mt-3 mb-3 sm:mb-6">
      <li className="py-2.5 bg-gray-200 w-3/5 rounded-3xl" />
      {[...Array(7)].map((_, i) => (
        <Fragment key={i}>
          <li className="py-3 bg-gray-200 w-10/12 rounded-3xl" />
          {i === 1 && <li className="py-2.5 bg-gray-200 w-3/5 rounded-3xl" />}
        </Fragment>
      ))}
      <span className="sr-only">Loading...</span>
    </ul>
  );
};

export default NavlinksSkeleton;
