import { Helmet } from 'react-helmet';
import Card from '../components/UI/Card';

/* eslint-disable react/prop-types */
const Contact = ({ className }) => (
  <Card className={`flex flex-col gap-3 md:gap-4 lg:gap-6 ${className} `}>
    <Helmet>
      <title>Contact Us | FullFrame</title>
      <meta
        name="description"
        content="Contact us for any questions about your account, site functionality, or billing."
      />
    </Helmet>
    <h3 className="text-lg md:text-xl font-filsonPro-bold">CONTACT</h3>
    <div className="flex flex-col gap-6 sm:w-11/12 md:w-10/12 xl:w-3/4 font-filsonPro-book">
      <p>
        We&apos;re always here to help! If you have any questions about your account, site
        functionality, or billing, please don&apos;t hesitate to get in touch with us. Our dedicated
        support team can be reached by email at&nbsp;
        <a href="mailto:Support@Fullfr.me" className="text-primary">
          Support@Fullfr.me
        </a>
        , and we&apos;ll make sure to get back to you as quickly as possible
      </p>
      <p>
        Whether you&apos;re experiencing technical difficulties or just need a little guidance,
        we&apos;re here to provide you with the support you need to succeed.
      </p>
    </div>
  </Card>
);

export default Contact;
