import React from 'react';
import { NavLink } from 'react-router-dom';

const MultiOptionItem = ({ subItem }) => {
  const pathname = window.location.pathname.substring(1) || 'script-coverage';
  const isActive = pathname === subItem?.linkTo;
  return (
    <NavLink
      to={subItem.linkTo}
      exact="true"
      className={`flex items-center gap-2 px-2.5 py-1.5 xl:py-2 w-full leading-6 ${
        isActive
          ? 'bg-primary rounded-xl'
          : 'bg-transparent hover:!text-secondary hover:border-l-secondary'
      } font-Inter border-2 border-transparent transition-all duration-100 cursor-pointer group`}>
      {subItem.icon(isActive)}
      <span className="w-full text-[11px] font-semibold">{subItem?.value}</span>
    </NavLink>
  );
};

export default MultiOptionItem;
