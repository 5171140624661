import React, { useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import DataTable from '../components/DataTable/DataTable';
import Divider from '../components/Divider/Divider';
import ConfirmationModal from '../components/Modal/ConfirmationModal';
import Button from '../components/UI/Button';
import Card from '../components/UI/Card';
import ActionButton from '../components/atoms/ActionButton';
import EditableText from '../components/atoms/EditableText';
import { Cross, PlusIcon, Upload } from '../components/icons/icons';
import DropdownNew from '../components/molecules/DropdownNew';
import SubmittedBy from '../components/templates/SubmittedBy';
import { MEDIA_TYPES, STATUS } from '../constants';
import userAvatar from '../static/icons/avatar.png';
import useListStore from '../store/list-generator';
const ListSection = ({ id, prevSectionId, nextSectionId, title, talents, name }) => {
  const textRef = useRef();
  const [removeSection, addTalent, editTalent, editSection] = useListStore((store) => [
    store.removeSection,
    store.addTalent,
    store.editTalent,
    store.editSection
  ]);
  const [confirmationModal, setConfirmationModal] = useState(false);

  useEffect(() => {
    if (title) textRef?.current?.setText(title);
  }, [title]);
  const COLUMNS = useMemo(() => {
    return [
      {
        accessor: 'avatar',
        Header: 'Headshot',
        Cell({ row: { original }, value }) {
          const inputRef = useRef();
          const [imgSrc, setImgSrc] = useState(value);

          const handleFileChange = async (event) => {
            const file = event.target.files[0];
            if (file) {
              const reader = new FileReader();

              reader.onloadend = () => {
                const dataUrl = reader.result; // Base64 encoded string
                setImgSrc(dataUrl); // Update the imgSrc state with the new image URL

                editTalent(id, original._id, {
                  ...original,
                  avatar: dataUrl, // Storing the Base64 encoded string
                  avatar_blob: file // Still storing the original file if needed
                });
              };

              reader.readAsDataURL(file); // Convert file to Base64
            }
          };

          const handleError = () => {
            // Fall back to a default user icon image when an error occurs
            setImgSrc(userAvatar); // Replace with the path to your default icon
          };

          return (
            <div style={{ height: 40, width: 40 }}>
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id={`upload-${original._id}`}
              />
              {!imgSrc ? (
                <div className="flex items-center">
                  <label htmlFor={`upload-${original._id}`}>
                    <Upload className="cursor-pointer hover:opacity-70" />
                  </label>
                </div>
              ) : (
                <img
                  onClick={(e) => {
                    inputRef.current?.click?.();
                  }}
                  src={imgSrc}
                  onError={handleError}
                  className="rounded-md border border-gray-100 h-full w-full object-cover cursor-pointer"
                  alt={original?.name || 'User Icon'}
                />
              )}
            </div>
          );
        }
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell({ row: { original }, value }) {
          const textRef = useRef();

          useEffect(() => {
            if (value) textRef?.current?.setText(value);
          }, [value]);

          return (
            <button className="flex gap-1">
              <div style={{ height: 20, width: 20 }}>
                <svg
                  className="cursor-pointer"
                  onClick={() =>
                    editTalent(id, original._id, {
                      ...original,
                      is_favourite: !original.is_favourite
                    })
                  }
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M9.99999 1.66669L12.575 6.88335L18.3333 7.72502L14.1667 11.7834L15.15 17.5167L9.99999 14.8084L4.84999 17.5167L5.83332 11.7834L1.66666 7.72502L7.42499 6.88335L9.99999 1.66669Z"
                    stroke={original.is_favourite ? null : '#98A2B3'}
                    className={original.is_favourite ? 'fill-yellow-500' : null}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <EditableText
                ref={textRef}
                onChange={(value) => editTalent(id, original._id, { ...original, name: value })}
              />
            </button>
          );
        }
      },
      {
        accessor: 'credits',
        Header: 'Credits',
        minWidth: '17vw',
        Cell({ row: { original } }) {
          const [autoFocus, setAutoFocus] = useState();

          return (
            <div className="flex flex-wrap flex-grow-0 gap-1 max-w-sm items-center">
              {original.credits?.map?.((credit, index) => {
                return (
                  <DropdownNew
                    searchPlaceholder="Type.."
                    autoFocus={autoFocus}
                    className={'min-h-[25px] h-auto'}
                    items={MEDIA_TYPES}
                    value={credit}
                    key={index}
                    onDelete={() => {
                      const newCredits = original.credits.filter((c, idx) => idx !== index);
                      editTalent(id, original._id, {
                        ...original,
                        credits: newCredits //pleaese complete the logic here
                      });
                    }}
                    onChange={(value) => {
                      const updatedCredits = original.credits.map((item, i) =>
                        i === index ? value : item
                      );
                      editTalent(id, original._id, {
                        ...original,
                        credits: updatedCredits //pleaese complete the logic here
                      });
                      setAutoFocus(false);
                    }}
                  />
                );
              })}
              <ActionButton
                className="p-1 min-h-[30px] h-auto rounded-[4px]"
                onClick={() => {
                  editTalent(id, original._id, {
                    ...original,
                    credits: [...original.credits, { type: MEDIA_TYPES[0], name: ' ' }] //pleaese complete the logic here
                  });
                  setAutoFocus(true);
                }}>
                <PlusIcon />
              </ActionButton>
            </div>
          );
        }
      },
      {
        accessor: 'upcoming_projects',
        Header: 'Next Up Project',
        minWidth: '17vw',
        Cell({ row: { original } }) {
          const [autoFocus, setAutoFocus] = useState();
          return (
            <div className="flex flex-wrap flex-grow-0 gap-1 max-w-sm items-center">
              {original.upcoming_projects?.map?.((project, index) => {
                return (
                  <DropdownNew
                    searchPlaceholder="Type.."
                    className={'bg-orange-400 min-h-[25px] h-auto'}
                    autoFocus={autoFocus}
                    items={MEDIA_TYPES}
                    value={project}
                    key={index}
                    onDelete={() => {
                      const newVal = original.upcoming_projects.filter((c, idx) => idx !== index);
                      editTalent(id, original._id, {
                        ...original,
                        upcoming_projects: newVal //pleaese complete the logic here
                      });
                    }}
                    onChange={(value) => {
                      const updatedVal = original.upcoming_projects.map((item, i) =>
                        i === index ? value : item
                      );
                      editTalent(id, original._id, {
                        ...original,
                        upcoming_projects: updatedVal //pleaese complete the logic here
                      });
                      setAutoFocus(false);
                    }}
                  />
                );
              })}
              <ActionButton
                className="p-1 bg-orange-400 min-h-[30px] h-auto rounded-[4px]"
                onClick={() => {
                  editTalent(id, original._id, {
                    ...original,
                    upcoming_projects: [
                      ...original.upcoming_projects,
                      { type: MEDIA_TYPES[0], name: ' ' }
                    ]
                  });
                  setAutoFocus(true);
                }}>
                <PlusIcon />
              </ActionButton>
            </div>
          );
        }
      },
      {
        accessor: 'submitted_by',
        Header: 'Representation',
        Cell({ row: { original } }) {
          return (
            <SubmittedBy
              item={original}
              label={`listsection-${original._id}`}
              onUpdate={(data) => editTalent(id, original._id, data)}
            />
          );
        }
      },
      {
        accessor: 'status',
        Header: 'Availability',
        minWidth: '10vw',
        Cell({ row: { original } }) {
          // const talent = useListStore((store) =>
          //   store.sections?.find((s) => s._id === id)?.talents?.find((t) => t._id === original._id)
          // );
          const status = original?.status;
          console.log(status);
          return (
            <div className="h-fit">
              <DropdownNew
                toggleOnTextClick={true}
                key={`${status?.name}-${status?.type?.id}`}
                disableEditing={true}
                className={twMerge(
                  'rounded-full min-h-[25px] h-auto',
                  status?.type?.id === 'contacted'
                    ? 'text-green-300 bg-[#34C7591A]'
                    : 'bg-[#F97E751A] text-red-100'
                )}
                items={STATUS}
                value={original?.status}
                onChange={(value) => {
                  const type = STATUS.find((s) => s.id === value.type?.id);
                  editTalent(id, original._id, {
                    ...original,
                    status: { type, name: type?.name }
                  });
                }}
              />
            </div>
          );
        }
      },
      {
        accessor: 'notes',
        Header: 'Notes',
        Cell({ row: { original }, value }) {
          const textRef = useRef();

          useEffect(() => {
            if (value) textRef?.current?.setText(value);
          }, [value]);
          return (
            <EditableText
              className="text-xs"
              ref={textRef}
              onChange={(value) =>
                editTalent(id, original._id, {
                  ...original,
                  notes: value
                })
              }
            />
          );
        }
      },
      {
        accessor: '_id',
        Header: 'Move Tier',
        Cell({ row: { original }, value }) {
          const [removeTalent, moveTalent] = useListStore((store) => [
            store.removeTalent,
            store.moveTalent
          ]);

          return (
            <div className="flex justify-center items-center gap-2">
              <div className="flex flex-col gap-1">
                <ActionButton
                  disabled={!prevSectionId}
                  className={'py-1'}
                  onClick={() => moveTalent(id, prevSectionId, value)}>
                  <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M8 4.5L4 0.5L0 4.5H8Z" fill="white" />
                  </svg>
                </ActionButton>
                <ActionButton
                  disabled={!nextSectionId}
                  className={'py-1'}
                  onClick={() => moveTalent(id, nextSectionId, value)}>
                  <svg width="8" height="5" viewBox="0 0 8 5" fill="none">
                    <path d="M8 0.5L4 4.5L0 0.5H8Z" fill="white" />
                  </svg>
                </ActionButton>
              </div>
              <ActionButton onClick={() => removeTalent(id, value)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M2 4H14"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.6667 4V13.3333C12.6667 14 12 14.6667 11.3334 14.6667H4.66671C4.00004 14.6667 3.33337 14 3.33337 13.3333V4"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.33337 3.99992V2.66659C5.33337 1.99992 6.00004 1.33325 6.66671 1.33325H9.33337C10 1.33325 10.6667 1.99992 10.6667 2.66659V3.99992"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66663 7.33325V11.3333"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.33337 7.33325V11.3333"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ActionButton>
            </div>
          );
        }
      }
    ];
  }, [editTalent, prevSectionId, nextSectionId, talents?.map((t) => t._id)?.join('-')]);

  return (
    <Card className={'min-h-fit !p-0'} resetZIndex>
      <header className="p-4 flex justify-between items-center">
        <EditableText
          onChange={(value) => editSection(id, { title: value })}
          fullWidth
          ref={textRef}
        />
        {name === 'top' ? null : (
          <ActionButton className={'p-1'} onClick={() => setConfirmationModal(true)}>
            <Cross />
          </ActionButton>
        )}
      </header>
      <Divider className="my-0" />
      <section>
        <DataTable data={talents} columns={COLUMNS} />
      </section>
      <Divider className="my-0" />
      <footer className="p-4">
        <Button
          onClick={() => addTalent(id, { name: '', credits: [], upcoming_projects: [] })}
          variant="gray">
          <PlusIcon /> Add Talent
        </Button>
      </footer>
      <ConfirmationModal
        title={`Are you sure you want to delete “${title}” table? `}
        subTitle="This action cannot be undone."
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        onConfirm={() => {
          removeSection(id);
          setConfirmationModal(false);
        }}
      />
    </Card>
  );
};

export default ListSection;
