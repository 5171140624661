import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

import { SidebarContextProvider } from './context/sidebar';
import { SubmissionsContextProvider } from './context/submissions';
import { TemplatesContextProvider } from './context/templates';
import { UserDetailsContextProvider } from './context/user-details';

const { publishableKey } = require('./config.json');

const stripePromise = loadStripe(publishableKey.toString());

const root = ReactDOM.createRoot(document.getElementById('root'));

const options = {
  mode: 'setup',
  currency: 'usd',
  loader: 'auto'
};

root.render(
  <BrowserRouter>
    <SidebarContextProvider>
      <UserDetailsContextProvider>
        <TemplatesContextProvider>
          <SubmissionsContextProvider>
            <Elements stripe={stripePromise} options={options}>
              <App />
            </Elements>
          </SubmissionsContextProvider>
        </TemplatesContextProvider>
      </UserDetailsContextProvider>
    </SidebarContextProvider>
  </BrowserRouter>
);
