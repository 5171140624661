import { twMerge } from 'tailwind-merge';

function Card({ className, children, resetZIndex = false }) {
  return (
    <section
      className={twMerge(
        'min-h-[60vh] p-3 sm:p-3 md:p-4 lg:p-5 border border-[rgba(255,255,255,0.1)] rounded-[10px] bg-[rgba(0,1,32,0.2)]',
        resetZIndex === false ? 'z-10' : null,
        className
      )}>
      {children}
    </section>
  );
}

export default Card;
