
 export default function (str) {
  
    if (typeof str !== 'string') {
        throw new TypeError('The input must be a string');
    }
    if (str.length === 0) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
 }
