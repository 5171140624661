export const content = [
  {
    title: 'FAQ'
  },
  {
    title: 'Security, Privacy, & Data',
    subcontent: [
      {
        question: 'Q: Is my data secure with your software?',
        answer:
          'A: Yes! We take the security of your data very seriously. All of our software is designed with industry-standard encryption and security protocols on the Microsoft Azure Platform to ensure that your data is safe and secure.'
      },
      {
        question: 'Q: Who can access my data on your platform?',
        answer:
          'A: Data on the platform is encrypted at rest and in transit. Our authentication secures against unauthorized access.'
      },
      {
        question: 'Q: How do you handle my personal information?',
        answer:
          'A: We take your privacy very seriously and follow all applicable laws and regulations regarding the handling of personal information. Please refer to our Privacy Policy for more information.'
      },

      {
        question: 'Q: Can I delete my account from your servers?',
        answer:
          'A: Yes, you can delete your account from our servers at any time. Please contact our support team at Support@Fullfr.me if you need assistance with this process.'
      }
    ]
  },
  {
    title: 'Site Usage',
    subcontent: [
      {
        question: 'Q: How does your software augment human script and book coverage?',
        answer:
          'A: Our software streamlines the script and book coverage process by creating a first draft template, largely reducing the more clerical tasks (Summarization, character description) of script coverage. Our software does not replace a human reader’s creative input or make an evaluation on a script’s merits. By cutting down on time spent summarizing, we hope to free up time for users to form thoughtful opinions on material.'
      },
      {
        question: 'Q: What if I didn’t get my report?',
        answer:
          'A: Please reach out to support@fullfr.me and we will make sure this report doesn’t count against your monthly allowance. Please note that since we do not persist any reports for your content’s security, the only way to download a report currently is to keep the window open that the report is being generated in.'
      },
      {
        question: 'Q: Is the software easy to use?',
        answer:
          "A: Yes! We've designed our software to be intuitive and user-friendly, even for those who may not be as tech-savvy. We also provide training and support to help you get started and answer any questions you may have."
      },

      {
        question: 'Q: What devices can I use the software on?',
        answer:
          'A: Our software is web-based and can be accessed from any device with an internet connection, including desktops, laptops, tablets, and smartphones.'
      },

      {
        question: 'Q: What if I have feedback or suggestions for improving the software?',
        answer:
          'A: We welcome feedback and suggestions from our users! Please feel free to email us at Support@Fullfr.me with any suggestions or concerns you may have.'
      }
    ]
  },
  {
    title: 'Billing',
    subcontent: [
      {
        question: 'Q: What are the billing options for your software?',
        answer:
          'A: We currently multiple billing options, which includes access to our script and book coverage template generator and automated material log.'
      },

      {
        question: 'Q: Is there a free trial available?',
        answer:
          'A: Yes! We offer a 7-day free trial of our software so you can try it out before committing to a subscription.'
      },

      {
        question: 'Q: Are there any caps on how many reports I can generate?',
        answer: (
          <span>
            A: Your monthly subscription allows you to generate up to the specific amount of scripts
            and/or book coverage. If you need to produce more templates in a month, you can purchase
            more credits on a pay-as-you-go basis. If you have any questions, please reach out to{' '}
            <a href="mailto:support@fullfr.me" className="text-primary">
              support@fullfr.me
            </a>{' '}
            to discuss add-on package pricing.
          </span>
        )
      },

      {
        question: 'Q: Can I cancel my subscription at any time?',
        answer:
          'A: Yes, you can cancel your subscription at any time on our site or by reaching out to our support team. Your subscription will persist until the next billing cycle, at which point you will lose access.'
      }
    ]
  }
];
