import { useEffect } from 'react';
import Card from '../components/UI/Card';

function AboutUs() {
  useEffect(() => {
    document.title = 'About Us | FullFrame';
  }, []);
  return (
    <Card className="flex flex-col gap-6">
      <h3 className="text-lg md:text-xl font-filsonPro-bold">ABOUT</h3>
      <div className="flex flex-col gap-6 sm:w-11/12 md:w-10/12 xl:w-3/4 font-filsonPro-book">
        <p>
          Welcome to FullFrame, where we&apos;re all about empowering film and television creatives
          to focus on what really matters: bringing their visions to life. We know firsthand the
          frustrations of being bogged down by administrative tasks, particularly in the
          entertainment industry. That&apos;s why we&apos;re on a mission to revolutionize the way
          Hollywood works, starting with development and pre-production processes.
        </p>
        <p>
          Our flagship productivity tool is designed to streamline the script coverage process,
          saving you countless hours of tedious work. But we&apos;re not stopping there. We&apos;re
          already hard at work on new tools that will expedite research and analysis processes,
          making your work even more efficient and freeing up even more time for you to create.
        </p>
        <p>
          We believe that by giving creatives more time to focus on what they do best, we can help
          transform the industry for the better. Thank you for joining us on this journey - we
          can&apos;t wait to see what you&apos;ll create with the help of FullFrame.
        </p>
      </div>
    </Card>
  );
}

export default AboutUs;
