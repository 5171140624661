export default (dateInput) => {
    let date;
    if (dateInput instanceof Date) {
      date = dateInput;
    } else {
      const isoDate = dateInput + 'Z';
      date = new Date(isoDate);
      if (isNaN(date.getTime())) {
        date = new Date(dateInput);
      }
    }
    return date;
}