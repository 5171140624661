import PropTypes from 'prop-types';
import Button from '../UI/Button';

function BuyMoreCreditsDropDown({ isScript, credits, setCredits }) {
  return (
    <div className="mt-6 mb-6 flex flex-col items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
      <div className="flex gap-4 self-center">
        <div className="flex gap-2 items-center">
          <p
            aria-label="credits dropdown"
            htmlFor="credits"
            className="block text-base sm:text-lg font-semibold"
          >
            Credits:
          </p>
          <select
            name="credits"
            id="credits"
            onChange={(e) => {
              setCredits(e.target.value);
            }}
            className="text-lg font-filsonPro-book border-2 border-primary text-center bg-transparent w-[4rem] py-1.5 rounded-md  outline-none"
            defaultValue={credits}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
        </div>
        <Button
          element="link"
          to={`/billing/checkout/${isScript ? 'script' : 'book'}?${credits === 1 ? 'credit=1' : `credits=${credits}`}`}
          className="!py-2.5 sm:!py-3  !font-inter !font-semibold"
        >
          Buy Credits
        </Button>
      </div>
    </div>
  );
}

BuyMoreCreditsDropDown.propTypes = {
  isScript: PropTypes.bool.isRequired,
  credits: PropTypes.number.isRequired,
  setCredits: PropTypes.func.isRequired
};

export default BuyMoreCreditsDropDown;
