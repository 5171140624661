import toast from 'react-hot-toast';

export const endTrial = async (setShowMessage, tokenId, navigate) => {
  setShowMessage(false);

  const loadingToastId = toast.loading('Ending Trial Period...');

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}end_trial/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenId}`
    }
  });

  if (response.status === 200) {
    toast.dismiss(loadingToastId);
    toast.success('Trial Period Ended Successfully');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  if (response.status === 401) {
    const responseJson = await response.json();
    toast.dismiss(loadingToastId);
    console.log(responseJson.message);
    toast.error(responseJson.message);
    setTimeout(() => {
      navigate('/script-coverage');
    }, 1000);
    return;
  }

  if (response.status === 400) {
    const responseJson = await response.json();
    toast.dismiss(loadingToastId);
    console.log(responseJson.message);
    toast.error(responseJson.message);
  }
};
