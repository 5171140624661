import React, { useEffect, useRef } from 'react';

const TableTextAreaField = ({
  value,
  onChange,
  onKeyDown = () => {},
  onBlur,
  onFocus,
  className = '',
  id = '',
  style
}) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      // Reseting height before recalculating to avoid infinite growth
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textAreaRef}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`px-2 py-1 bg-[#292A3580] border-[rgba(255,255,255,0.2)] border border-tansparent resize-none rounded outline-none ${className}`}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
      title={value}
      style={style}
      cols={84}
    />
  );
};

export default TableTextAreaField;
