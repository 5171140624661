/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { twMerge } from 'tailwind-merge';

// Main Table component
function DataTable({ data, columns, className }) {
  // Memoize the columns and data for performance
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  // Use the useTable hook to create the table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: memoizedColumns,
    data: memoizedData
  });

  return (
    <div className="overflow-x-auto">
      <table
        {...getTableProps()}
        className={twMerge('min-w-full divide-y divide-black-50 text-white', className)}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="px-6 py-3 text-left text-sm font-semibold tracking-wider text-gray-400"
                  style={{
                    width: column.width ? column.width : 'auto',
                    minWidth: column.minWidth ? column.minWidth : 'auto'
                  }}>
                  {column.renderHeader ? column.renderHeader(headerGroup) : column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length ? (
          <tbody {...getTableBodyProps()} className="bg-transparent text-white">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-6 py-4 whitespace-nowrap text-sm"
                      style={{ width: cell.column.width ? cell.column.width : 'auto' }}>
                      {cell.column.renderCell ? cell.column.renderCell(cell) : cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={99}>
                <div className="flex justify-center items-center p-6 bg-transparent">
                  No data found
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

export default DataTable;
