import toast from 'react-hot-toast';

export const resumSubscription = async (tokenId) => {
  const loadingToastId = toast.loading('Resuming Subscription...');

  const defaultResponse = {
    client_secret: null,
    payment_method: null,
    card: null
  };

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}resume_sub/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenId}`
    }
  });
  if (response.status === 200) {
    toast.dismiss(loadingToastId);
    const responseJson = await response.json();

    return responseJson;
  }

  if (response.status === 401) {
    const responseJson = await response.json();
    toast.dismiss(loadingToastId);

    toast.error(responseJson.message);

    setTimeout(() => {
      window.location.reload();
    }, 2000);

    return defaultResponse;
  }

  if (response.status === 400) {
    toast.dismiss(loadingToastId);
    const responseJson = await response.json();

    toast.error(responseJson.message);
  }

  return defaultResponse;
};
