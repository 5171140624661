import React from 'react';
import { twMerge } from 'tailwind-merge';

const Divider = ({ orientation = 'horizontal', className = '' }) => {
  const baseStyles = 'bg-[rgba(255,255,255,0.1)]';
  const orientationStyles = orientation === 'horizontal' ? 'w-full h-px my-2' : 'h-full w-px mx-2';

  return <div className={twMerge(baseStyles, orientationStyles, className)} />;
};

export default Divider;
