// It will return the date in this format month/Day/Year. Eg: 8/16/24
export const returnFormattedDate = (inputDate) => {
  if (!inputDate) {
    return '';
  } else {
    const date = new Date(inputDate);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    return formattedDate;
  }
};
