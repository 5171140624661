import { Outlet } from 'react-router-dom';

function UnprotectedLayout() {
  return (
    <main id="pageContainer" className="w-full z-20 h-screen full-wrapper overflow-y-scroll">
      <div className="p-4 md:p-6 lg:p-7 rounded-[10px] bg-[rgba(0,1,32,0.65)] z-10">
        <Outlet />
      </div>
    </main>
  );
}

export default UnprotectedLayout;
