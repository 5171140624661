import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const ActionButton = forwardRef(({ children, className, disabled, ...props }, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      disabled={disabled}
      className={twMerge(
        'rounded-sm bg-gray-800 p-2 hover:opacity-60 border border-black-50',
        disabled && 'opacity-30 hover:opacity-30 pointer-events-none',
        className
      )}>
      {children}
    </button>
  );
});

export default ActionButton;
