import { useId } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './styles.module.css';
import { Tooltip } from '@mui/material';
import { ChevronDownIcon, InfoIcon } from '../icons/icons';

const Select = ({
  items,
  label,
  placeholder,
  showPlaceholder = false,
  tooltip = null,
  className,
  renderOption = (item) => (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  )
}) => {
  // Generate a unique ID for the select element
  const id = useId();

  function renderOptions(items) {
    return items?.map(renderOption);
  }
  return (
    <fieldset>
      {/* Label for the select element */}
      {label && (
        <label
          htmlFor={id}
          className="flex mb-2 text-sm font-filsonPro-bold text-white items-center gap-2 !pl-1">
          {label}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <InfoIcon />
            </Tooltip>
          )}
        </label>
      )}
      {/* Select dropdown */}
      <div className={twMerge('relative')}>
        <select id={id} className={twMerge(styles.base, 'relative', className)}>
          {/* Optionally show a placeholder option */}
          {showPlaceholder && <option value="">{placeholder || '-- Select --'}</option>}
          {renderOptions(items)}
        </select>
        <ChevronDownIcon className={'absolute top-1/2 z-10 right-2 transform -translate-y-1/2'} />
      </div>
    </fieldset>
  );
};

export default Select;
