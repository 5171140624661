export const ChatIcon = ({ className = '', onClick }) => (
  <button type="button" onClick={onClick} className="group" title="Custom Query">
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`text-white group-hover:text-primary transition-all duration-300 ${className}`}>
      <path
        d="M5.66667 8.00004H5.675M9 8.00004H9.00833M12.3333 8.00004H12.3417M16.5 8.00004C16.5 11.6819 13.1421 14.6667 9 14.6667C7.71728 14.6667 6.50976 14.3805 5.45389 13.8759L1.5 14.6667L2.66249 11.5667C1.9263 10.5353 1.5 9.31194 1.5 8.00004C1.5 4.31814 4.85786 1.33337 9 1.33337C13.1421 1.33337 16.5 4.31814 16.5 8.00004Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
);
