import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Card from '../components/UI/Card';
import { useUserDetialsContext } from '../context/user-details';
import { ReactComponent as PDFIcon } from '../static/icons/pdf.svg';
import { ReactComponent as WordIcon } from '../static/icons/word.svg';
import LogoCroped from '../static/images/logo-croped.png';
import { getChatHistory, sendChatQuery } from '../utils/chat';
import MessageItem from './components/MessageItem';
import SuggestionsItem from './components/Suggestions';
import { SpinnerSmall } from '../components/spinner/Spinner';

function QueryScript({ setAndUpdateChatTitle }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [fileName, setSelectedFile] = useState('');
  const { userDetails } = useUserDetialsContext();
  const { name, status } = userDetails;
  const [chatId, setChatId] = useState('');
  const [queryInProgress, setQueryInProgress] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [messageText, setMessageText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(messageText);
  const requestCount = useRef(1);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const timer = useRef(null);

  const suggestions = [
    'What are some similar films?',
    'What is the character arc of the main character?',
    'When does the film take place, and what real-life historical events happened at the time?',
    'Why is the main character uniquely equipped for their quest?'
  ];

  const sendMessage = async (message, updatedMessages) => {
    if (!!message && messageText !== '') {
      toast.error('Please enter a query first.');
      return;
    }

    if (queryInProgress) return;
    setQueryInProgress(true);
    requestCount.current && setShowSuggestions(false)
    requestCount.current += 1;

    const newMessage = message || messageText;
    const newMessages =
      message && updatedMessages ? [...updatedMessages] : [...messages, { text: newMessage }];

    setMessageText('');
    setMessages(newMessages);

    const payload = { query: newMessage, chat: true, chat_id: chatId };

    await sendChatQuery(payload, navigate, setMessages, newMessages, setQueryInProgress);
  
    // Handle chat title updates based on request count
    handleChatTitleUpdate(requestCount.current);
  };
  
  // Handle chat title generation based on query count
  const handleChatTitleUpdate = async (requestCount) => {
    if (requestCount === 2) {
      timer.current = setTimeout(async () => {
        await setAndUpdateChatTitle(chatId);
      }, 25000);
    } else if (requestCount === 3) {
      if (timer.current) clearTimeout(timer.current);
      await setAndUpdateChatTitle(chatId);
    }
  };

  const messagesEndRef = useRef(null);

  const newChatHandler = () => {
    navigate('/query', { state: { newChat: true }, replace: true });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    sendMessage();
  };

  const handleEditClick = (message) => {
    setEditedText(message);
    setIsEditing(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSaveClick = (index) => {
    // Implement your logic to save the edited text
    if (queryInProgress) {
      toast.error('Please wait for the current query to complete.');
      return;
    }
    if (editedText === '') {
      toast.error('Please enter a query first.');
      return;
    }
    if (editedText === messageText) {
      setIsEditing(false);
      return;
    }
    const updatedMessages = [...messages];
    // Removing all the messages after the edited message and the edited message itself
    updatedMessages.splice(index, updatedMessages.length - index);
    // Adding the edited message
    updatedMessages.push({ text: editedText });
    setIsEditing(false);
    setMessages(updatedMessages);
    sendMessage(editedText, updatedMessages);
  };

  const isMessageEditable = (index, message) =>
    (index === messages.length - 1 && !message.botMessage) ||
    (index === messages.length - 2 &&
      messages[messages.length - 1].botMessage &&
      !message.botMessage);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Scroll to bottom whenever messages change

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (messageText !== '') {
          sendMessage();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [messageText]);

  useEffect(() => {
    if (status === 'not_subscribed') {
      navigate('/script-coverage');
      return;
    }

    const chat_id = searchParams.get('chat_id');

    if (!chat_id) {
      setChatId('');
      return;
    }

    if (chat_id !== chatId) {
      setChatId(chat_id);
      setIsLoading(true);

      const payload = { chat: false, chat_id };
      getChatHistory(
        payload,
        navigate,
        (messages) => {
          setMessages(messages);
          setIsLoading(false);
        },
        setSelectedFile
      );
    }
  }, [searchParams, status, navigate, chatId]);

  const fileExtension = fileName?.split('.')?.pop()?.toLowerCase();

  const returnFileIcon = () => {
    if (fileExtension === 'pdf') {
      return <PDFIcon className="h-9 stroke-gray-500" />;
    } else if (['docx', 'doc']?.includes(fileExtension)) {
      return <WordIcon className="h-9 stroke-gray-500" />;
    } else {
      return null;
    }
  };

  return (
    <div className="flex flex-col gap-4 z-[1000]">
      <Card className="w-full">
        <div className="flex items-center gap-3 font-['Poppins',sans-serif] pb-2">
          <span>Query Submission</span>
          <button type="button" className="px-[0.2rem] group" onClick={newChatHandler}>
            <i className="fas fa-plus text-sm px-1 bg-primary group-hover:bg-primary-dark transition-all duration-300 rounded-full" />
            <span className="ml-1 text-primary group-hover:text-primary-dark transition-all duration-300 align-middle">
              New Query
            </span>
          </button>
        </div>
        <ul className="flex flex-col gap-7 pb-0 p-1 sm:p-4 pt-6 self-start text-sm font-['Poppins',sans-serif] h-[61vh] overflow-y-scroll">
          {isLoading ? (
            <li className="w-full flex justify-center items-center h-full">
              <div className="loader">
                {' '}
                <SpinnerSmall />{' '}
              </div>{' '}
              {/* Add appropriate CSS for the loader */}
            </li>
          ) : (
            <>
              <li className="flex gap-2 w-fit">
                <div className="flex items-center justify-center gap-[0.09rem] h-10 w-10 bg-[rgba(217,217,217,0.10)] text-[0.9rem] text-primary rounded-full">
                  <span>{name?.split(' ')[0]?.substring(0, 1)?.toUpperCase()}</span>
                  <span>{name?.split(' ')[1]?.substring(0, 1)?.toUpperCase()}</span>
                </div>
                <div className="flex gap-4 items-center p-3 sm:pr-8 rounded-2xl bg-[rgba(217,217,217,0.10)] font-filsonPro-regular w-fit">
                  {returnFileIcon()}
                  <div className="flex flex-col">
                    <span>{fileName}</span>
                    <span className="text-sm text-gray-400"></span>
                  </div>
                </div>
              </li>
              {/* First bot message */}
              <li className="flex gap-2 pr-8 w-fit">
                <img
                  src={LogoCroped}
                  className="mt-0.5 w-6 sm:w-8 h-6 sm:h-8 self-start rounded-full object-cover"
                  alt="bot"
                />
                <div className="flex flex-col gap-0.5 w-fit p-3 bg-[rgba(217,217,217,0.10)] rounded-2xl min-w-[13rem] sm:min-w-[15rem]">
                  <p className="font-normal mb-4">
                    Welcome to Query! Dive into more detail using our coverage report technology on
                    the entire text of a script or book, helping you explore submissions in greater
                    depth after generating coverage.
                  </p>

                  <p className="font-normal mb-4">
                    Use it to complement, not replace, the coverage generator, which is best for
                    creating summaries, loglines, and detailed analysis.
                  </p>

                  <p className="font-normal mb-4">
                    If you find yourself making the same query repeatedly, let us know, and we'll
                    work on building it into the standard coverage reports.
                  </p>

                  <p className="font-normal">
                    Please reach out to <a href="mailto:support@fullfr.me">support@fullfr.me</a> if
                    you have any questions.
                  </p>
                </div>
              </li>
              {/* Chat Suggestions */}
              {showSuggestions && messages.length === 0 && (
                <SuggestionsItem suggestions={suggestions} sendMessage={sendMessage} />
              )}
              {messages?.length > 0 &&
                messages?.map((message, index) => (
                  <MessageItem
                    key={message.text}
                    message={message}
                    index={index}
                    editable={isMessageEditable(index, message)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    handleEditClick={handleEditClick}
                    handleSaveClick={handleSaveClick}
                    editedText={editedText}
                    setEditedText={setEditedText}
                    inputRef={inputRef}
                    queryInProgress={queryInProgress}
                  />
                ))}
              {queryInProgress && (
                <li className="w-fit lg:pl-6">
                  {/* When queryInProgress showing three jumping dots */}
                  <div className="flex items-center justify-start gap-2 w-fit mx-auto">
                    <i className="fas fa-circle text-[0.5rem] text-primary animate-bounce1" />
                    <i className="fas fa-circle text-[0.5rem] text-primary animate-bounce2" />
                    <i className="fas fa-circle text-[0.5rem] text-primary animate-bounce3" />
                  </div>
                </li>
              )}
            </>
          )}
          <li ref={messagesEndRef} />
        </ul>
      </Card>
      <form
        className="relative flex items-center border border-[rgba(255,255,255,0.21)] rounded-2xl cursor-default"
        onSubmit={onSubmitHandler}>
        <input
          value={messageText}
          type="text"
          onChange={(e) => setMessageText(e.target.value)}
          className="p-3 flex-1 bg-transparent outline-none"
          placeholder="Enter your query..."
        />
        <button type="submit" className="msge-send-btn mr-2 sm:mr-4" disabled={queryInProgress}>
          <i
            className={`fas fa-paper-plane flex items-center justify-center h-8 w-8 ${queryInProgress || !messageText ? 'bg-primary-light' : 'bg-primary'} rounded-full outline-none`}
          />
        </button>
      </form>
    </div>
  );
}

export default QueryScript;
