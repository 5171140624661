import { ReactComponent as CrossIcon } from '../../static/icons/cross.svg';
import { ReactComponent as PDFIcon } from '../../static/icons/pdf.svg';
import { ReactComponent as WordIcon } from '../../static/icons/word.svg';
import { ReactComponent as SubmissionChat } from '../../static/icons/submission-chat.svg';

const UploadedFiles = ({ selectedFile, onFileCancel }) => {
  const fileExtension = selectedFile?.name?.split('.')?.pop()?.toLowerCase();
  const returnFileIcon = () => {
    if (fileExtension === 'pdf') {
      return <PDFIcon className="h-9 stroke-white" />;
    } else if (['docx', 'doc']?.includes(fileExtension)) {
      return <WordIcon className="h-10 w-10 text-gray-400" />;
    } else {
      return <SubmissionChat className="h-10 w-10 text-gray-400" />;
    }
  };
  const renderUploadedFiles = () => {
    if (selectedFile) {
      return (
        <div className="flex items-center justify-between pl-9 pr-5 border border-[rgba(255,255,255,0.1)] bg-[rgba(0,1,32,0.3)] backdrop:filter-blur(15px) rounded-[10px]">
          <div className="flex gap-4 items-center py-3 md:py-4 font-filsonPro-regular">
            {returnFileIcon()}
            <div className="flex flex-col">
              <span>{selectedFile?.name|| selectedFile?.title }</span>
              <span className="text-sm text-gray-400">
                {/* eslint-disable-next-line */}
                {selectedFile?.size ? `${(selectedFile?.size  / 1048576)?.toFixed(2)} MB` : "Material Log"} 
              </span>
            </div>
          </div>
          <button
            type="button"
            className="text-gray-300 hover:text-white group"
            onClick={onFileCancel}>
            <CrossIcon className="group-hover:scale-[1.04] stroke-red-800" />
          </button>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-between pl-6 md:pl-9 pr-5 border border-[rgba(254,102,41,0.4)] bg-[rgba(0,1,32,0.3)] backdrop:filter-blur(15px) rounded-[10px]">
        <div className="flex gap-4 items-center py-3 md:py-4">
          <span>No file selected</span>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-gray-400 font-semibold">UPLOADED FILES</h4>
      {renderUploadedFiles()}
    </div>
  );
};

export default UploadedFiles;
