import { useState, useRef } from 'react';

import toast from 'react-hot-toast';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

import Button from '../components/UI/Button';
import Card from '../components/UI/Card';

function Refer() {
  const [email, setEmail] = useState('');
  const form = useRef();
  const followClickHandler = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_693cc38', 'template_a6h7kus', form.current, '_uhDnv4yjaMKgOXNs').then(
      (result) => {
        toast.success('Email sent successfully!');
        setEmail('');
      },
      (error) => {
        toast.error('Could not send email!');
      }
    );
  };

  return (
    <Card className="flex flex-col justify-center py-16">
      <Helmet>
        <title>Refer a friend | FullFrame</title>
        <meta
          name="description"
          content="Know someone who might like our platform? Send them an email with info about signing up"
        />
      </Helmet>
      <div className="flex flex-col gap-4 md:gap-6 lg:gap-8">
        <p className="text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold">
          Refer a Friend
        </p>
        <div className="flex justify-center w-auto">
          <p className="text-gray-300 text-center text-semibold sm:text-base md:text-[18px]">
            Know someone who might like our platform? Send them an email with info about signing up
          </p>
        </div>
      </div>
      <div id="refer">
        <form
          ref={form}
          className="relative items-stretch sm:mx-[10%] md:mx-[15%] lg:mx-[20%] 2xl:mx-[30%] mt-11 bg-slate-200 bg-opacity-5 rounded-lg"
          onSubmit={followClickHandler}
        >
          <input
            type="email"
            name="user_name"
            value={email}
            className="w-full rounded px-4 py-5 md:py-6 focus:outline-none bg-transparent"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            name="message"
            value={`Hi, the user would like to refer their friend, ${email}, to FullFrame.`}
            hidden
            readOnly
          />
          <Button
            type="submit"
            className="absolute right-3 top-1/2 -translate-y-1/2 !py-2 md:!py-4 !px-3 md:!px-5"
          >
            Refer <span className="hidden sm:inline">Now</span>
          </Button>
        </form>
      </div>
    </Card>
  );
}

export default Refer;
