function returnFieldValue(fieldName, value) {
  if (value !== undefined) {
    return value;
  } else {
    if (fieldName === 'priority') {
      return 'General';
    } else {
      return false;
    }
  }
}

export function sortDataByFields(data, sortingCriteria) {
  if (!sortingCriteria || !sortingCriteria.fieldName) return data;
  if (!data || !data.length) return [];

  const { fieldName, type } = sortingCriteria;

  return [...data].sort((a, b) => {
    const valueA = returnFieldValue(fieldName, a[fieldName]);
    const valueB = returnFieldValue(fieldName, b[fieldName]);

    if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
      if (valueA === valueB) return 0;
      // Reversing the sort for the read boolean value
      let readBooleanSortMultiplier = 1;
      if (fieldName === 'read') {
        readBooleanSortMultiplier = -1;
      }
      if (type === 'asc') {
        if (valueA === false || !valueA) {
          return -1 * readBooleanSortMultiplier;
        } else {
          return 1 * readBooleanSortMultiplier;
        }
      } else {
        if (valueA === false || !valueA) {
          return 1 * readBooleanSortMultiplier;
        } else {
          return -1 * readBooleanSortMultiplier;
        }
      }
    } else if (fieldName === 'id' || fieldName === 'title') {
      const comparisonResult = valueA?.localeCompare(valueB, 'en', { numeric: true });
      return type === 'asc' ? comparisonResult : -comparisonResult;
    } else if (fieldName.toLowerCase().includes('date')) {
      const dateA = new Date(valueA);
      const dateB = new Date(valueB);
      if (dateA < dateB) return type === 'asc' ? -1 : 1;
      if (dateA > dateB) return type === 'asc' ? 1 : -1;
    } else {
      if (valueA < valueB) return type === 'asc' ? -1 : 1;
      if (valueA > valueB) return type === 'asc' ? 1 : -1;
    }
    return 0;
  });
}
