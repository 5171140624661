import { useState } from 'react';
import Dropdown from '../submissions-database/dropdown/Dropdown';

import { useSubmissionsContext } from '../../context/submissions';
import MultiEntryField from '../submissions-database/MultiEntryField';
import RenderActionIcons from '../submissions-database/RenderActionIcons';
import SingleEntryField from '../submissions-database/SingleEntryField';
import SubmittedByDropdown from '../submissions-database/SubmittedByDropdown';
import DataContainer from './DataContainer';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import RenderStatus from './RenderStatus';

const SubmissionsTableRow = ({ item, index, isLast, isArchiveTable }) => {
  const { setSubmissionData, hiddenColumns } = useSubmissionsContext();
  const status = item?.status;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [currentDropdown, setCurrentDropdown] = useState({ index: null, label: '' });
  const [editingField, setEditingField] = useState({
    field: '',
    itemId: ''
  });

  const isHidden = (label) => {
    return hiddenColumns?.includes(label);
  };
  const isProcessingOrFailed = ['processing', 'failed'].includes(status?.toLowerCase());
  return (
    <tr
      className={`hover:bg-[rgba(0,1,32,0.47)] font-[Arial] transition-all duration-500 ease-in-out ${!isLast && 'border-b'} border-[#1D2939] text-start`}>
      <DataContainer className="!min-w-0 !pl-2 !px-1 py-3.5 ">
        {!isHidden('Title') && (
          <Dropdown
            item={item}
            values={['SCRIPT', 'BOOK', 'SERIES']}
            defaultValue={item?.type}
            dropdownClass="!w-28"
            title="Type"
            label="type"
            currentDropdown={currentDropdown}
            setCurrentDropdown={setCurrentDropdown}
            isSubmissionType
            index={index}
          />
        )}
      </DataContainer>
      {!isHidden('Title') && (
        <DataContainer colSpan={isProcessingOrFailed ? 9 : 1}>
          <SingleEntryField
            defaultValue={item.title}
            fieldName={'title'}
            item={item}
            editingField={editingField}
            setEditingField={setEditingField}
            isProcessingOrFailed={isProcessingOrFailed}
            fieldWidth="168px"
          />
        </DataContainer>
      )}
      {!isHidden('Writers/Directors/Producers') && !isProcessingOrFailed && (
        <DataContainer className={`text-nowrap`}>
          <MultiEntryField
            fieldNames={['writer', 'directors', 'producers']}
            item={item}
            editingField={editingField}
            setEditingField={setEditingField}
            fieldWidth="168px"
          />
        </DataContainer>
      )}
      {!isHidden('Submitted by') && !isProcessingOrFailed && (
        <DataContainer>
          <div className="flex items-center gap-2">
            <SubmittedByDropdown
              item={item}
              currentDropdown={currentDropdown}
              setCurrentDropdown={setCurrentDropdown}
            />
          </div>
        </DataContainer>
      )}
      {!isHidden('Genres') && !isProcessingOrFailed && (
        <DataContainer>
          <Dropdown
            item={item}
            values={[
              'Arthouse',
              'Biopic',
              'Slasher',
              'Paranormal',
              'Found Footage',
              'Psychological',
              'Dark Comedy',
              'Slapstick',
              'Heist',
              'Thriller',
              'Musical',
              'Non-Fiction',
              'Educational',
              'Technical',
              'Action',
              'Adventure',
              'Comedy',
              'Drama',
              'Horror',
              'Romance',
              'Science Fiction',
              'Fantasy',
              'Documentary',
              'Film Noir',
              'Western',
              'Historical',
              'Crime'
            ]}
            defaultValue={item?.genre}
            dropdownClass="!w-36"
            title="Genre"
            label="genre"
            currentDropdown={currentDropdown}
            setCurrentDropdown={setCurrentDropdown}
            isMultiEntry
            isSubmissionType
            index={index}
          />
        </DataContainer>
      )}
      {!isHidden('Logline') && !isProcessingOrFailed && (
        <DataContainer>
          <SingleEntryField
            defaultValue={item?.logline}
            fieldName={'logline'}
            textLength={10}
            lineBreakLength={30}
            item={item}
            editingField={editingField}
            setEditingField={setEditingField}
            isTextArea
          />
        </DataContainer>
      )}
      {!isHidden('Note') && !isProcessingOrFailed && (
        <DataContainer>
          <SingleEntryField
            defaultValue={item?.note}
            fieldName={'note'}
            textLength={10}
            lineBreakLength={30}
            item={item}
            editingField={editingField}
            setEditingField={setEditingField}
            isTextArea
          />
        </DataContainer>
      )}
      {!isHidden('Based on') && !isProcessingOrFailed && (
        <DataContainer>
          <SingleEntryField
            defaultValue={item?.note}
            fieldName={'based_on'}
            textLength={10}
            lineBreakLength={30}
            item={item}
            editingField={editingField}
            setEditingField={setEditingField}
            isTextArea
          />
        </DataContainer>
      )}
      {!isHidden('Read') && !isProcessingOrFailed && (
        <DataContainer>
          <Dropdown
            item={item}
            values={['Unread', 'Read']}
            defaultValue={item?.read ? 'Read' : 'Unread'}
            containerClass="w-20"
            title="Read"
            label="read"
            isBoolean
            currentDropdown={currentDropdown}
            setCurrentDropdown={setCurrentDropdown}
            index={index}
          />
        </DataContainer>
      )}
      {!isHidden('Responded') && !isProcessingOrFailed && (
        <DataContainer>
          <Dropdown
            item={item}
            values={['No Response', 'Responded']}
            defaultValue={item?.response_status ? 'Responded' : 'No Response'}
            containerClass="w-28"
            title="Response Status"
            label="response_status"
            isBoolean
            currentDropdown={currentDropdown}
            setCurrentDropdown={setCurrentDropdown}
            index={index}
          />
        </DataContainer>
      )}
      {!isHidden('Priority') && !isProcessingOrFailed && (
        <DataContainer className={``}>
          <Dropdown
            item={item}
            values={['Project Drafts', 'Project Samples', 'Available Material', 'General']}
            defaultValue={item?.priority || 'General'}
            onValueChange={() => {}}
            containerClass="w-24"
            title="Priority"
            label="priority"
            currentDropdown={currentDropdown}
            setCurrentDropdown={setCurrentDropdown}
            index={index}
          />
        </DataContainer>
      )}
      {!isHidden('Date') && (
        <DataContainer className={`px-2`} colSpan={isProcessingOrFailed ? 2 : 1}>
          <RenderStatus item={item} />
        </DataContainer>
      )}
      {!isProcessingOrFailed && (
        <DataContainer className={`!w-[1%] pr-4`}>
          <div className="flex items-center gap-2">
            <RenderActionIcons
              item={item}
              setSubmissionData={setSubmissionData}
              isArchiveTable={isArchiveTable}
              setConfirmationModal={setConfirmationModal}
            />
            <DeleteConfirmationModal
              item={item}
              confirmationModal={confirmationModal}
              setConfirmationModal={setConfirmationModal}
            />
          </div>
        </DataContainer>
      )}
    </tr>
  );
};

export default SubmissionsTableRow;
