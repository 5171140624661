import { useEffect } from 'react';

import Button from '../../UI/Button';
import Modal from '../../UI/Modal';
import ModalHeader from './ModalHeader';

/* eslint-disable react/prop-types */
const ConfirmationModal = ({
  suretyClickHandler,
  confirmationModal,
  setConfirmationModal,
  subHeading,
  message
}) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setConfirmationModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Modal onClose={() => setConfirmationModal(false)} showModal={confirmationModal}>
      <div className="mb-4 flex flex-col items-center justify-center gap-4 sm:gap-7 w-full">
        <ModalHeader text="Confirmation" />
        <div className="flex flex-col lg:gap-1 px-4 md:px-4 lg:px-4 xl:px-6 w-full font-semibold lg:text-lg">
          <span>{subHeading}</span>
          <span className="text-gray-600 block lg:text-[0.94rem] xl:text-base">{message}</span>
        </div>
        <div className="grid grid-cols-2 justify-center gap-4">
          <Button
            className="flex items-center justify-center gap-1 sm:gap-2 !py-2 !px-2 md:!px-5 !text-gray-600 !border !border-gray-400 !bg-transparent hover:!bg-gray-100 hover:!text-black hover:!border-gray-800 font-semibold"
            onClick={() => suretyClickHandler('no')}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke="gray"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.16992 14.8299L14.8299 9.16992"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.8299 14.8299L9.16992 9.16992"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="">No</span>
          </Button>
          <Button
            className="flex items-center justify-center gap-1 sm:gap-2 !py-2.5 !px-2 md:!px-5 font-semibold"
            onClick={() => suretyClickHandler('yes')}
          >
            <svg
              width="24"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 12.25L11 14.5L15.25 9.25"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="">Yes</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
