import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { reDirectToSignUp } from '../utils/token';

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;

  useEffect(() => {
    if (state.prevLandingPage) {
      reDirectToSignUp();
    } else {
      navigate('/');
    }
  }, []);

  return null;
};

export default Signup;
