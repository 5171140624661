import React from 'react';
import { twMerge } from 'tailwind-merge';

const Progress = ({ value, max = 100, label, className }) => {
  const progressPercentage = Math.min(Math.max(value, 0), max);
  if (progressPercentage <= 0) return;
  return (
    <div className={twMerge('w-full', className)}>
      {label && <div className="text-sm font-medium text-white mb-1">{label}</div>}
      <div
        className="w-full rounded-lg h-[10px] relative"
        style={{
          background:
            'linear-gradient(253.98deg, rgba(224, 76, 17, 0.3) 22.31%, rgba(254, 102, 41, 0.3) 77.69%)'
        }}>
        <div
          className="h-[10px] rounded-lg"
          style={{
            width: `${(progressPercentage / max) * 100}%`,
            background: 'linear-gradient(253.98deg, #E04C11 22.31%, #FE6629 77.69%)'
          }}
        />
      </div>
    </div>
  );
};

export default Progress;
