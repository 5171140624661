import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const EditableText = forwardRef(
  (
    {
      tagMode = false,
      initialText = '---',
      className = '',
      defaultIsEditing = false,
      fullWidth = false,
      onChange,
      disableEditing,
      standalone = true, // Default to true if not provided
      onClick
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(defaultIsEditing);
    const internalRef = useRef(null);

    useEffect(() => {
      if (isEditing && internalRef.current) {
        internalRef.current.focus();
      }
      if (!isEditing && !internalRef.current.textContent) {
        internalRef.current.textContent = initialText;
      }
    }, [isEditing]);

    useImperativeHandle(
      ref,
      () => ({
        ...internalRef.current,
        setText: (text) => {
          if (internalRef.current) {
            internalRef.current.textContent = text;
          }
        },
        getText: () => {
          return internalRef.current ? internalRef.current.textContent : '';
        },
        toggleEditing: () => {
          setIsEditing((prev) => !prev);
        },
        focus: () => {
          if (internalRef.current) {
            internalRef.current.focus();
            window.getSelection().selectAllChildren(internalRef.current);
          }
        }
      }),
      []
    );
    return (
      <div
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            internalRef.current.blur();
          }
        }}
        ref={internalRef}
        contentEditable={isEditing && !disableEditing}
        onBlur={() => setIsEditing(false)}
        onClick={() => {
          if (!disableEditing) {
            setIsEditing(true);
            internalRef.current.focus();
            window.getSelection().selectAllChildren(internalRef.current);
          }
          onClick?.();
        }}
        onInput={(e) => onChange?.(e.currentTarget.textContent)}
        className={twMerge(
          'bg-transparent outline-none focus-visible:outline-black-50 rounded-md px-2 overflow-y-hidden mx-1 min-w-4 min-h-4',
          tagMode && '!outline-none px-0 rounded-none',
          isEditing && 'resize-none',
          !standalone && '!outline-none !border-none !p-0', // Remove borders, outlines, and padding if standalone is false
          fullWidth ? 'max-w-full' : 'max-w-56',
          className
        )}
        style={{
          whiteSpace: 'pre-wrap',
          direction: 'ltr' // Ensure text is left-to-right
        }}
        suppressContentEditableWarning={true}>
        {initialText}
      </div>
    );
  }
);

// Wrap EditableText with React.memo to optimize re-renders
export default React.memo(EditableText);
