import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Button from '../UI/Button';
import RenderInitials from '../submissions-database/RenderInitials';
import { useClickOutside } from '@mantine/hooks';
import { twMerge } from 'tailwind-merge';

const SubmittedBy = ({
  item,
  label = '',
  dropdownClass = '',
  setCurrentDropdown,
  onUpdate // Callback to handle updates
}) => {
  const dropdownRef = useRef(null);
  const location = useLocation();
  const companyNameInputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [companyName, setCompanyName] = useState(item.company_name || '');
  const [submittedBy, setSubmittedBy] = useState(item.submitted_by || '');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const contentRef = useClickOutside(() => {
    setIsVisible(false);
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsVisible(false);

    if (
      (companyName === item.company_name && submittedBy === item.submitted_by) ||
      !companyName ||
      !submittedBy
    )
      return;

    const updatedData = { ...item, company_name: companyName, submitted_by: submittedBy };

    if (onUpdate) {
      onUpdate(updatedData, location); // Trigger callback with updated data
    }
  };

  const toggleVisibility = () => {
    if (!isVisible) {
      setCurrentDropdown && setCurrentDropdown(label);
      setTimeout(() => {
        companyNameInputRef.current?.focus();
      }, 60);

      const rect = dropdownRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
    setIsVisible((prev) => !prev);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <>
      <div
        ref={dropdownRef}
        className={twMerge(
          'flex items-center gap-1 text-xs rounded-full',
          'px-2 py-0.5',
          'text-white bg-transparent w-full cursor-pointer'
        )}
        onClick={toggleVisibility}>
        {item?.company_name?.trim() && item?.submitted_by?.trim() ? (
          <>
            <RenderInitials companyName={item.company_name} />
            <span>{item.submitted_by}</span>
          </>
        ) : (
          '---'
        )}
      </div>

      {isVisible &&
        ReactDOM.createPortal(
          <form
            ref={contentRef}
            onSubmit={onSubmitHandler}
            className={twMerge(
              'absolute rounded-md',
              'text-[13px] text-left',
              'p-3',
              'bg-[rgba(49,50,62,1)] border border-[rgba(255,255,255,0.1)] text-white',
              'z-[999999] shadow-[0px_4px_24px_rgba(0,0,0,0.2)]',
              'font-inter w-fit',
              dropdownClass
            )}
            style={{ top: `${position.top}px`, left: `${position.left}px` }}>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <label htmlFor="company-name" className="text-xs text-[#98A2B3] font-normal">
                  Company Name
                </label>
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  id="company-name"
                  className={twMerge(
                    'w-[200px] text-xs',
                    'bg-[#37384580] text-white',
                    'px-5 py-3',
                    'border-b border-[#52526F40]',
                    'rounded outline-none'
                  )}
                  ref={companyNameInputRef}
                  placeholder="Enter company name"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="submitted-by" className="text-xs text-[#98A2B3] font-normal">
                  Submitted by
                </label>
                <input
                  type="text"
                  value={submittedBy}
                  onChange={(e) => setSubmittedBy(e.target.value)}
                  id="submitted-by"
                  className={twMerge(
                    'w-[200px] text-xs',
                    'bg-[#37384580] text-white',
                    'px-5 py-3',
                    'border-b border-[#52526F40]',
                    'rounded outline-none'
                  )}
                  placeholder="Submitted by"
                />
              </div>
            </div>
            <div className="flex gap-3 items-center w-full mt-4 text-center">
              <Button
                className={twMerge(
                  'w-full !bg-[#292A35]',
                  'border border-[#393A4B] !py-2',
                  'text-xs hover:!bg-[#393A4B] !text-center'
                )}
                onClick={() => setIsVisible(false)}>
                Cancel
              </Button>
              <Button type="submit" className="w-full !py-2 text-xs">
                Submit
              </Button>
            </div>
          </form>,
          document.getElementById('portal')
        )}
    </>
  );
};

export default SubmittedBy;
